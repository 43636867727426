import React from 'react';
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

import UserHome from './Pages/User/UserHome/UserHome';
import UserVideo from './Pages/User/UserVideo/UserVideo';
import UserSgup from './Pages/User/UserSgup/UserSgup';
import UserLogin from './Pages/User/UserLogin/UserLogin';
import UserFrgt from './Pages/User/UserFrgt/UserFrgt';
import UserOtp from './Pages/User/UserOtp/UserOtp';
import UserPwd from './Pages/User/UserPwd/UserPwd';
import UserPrfl from './Pages/User/UserPrfl/UserPrfl';
import UserPymt from './Pages/User/UserPymt/UserPymt';
import UserCtgrylst from './Pages/User/UserCtgrylst/UserCtgrylst';
import UserStrlst from './Pages/User/UserStrlst/UserStrlst';
import UserStrdtl from './Pages/User/UserStrdtl/UserStrdtl';
import UserOrdrsmry from './Pages/User/UserOrdrsmry/UserOrdrsmry';
import UserOrdrsmrynew from './Pages/User/UserOrdrsmry/UserOrdrsmrynew';
import UserOrdrsmrynewstp2 from './Pages/User/UserOrdrsmry/UserOrdrsmrynewstp2';

import UserOdrcfm from './Pages/User/UserOrdrsmry/UserOdrcfm';
import UserOdrcfmnew from './Pages/User/UserOrdrsmry/UserOdrcfmnew';
import UserCupn from './Pages/User/UserCupn/UserCupn';
import UserOrdpymt from './Pages/User/UserOrdpymt/UserOrdpymt';
import UserPrflstp from './Pages/User/UserPrflstp/UserPrflstp';
import UserFvrt from './Pages/User/UserFvrt/UserFvrt';
import UserNoti from './Pages/User/UserNoti/UserNoti';
import UserIntmrct from './Pages/User/UserIntmrct/UserIntmrct';
import UserHelp from './Pages/User/UserHelp/UserHelp';
import UserAbout from './Pages/User/UserAbout/UserAbout';
import UserTerms from './Pages/User/UserTerms/UserTerms';
import UserPrvcy from './Pages/User/UserPrvcy/UserPrvcy';
import UserSrvc from './Pages/User/UserSrvc/UserSrvc';
import UserPrdct from './Pages/User/UserPrdct/UserPrdct';
import UserEplyscn from './Pages/User/UserEplyscn/UserEplyscn';
import UserCupnscrn from './Pages/User/UserCupnscrn/UserCupnscrn';
import UserInvic from './Pages/User/UserInvic/UserInvic';
import UserRcipt from './Pages/User/UserRcipt/UserRcipt';
import UserCalender from './Pages/User/UserCalender/UserCalender';
import UserMycldr from './Pages/User/UserMycldr/UserMycldr';

import MerLogin from './Pages/Merchant/MerLogin/MerLogin';
import MerForgot from './Pages/Merchant/MerForgot/MerForgot';
import MerOtp from './Pages/Merchant/MerOtp/MerOtp';
import MerPsw from './Pages/Merchant/MerPsw/MerPsw';
import MerPrivacy from './Pages/Merchant/MerPrivacy/MerPrivacy';
import MerTerms from './Pages/Merchant/MerTerms/MerTerms';
import MerStore from './Pages/Merchant/MerStore/MerStore';
import MerDash from './Pages/Merchant/MerDash/MerDash';
import MerCust from './Pages/Merchant/MerCust/MerCust';
import MerMarketing from './Pages/Merchant/MerMarketing/MerMarketing';
import MerSelling from './Pages/Merchant/MerSelling/MerSelling';
import MerNoti from './Pages/Merchant/MerNoti/MerNoti';
import MerAbout from './Pages/Merchant/MerAbout/MerAbout';
import MerHelp from './Pages/Merchant/MerHelp/MerHelp';
import MerCal from './Pages/Merchant/MerCal/MerCal';
import MerOrder from './Pages/Merchant/MerOrder/MerOrder';
import MerInvoice from './Pages/Merchant/MerInvoice/MerInvoice';
import MerCoupon from './Pages/Merchant/MerCoupon/MerCoupon';
import MerSubscr from './Pages/Merchant/MerSubscr/MerSubscr';
import MerReceipts from './Pages/Merchant/MerReceipts/MerReceipts';
import MerEmp from './Pages/Merchant/MerEmp/MerEmp';

import EmpCale from './Pages/Employee/EmpCale/EmpCale';
import EmpCommi from './Pages/Employee/EmpCommi/EmpCommi';
import EmpDash from './Pages/Employee/EmpDash/EmpDash';
import EmpForget from './Pages/Employee/EmpForget/EmpForget';
import EmpInvoice from './Pages/Employee/EmpInvoice/EmpInvoice';
import EmpLogin from './Pages/Employee/EmpLogin/EmpLogin';
import EmpNoti from './Pages/Employee/EmpNoti/EmpNoti';
import EmpOrder from './Pages/Employee/EmpOrder/EmpOrder';
import EmpOtp from './Pages/Employee/EmpOtp/EmpOtp';
import EmpProfile from './Pages/Employee/EmpProfile/EmpProfile';
import EmpPwd from './Pages/Employee/EmpPwd/EmpPwd';
import EmpReceipts from './Pages/Employee/EmpReceipts/EmpReceipts';


import SalVideo from './Pages/Sales/SalVideo/SalVideo';
import SalDash from './Pages/Sales/SalDash/SalDash';
import SalHelp from './Pages/Sales/SalHelp/SalHelp';
import SalAbout from './Pages/Sales/SalAbout/SalAbout';
import SalSignup from './Pages/Sales/SalSignup/SalSignup';
import SalWithdraw from './Pages/Sales/SalWithdraw/SalWithdraw';
import SalCommi from './Pages/Sales/SalCommi/SalCommi';
import SalProfile from './Pages/Sales/SalProfile/SalProfile';
import SalNoti from './Pages/Sales/SalNoti/SalNoti';
import SalPerson from './Pages/Sales/SalPerson/SalPerson';
import SalInvite from './Pages/Sales/SalInvite/SalInvite';




function App() {
  return (
    <>
      <Router>
              <Routes>
                  <Route exact path="/" element={<UserVideo />} />
                  <Route exact path="/about/" element={<UserAbout />} />
                  <Route exact path="/calendar/" element={<UserCalender />} />
                  <Route exact path="/category-list/" element={<UserCtgrylst />} />
                  <Route exact path="/coupon-list/" element={<UserCupn />} />
                  <Route exact path="/coupon/" element={<UserCupnscrn />} />
                  <Route exact path="/employee/" element={<UserEplyscn />} />
                  <Route exact path="/forgot-password/" element={<UserFrgt />} />
                  <Route exact path="/favorite/" element={<UserFvrt />} />                                      
                  <Route exact path="/help/" element={<UserHelp />} />
                  <Route exact path="/home/" element={<UserHome />} />
                  <Route exact path="/invite-merchant/" element={<UserIntmrct />} />
                  <Route exact path="/invoice/" element={<UserInvic />} />
                  <Route exact path="/login/" element={<UserLogin />} />
                  <Route exact path="/my-calendar/" element={<UserMycldr />} />
                  <Route exact path="/notification/" element={<UserNoti />} />
                  <Route exact path="/order-payment/" element={<UserOrdpymt />} />
                  <Route exact path="/order-summary/" element={<UserOrdrsmry />} />                                      
                  <Route exact path="/service-order-summary/" element={<UserOrdrsmrynew />} />     
                  <Route exact path="/service-order-confirmation/" element={<UserOrdrsmrynewstp2 />} />
                  <Route exact path="/order-confirmation/" element={<UserOdrcfm />} />
                  <Route exact path="/product-order-confirmation/" element={<UserOdrcfmnew />} />
                  <Route exact path="/otp/" element={<UserOtp />} />
                  <Route exact path="/product/" element={<UserPrdct />} />
                  <Route exact path="/profile/" element={<UserPrfl />} />
                  <Route exact path="/profile-setup/" element={<UserPrflstp />} />
                  <Route exact path="/privacy-policy/" element={<UserPrvcy />} />
                  <Route exact path="/create-password/" element={<UserPwd />} />
                  <Route exact path="/payment/" element={<UserPymt />} />
                  <Route exact path="/receipts/" element={<UserRcipt />} />
                  <Route exact path="/signup/" element={<UserSgup />} />
                  <Route exact path="/service/" element={<UserSrvc />} />
                  <Route exact path="/store-details/" element={<UserStrdtl />} />
                  <Route exact path="/store-list/" element={<UserStrlst />} />
                  <Route exact path="/terms/" element={<UserTerms />} />
                  <Route exact path="/video/" element={<UserVideo />} />

                  <Route exact path="/mer-login/" element={<MerLogin />} />
                  <Route exact path="/mer-forgot-password/" element={<MerForgot />} />
                  <Route exact path="/mer-otp/" element={<MerOtp />} />
                  <Route exact path="/mer-create-password/" element={<MerPsw />} />
                  <Route exact path="/mer-privacy/" element={<MerPrivacy />} />
                  <Route exact path="/mer-terms/" element={<MerTerms />} />
                  <Route exact path="/mer-store-setup/" element={<MerStore />} />
                  <Route exact path="/mer-dashboard/" element={<MerDash />} />
                  <Route exact path="/mer-customer/" element={<MerCust />} />
                  <Route exact path="/mer-marketing/" element={<MerMarketing />} />
                  <Route exact path="/mer-selling/" element={<MerSelling />} />
                  <Route exact path="/mer-order-appointment/" element={<MerOrder />} />
                  <Route exact path="/mer-notification/" element={<MerNoti />} />
                  <Route exact path="/mer-about/" element={<MerAbout />} />
                  <Route exact path="/mer-help/" element={<MerHelp />} />
                  <Route exact path="/mer-calendar/" element={<MerCal />} />
                  <Route exact path="/mer-invoice/" element={<MerInvoice />} />
                  <Route exact path="/mer-sending-coupons/" element={<MerCoupon />} />
                  <Route exact path="/mer-subscription/" element={<MerSubscr />} />
                  <Route exact path="/mer-receipts/" element={<MerReceipts />} />
                  <Route exact path="/mer-employee-management/" element={<MerEmp />} />

                  <Route exact path="/emp-dashboard/" element={<EmpDash />} />
                  <Route exact path="/emp-profile/" element={<EmpProfile />} />
                  <Route exact path="/emp-order/" element={<EmpOrder />} />
                  <Route exact path="/emp-commission/" element={<EmpCommi />} />
                  <Route exact path="/emp-notification/" element={<EmpNoti />} />
                  <Route exact path="/emp-calendar/" element={<EmpCale />} />
                  <Route exact path="/emp-invoice/" element={<EmpInvoice />} />
                  <Route exact path="/emp-login/" element={<EmpLogin />} />
                  <Route exact path="/emp-otp/" element={<EmpOtp />} />
                  <Route exact path="/emp-forgot-password/" element={<EmpForget />} />
                  <Route exact path="/emp-create-password/" element={<EmpPwd />} />
                  <Route exact path="/emp-receipts/" element={<EmpReceipts />} />

                  <Route exact path="/sales-about/" element={<SalAbout />} />
                  <Route exact path="/sales-commission/" element={<SalCommi />} />
                  <Route exact path="/sales-dashboard/" element={<SalDash />} />
                  <Route exact path="/sales-help/" element={<SalHelp />} />
                  <Route exact path="/sales-invite-merchant/" element={<SalInvite />} />
                  <Route exact path="/sales-notification/" element={<SalNoti />} />
                  <Route exact path="/sales-person/" element={<SalPerson />} />
                  <Route exact path="/sales-profile/" element={<SalProfile />} />
                  <Route exact path="/sales-signup/" element={<SalSignup />} />
                  <Route exact path="/sales-video/" element={<SalVideo />} />                  
                  <Route exact path="/sales-withdraw/" element={<SalWithdraw />} />
              </Routes>
      </Router>
                
    </>
  );
}

export default App;