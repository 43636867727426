import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Form, Modal} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './MerCust.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "../footer";
import { useNavigate } from 'react-router-dom';
//import $ from 'jquery';

const MerCust = () => {
const navigate = useNavigate();

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const [show1, setShow1] = useState(false);
const handleClose1 = () => setShow1(false);
const handleShow1 = () => setShow1(true);

const [show2, setShow2] = useState(false);
const handleClose2 = () => setShow2(false);
const handleShow2 = () => setShow2(true);

useEffect(() => {
      document.title = ` Merchant Customers | Blue Faucet`;
      document.getElementById('bodywrap').className='cstmrpgmain grnbg mcus';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });        
},[])  
    return (
      
        <>
      {/* ========== Merchant Customer Page Start ========== */}
         <section className="gen-wrap">
         {/* ========== Navbar Start ========== */}
         <Row className="maincntmrcnt">
            <Col xs={2} md={2} className="align-self-center px-0">
            <button onClick={() =>
               navigate(-1)} className="back-btn d-none">
               <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
               </svg>
            </button>
            </Col>
            <Col xs={8} md={8} className="align-self-center text-center">
            <h3>Customers</h3>
            </Col>
         </Row>
         {/* ========== Navbar End ========== */}
         <Container>
            {/* ========== Customer Top Box Start ========== */}
            <Row className="tpfixgrn">
               <Col md={3} xs={4} className="align-self-center">
               <Form.Select aria-label="Default select example">
                  <option>All</option>
                  <option defaultValue="1">Members</option>
                  <option defaultValue="2">Pending</option>
                  <option defaultValue="3">Normal</option>
                  <option defaultValue="4">VIP</option>
                  <option defaultValue="5">Block</option>
               </Form.Select>
               </Col>
               <Col md={9} xs={8} className="align-self-center text-end btntpfx px-0">
               <NavLink to="#" onClick={handleShow2}><Button variant="gren" type="submit">Share via…</Button></NavLink>
               <NavLink to="#" onClick={handleShow}><Button variant="black" type="submit">Invite Customers</Button></NavLink>
               <NavLink to="#" onClick={handleShow1}><Button variant="icn" type="submit"> Add Customer</Button></NavLink>
               </Col>
            </Row>
            {/* ========== Customer Top Box End ========== */}
            {/* ========== Customer Content Start ========== */}
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
               <Col md={12} xs={12} className="cusslctbtn btnspcer">
               <div className="cusradio">
                  <input className="radio-label" type="radio" name="yesno" id="one" />
                  <label className="button-label" htmlFor="one">Customers: <span>15</span></label>
                  <input className="radio-label" type="radio" name="yesno" id="two" defaultChecked={true}/>
                  <label className="button-label" htmlFor="two">Members</label>
                  <input className="radio-label" type="radio" name="yesno" id="three"/>
                  <label className="button-label" htmlFor="three">Pending</label>
               </div>
               </Col>
               <Col md={12} xs={12} className="mcusbx">
               <Row className="mx-0">
                  <Col md={2} xs={2} className="align-self-center px-1">
                  <img src= {'assets/images/merchant/mcust-img1.png'} className="img-fluid" alt="Mcust Img"/></Col>
                  <Col md={6} xs={7} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                  <h3>Customer Name</h3>
                  <p>loremipsum@gmail.com</p>
                  </Col>
                  <Col md={4} xs={3} className="align-self-center text-md-end">
                  <div className="demo">
                     <select>
                        <option>Normal</option>
                        <option>Vip</option>
                        <option>Block</option>
                     </select>
                  </div>
                  </Col>
               </Row>
               </Col>
               <Col md={12} xs={12} className="mcusbx">
               <Row className="mx-0">
                  <Col md={2} xs={2} className="align-self-center px-1">
                  <img src= {'assets/images/merchant/mcust-img2.png'} className="img-fluid" alt="Mcust Img"/></Col>
                  <Col md={6} xs={7} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                  <h3>Customer Name</h3>
                  <p>loremipsum@gmail.com</p>
                  </Col>
                  <Col md={4} xs={3} className="align-self-center text-end">
                  <div className="demo">
                     <select>
                        <option>Normal</option>
                        <option>Vip</option>
                        <option>Block</option>
                     </select>
                  </div>
                  </Col>
               </Row>
               </Col>
               <Col md={12} xs={12} className="mcusbx">
               <Row className="mx-0">
                  <Col md={2} xs={2} className="align-self-center px-1">
                  <img src= {'assets/images/merchant/mcust-img3.png'} className="img-fluid" alt="Mcust Img"/></Col>
                  <Col md={6} xs={7} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                  <h3>Customer Name</h3>
                  <p>loremipsum@gmail.com</p>
                  </Col>
                  <Col md={4} xs={3} className="align-self-center text-end">
                  <div className="demo">
                     <select>
                        <option>Normal</option>
                        <option>Vip</option>
                        <option>Block</option>
                     </select>
                  </div>
                  </Col>
               </Row>
               </Col>
               <Col md={12} xs={12} className="mcusbx">
               <Row className="mx-0">
                  <Col md={2} xs={2} className="align-self-center px-1">
                  <img src= {'assets/images/merchant/mcust-img4.png'} className="img-fluid" alt="Mcust Img"/></Col>
                  <Col md={6} xs={7} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                  <h3>Customer Name</h3>
                  <p>loremipsum@gmail.com</p>
                  </Col>
                  <Col md={4} xs={3} className="align-self-center text-end">
                  <div className="demo">
                     <select>
                        <option>Normal</option>
                        <option>Vip</option>
                        <option>Block</option>
                     </select>
                  </div>
                  </Col>
               </Row>
               </Col>
               <Col md={12} xs={12} className="mcusbx">
               <Row className="mx-0">
                  <Col md={2} xs={2} className="align-self-center px-1">
                  <img src= {'assets/images/merchant/mcust-img5.png'} className="img-fluid" alt="Mcust Img"/></Col>
                  <Col md={6} xs={7} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                  <h3>Customer Name</h3>
                  <p>loremipsum@gmail.com</p>
                  </Col>
                  <Col md={4} xs={3} className="align-self-center text-end">
                  <div className="demo">
                     <select>
                        <option>Normal</option>
                        <option>Vip</option>
                        <option>Block</option>
                     </select>
                  </div>
                  </Col>
               </Row>
               </Col>
               <Col md={12} xs={12} className="mcusbx">
               <Row className="mx-0">
                  <Col md={2} xs={2} className="align-self-center px-1">
                  <img src= {'assets/images/merchant/mcust-img6.png'} className="img-fluid" alt="Mcust Img"/></Col>
                  <Col md={6} xs={7} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                  <h3>Customer Name</h3>
                  <p>loremipsum@gmail.com</p>
                  </Col>
                  <Col md={4} xs={3} className="align-self-center text-end">
                  <div className="demo">
                     <select>
                        <option>Normal</option>
                        <option>Vip</option>
                        <option>Block</option>
                     </select>
                  </div>
                  </Col>
               </Row>
               </Col>
               <Col md={12} xs={12} className="mcusbx">
               <Row className="mx-0">
                  <Col md={2} xs={2} className="align-self-center px-1">
                  <img src= {'assets/images/merchant/mcust-img7.png'} className="img-fluid" alt="Mcust Img"/></Col>
                  <Col md={6} xs={7} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                  <h3>Customer Name</h3>
                  <p>loremipsum@gmail.com</p>
                  </Col>
                  <Col md={4} xs={3} className="align-self-center text-end">
                  <div className="demo">
                     <select>
                        <option>Normal</option>
                        <option>Vip</option>
                        <option>Block</option>
                     </select>
                  </div>
                  </Col>
               </Row>
               </Col>
               <Col>
               <div className="mrcustmrmodal">
                  <Modal size="lg" show={show} onHide={handleClose} aria-labelledby="example-modal-sizes-title-lg" className="trdmodal" id="trdmdl3">
                     <Modal.Body>
                        <Row className="mx-0 topppdtlmcstmr">
                           <Col md={6} xs={6}>
                           <h3 className="mdlttltxt">Invite Customers</h3>
                           </Col>
                           <Col md={6} xs={6} className="grntxt">
                           <h3 className="mdlttltxt">Copy Link</h3>
                           </Col>
                        </Row>
                        <Row className="mx-0 shrelist">
                           <Col md={12} xs={12} className="my-2">
                           <h3 className="mdlttltxt">Click the following icons to send the Invitation Message to the contacts.</h3>
                           </Col>
                           <Col md={3} xs={3} className="shrelist-mincnt text-center">
                           <NavLink to="#">
                              <img src= {'assets/images/svg/shr-icn1.svg'} className="img-fluid" alt="Sld Img"/>
                              <div className="nm-shr">
                                 <p>To Contact</p>
                              </div>
                           </NavLink>
                           </Col>
                           <Col md={3} xs={3} className="shrelist-mincnt text-center">
                           <NavLink to="#">
                              <img src= {'assets/images/svg/shr-icn2.svg'} className="img-fluid" alt="Sld Img"/>
                              <div className="nm-shr">
                                 <p>Gmail</p>
                              </div>
                           </NavLink>
                           </Col>
                           <Col md={3} xs={3} className="shrelist-mincnt text-center">
                           <NavLink to="#">
                              <img src= {'assets/images/svg/shr-icn3.svg'} className="img-fluid" alt="Sld Img"/>
                              <div className="nm-shr">
                                 <p>Outlook</p>
                              </div>
                           </NavLink>
                           </Col>
                           <Col md={3} xs={3} className="shrelist-mincnt text-center">
                           <NavLink to="#">
                              <img src= {'assets/images/svg/shr-icn4.svg'} className="img-fluid" alt="Sld Img"/>
                              <div className="nm-shr">
                                 <p>Whatsapp</p>
                              </div>
                           </NavLink>
                           </Col>
                           <Col md={3} xs={3} className="shrelist-mincnt text-center">
                           <NavLink to="#">
                              <img src= {'assets/images/svg/shr-icn5.svg'} className="img-fluid" alt="Sld Img"/>
                              <div className="nm-shr">
                                 <p>Phone Log</p>
                              </div>
                           </NavLink>
                           </Col>
                           <Col md={3} xs={3} className="shrelist-mincnt text-center">
                           <NavLink to="#">
                              <img src= {'assets/images/svg/shr-icn5.svg'} className="img-fluid" alt="Sld Img"/>
                              <div className="nm-shr">
                                 <p>Phone Number</p>
                              </div>
                           </NavLink>
                           </Col>
                           <Col md={3} xs={3} className="shrelist-mincnt text-center">
                           <NavLink to="#">
                              <img src= {'assets/images/svg/shr-icn6.svg'} className="img-fluid" alt="Sld Img"/>
                              <div className="nm-shr">
                                 <p>Email</p>
                              </div>
                           </NavLink>
                           </Col>
                           <Col md={3} xs={3} className="shrelist-mincnt text-center d-none">
                           <NavLink to="#">
                              <img src= {'assets/images/svg/shr-icn7.svg'} className="img-fluid" alt="Sld Img"/>
                              <div className="nm-shr">
                                 <p>QR Code</p>
                              </div>
                           </NavLink>
                           </Col> 
                           <Col md={12} xs={12} className="oppbxcntmcustmr mb-3">
                           <h4 className="mdlttltxt">Invitation Message</h4>
                           <Form.Control className="p-3" as="textarea" rows={4} placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type..." />
                           </Col>
                           <Col md={12} xs={12} className="btnpopmcstmr my-2">
                           <NavLink to="#" onClick={() => handleClose()}><Button variant="black" type="submit">Confirm</Button></NavLink>
                           </Col>
                        </Row>
                     </Modal.Body>
                     <Modal.Footer className="d-none"></Modal.Footer>
                  </Modal>
               </div>
               <div className="madcustmrpop">
                  <Modal show={show1} onHide={handleClose1} className="popupwrngcnt" centered>
                     <Modal.Header closeButton>
                        <h3 className="mdlttltxt">Add Customer</h3>
                     </Modal.Header>
                     <Modal.Body>
                        <Col md={12} xs={12} className="ppinpt">
                        <Form>
                           <Form.Group className="mb-3">
                              <Form.Label>Name</Form.Label>
                              <Form.Control type="text" placeholder="Enter customer name" />
                           </Form.Group>
                           <Form.Group className="mb-3">
                              <Form.Label>Email</Form.Label>
                              <Form.Control type="text" placeholder="Enter customer email" />
                           </Form.Group>
                           <Form.Group className="mb-3">
                              <Form.Label>Phone Number<span>*</span></Form.Label>
                              <Form.Control type="tel" placeholder="+1 987 654 321 00" />
                           </Form.Group>
                           <Col md={12} xs={12} className="oppbxcntmcustmr mb-3">
                           <h4>Your Message</h4>
                           <Form.Control className="p-3" as="textarea" rows={4} placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type..." />
                           </Col>
                        </Form>
                        </Col>
                        <Col md={12} xs={12} className="btnpopmcstmr my-2">
                        <NavLink to="#" onClick={() => handleClose1()}><Button variant="black" type="submit">Save and Send Invitation</Button></NavLink>
                        </Col>
                     </Modal.Body>
                  </Modal>
               </div>
               <div className="modal3">
                  <Modal size="lg" show={show2} onHide={handleClose2} aria-labelledby="example-modal-sizes-title-lg" className="trdmodal" id="trdmdl3">
                     <Modal.Body>
                        <Row className="mx-0 tpcrd-pop3">
                           <Col md={2} xs={2} className="align-self-center">
                           <img src="assets/images/merchant/user-img.png" className="img-fluid" alt="Hm Img"/></Col>
                           <Col md={10} xs={10} className="align-self-center mb-3">
                           <h3>R-Cube Spa</h3>
                           <p>lorem.ipsum@gmail.com</p>
                           </Col>
                           <Col md={12} xs={12} className="cpytxtdv mt-3">
                           <h4 className="mdlttltxt">Copy your store link and paste to the social media</h4>
                           <div className="copy-text">
                              <input type="text" className="text" defaultValue="https://app.bluefaucet.co..." />
                              <button>Copy</button>
                           </div>
                           </Col>
                           <Col md={12} xs={12} className="dwncnt3">
                           <h4>Share via</h4>
                           <ul>
                              <NavLink to="#">
                                 <li>
                                    <img src="assets/images/merchant/icn-1.png" className="img-fluid" alt="Hm Img"/>
                                    <p>Yelp</p>
                                 </li>
                              </NavLink>
                              <NavLink to="#">
                                 <li>
                                    <img src="assets/images/merchant/icn-2.png" className="img-fluid" alt="Hm Img"/>
                                    <p>Twitter</p>
                                 </li>
                              </NavLink>
                              <NavLink to="#">
                                 <li>
                                    <img src="assets/images/merchant/icn-3.png" className="img-fluid" alt="Hm Img"/>
                                    <p>Instagram</p>
                                 </li>
                              </NavLink>
                              <NavLink to="#">
                                 <li>
                                    <img src="assets/images/merchant/icn-4.png" className="img-fluid" alt="Hm Img"/>
                                    <p>Meta</p>
                                 </li>
                              </NavLink>
                              <NavLink to="#">
                                 <li>
                                    <img src="assets/images/merchant/icn-5.png" className="img-fluid" alt="Hm Img"/>
                                    <p>WhatsApp</p>
                                 </li>
                              </NavLink>
                           </ul>
                           </Col>
                        </Row>
                     </Modal.Body>
                  </Modal>
               </div>
               </Col>
               </Col>
            </Row>
            {/* ========== Customer Content End ========== */}
         </Container>
      </section>
      {/* ========== Footer Start ========== */}
        <Footer/>
      {/* ========== Footer End ========== */}
      {/* ========== Merchant Customer Page End ========== */}
        </>
    )
}
export default MerCust;