import React, { useEffect } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import './UserCupn.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
// import $ from 'jquery';

const UserCupn = () => {

useEffect(() => {
  document.title = `User Coupons | Blue Faucet`;
  document.getElementById('bodywrap').className='ucupnpgmain';
 window.scrollTo({top: 0,left: 0,behavior: 'smooth', }); 
},[])  

    return (
        <>
         {/* ========== User Coupons Page Start ========== */}
            <section className="gen-wrap">
            {/* ========== Navbar Start ========== */}
               <Navbar headerText={'Coupons'}/>
            {/* ========== Navbar End ========== */}
               <Container>
                  <Row className="hmtpfixdv">
                     <Col md={12} lg={12} xl={12} xxl={12} className="hmtp-scrl uclsrldv">
                     <ul className="hmtp-scldv">
                        <Row className="mx-0 ucl-sfbtn">
                           <Col>
                           <NavLink to="#">
                              <Dropdown>
                                 <Dropdown.Toggle variant="" id="dropdown-basic"><span><img src="assets/images/svg/ucl-srtimg.svg" className="img-fluid" alt="Usl Img"/></span>Sort</Dropdown.Toggle>
                                 <Dropdown.Menu>
                                    <Dropdown.Item href={void(0)}>Popular</Dropdown.Item>
                                    <Dropdown.Item href={void(0)}>Rating</Dropdown.Item>
                                    <Dropdown.Item href={void(0)}>Price Low to high</Dropdown.Item>
                                    <Dropdown.Item href={void(0)}>Price High to Low</Dropdown.Item>
                                 </Dropdown.Menu>
                              </Dropdown>
                           </NavLink>
                           </Col>
                           <Col>
                           <NavLink to="#"><Button variant="" type="submit"><span><img src="assets/images/svg/ucl-fltrimg.svg" className="img-fluid" alt="Usl Img"/></span>Filter</Button></NavLink>
                           </Col>
                        </Row>
                     </ul>
                     </Col>
                  </Row>
                  <Row className="mx-0 mianhmdwncnt">
                     <Col className="mnhmcnt">
                     <Col md={12} xs={12} className="">
                     <NavLink to="#">
                      <Row className="mx-0 cuscoupn cpnblu mb-1">
                       <Col className="align-self-center">
                        <p>Limited Period Offer</p>
                        <h4>UP TO 50% OFF</h4>
                        <p>Stay Tuned</p>
                       </Col>
                       <Col className="align-self-end text-end"><img src="assets/images/cupn-img.png" className="img-fluid w-100" alt="Hmtp Postr"/></Col>
                      </Row>
                     </NavLink>
                     </Col>
                     <Col md={12} xs={12} className="py-2 cupn-crd mb-1">
                     <div className="coupon">
                        <div className="left">
                           <div>Discount</div>
                        </div>
                        <div className="center">
                           <div>
                              <h2>5% Off</h2>
                              <h4 className="h3line">2KZYVZD1PP3F</h4>
                              <p>Use BlueFaucet to Make Reservation</p>
                              <p>Start Date : <span>2023/07/03</span> &nbsp;&nbsp;End Date :<span>2023/07/03</span> </p>
                           </div>
                        </div>
                        <div className="right">
                        </div>
                     </div>
                     </Col>
                     </Col>
                  </Row>
               </Container>
            </section>
         {/* ========== User Coupons Page End ========== */}
        </>
    )
}

export default UserCupn;