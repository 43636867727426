import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Form, Card, Modal } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './MerSubscr.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
// import $ from 'jquery';

const MerSubscr = () => {

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

useEffect(() => {
      document.title = `Merchant Subscription | Blue Faucet`;
      document.getElementById('bodywrap').className='msbsrptnpgmain grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
},[])  

    return (
      <>
      {/* ========== Merchant Subscription Page Start ========== */}
            <section className="gen-wrap">
      {/* ========== Navbar Start ========== */}
               <Navbar headerText={'My Subscription'}/>
      {/* ========== Navbar End ========== */}
               <Container>
                  <Row className="mx-0 mianhmdwncnt">
      {/* ========== Subscription Box Start ========== */}
                     <Col md={12} xs={12} className="mnhmcnt my-3">
                     <Col md={12} xs={12} className="mainttl-subtn mb-3"><h3>Active Plan</h3></Col>
                     <Col md={12} xs={12} className="py-2">
                     <NavLink to="#">
                        <Row className="mx-0 ucl-box">
                          <Col md={1} xs={2} className="align-self-center px-0 text-center"><img src= {'assets/images/merchant/free.png'} className="img-fluid" alt="Sld Img"/></Col>
                          <Col md={8} xs={7} className="align-self-center px-3 px-md-3">
                          <h3>Free</h3>
                          <p>20 Day Left <span> $00.00</span></p>
                          </Col>
                          <Col md={3} xs={3} className="align-self-center text-end px-0"></Col>
                     </Row>
                     </NavLink>
                     </Col>
                     <Col md={12} xs={12} className="mainttl-subtn my-3">
                     <h3>Subscription Plan</h3>
                     </Col>
                     <Row xs={{ cols:1, gutter: 2 }} lg={{ cols: 2, gutter: 3}} className="mt-2">
                     <Col className="mb-4">
                     <NavLink to="#">
                        <Card className="msubtn-mainbox">
                           <Card.Body>
                              <Card.Title><h3>Free</h3></Card.Title>
                              <img src= {'assets/images/merchant/free.png'} className="img-fluid" alt="Sld Img"/>
                              <Col>
                                 <h4>$0.0 </h4>
                                 <ul>
                                    <li className="chekk"> <span>30 days</span></li>
                                    <li className="chekk"> <span>50 customer invite</span></li>
                                    <li className="close"> <span>0 employee</span></li>
                                    <li className=""> <span></span></li>
                                 </ul>
                              </Col>
                           </Card.Body>
                           <Card.Footer>
                              <Button className="active" variant="" type="submit">Active</Button>
                           </Card.Footer>
                        </Card>
                     </NavLink>
                     </Col>
                     <Col className="mb-4">
                     <NavLink to="#">
                        <Card className="msubtn-mainbox">
                           <Card.Body>
                              <Card.Title><h3>BASIC</h3></Card.Title>
                              <img src= {'assets/images/merchant/medal.png'} className="img-fluid" alt="Sld Img"/>
                              <Col>
                                 <h4>$90 </h4>
                                 <ul>
                                    <li className="chekk"> <span>90 days</span></li>
                                    <li className="chekk"> <span>Unlimited customer invite</span></li>
                                    <li className="chekk"> <span>1 employee profile setup</span></li>
                                    <li className="close"> <span>$30 for each new employee profile setup.</span></li>
                                 </ul>
                              </Col>
                           </Card.Body>
                           <Card.Footer>
                              <Button variant="" type="submit" onClick={handleShow}>Purchase</Button>
                           </Card.Footer>
                        </Card>
                     </NavLink>
                     </Col>
                     <Col className="mb-4">
                     <NavLink to="#">
                        <Card className="msubtn-mainbox">
                           <Card.Body>
                              <Card.Title><h3>BUSINESS</h3></Card.Title>
                              <img src= {'assets/images/merchant/gift.png'} className="img-fluid" alt="Sld Img"/>
                              <Col>
                                 <h4>$180 </h4>
                                 <ul>
                                    <li className="chekk"> <span>180+30 days</span></li>
                                    <li className="chekk"> <span>Unlimited customer invite</span></li>
                                    <li className="chekk"> <span>1 employee profile setup</span></li>
                                    <li className="close"> <span>$30 for each new employee profile setup.</span></li>
                                 </ul>
                              </Col>
                           </Card.Body>
                           <Card.Footer>
                              <Button variant="" type="submit" onClick={handleShow}>Purchase</Button>
                           </Card.Footer>
                        </Card>
                     </NavLink>
                     </Col>
                     <Col className="mb-4">
                     <NavLink to="#">
                     <Card className="msubtn-mainbox">
                        <Card.Body>
                           <Card.Title><h3>PROFESSIONAL</h3></Card.Title>
                           <img src= {'assets/images/merchant/beg.png'} className="img-fluid" alt="Sld Img"/>
                           <Col>
                              <h4>$360 </h4>
                              <ul>
                                 <li className="chekk"> <span>360 days + 60 days = 420 days</span></li>
                                 <li className="chekk"> <span>unlimited customer invite</span></li>
                                 <li className="chekk"> <span>1 employee profile setup</span></li>
                                 <li className="close"> <span>$30 for each new employee profile setup.</span></li>
                              </ul>
                           </Col>
                        </Card.Body>
                        <Card.Footer>
                           <Button variant="" type="submit" onClick={handleShow}>Purchase</Button>
                        </Card.Footer>
                     </Card>
                     </NavLink>
                     </Col>
                  </Row>
                  </Col>
      {/* ========== Subscription Box End ========== */}
                  <Col className="popupmsunscptn">
      {/* ========== Subscription Plan Popup Start ========== */}
                 <Modal show={show} onHide={handleClose} className="popupwrngcnt">
                        <Modal.Header closeButton>
                           <h3 className="mdlttltxt">Subscription Plan</h3>
                        </Modal.Header>
                        <Modal.Body>
                           <Col md={12} xs={12} className="ppinpt">
                           <h3 className="sbsrptn-tital">Price : $30</h3>
                           <Form>
                              <Form.Group className="mb-3">
                                 <Form.Label> </Form.Label>
                                 <Form.Check aria-label="option 1" id="" label="Enable Recurring" />
                              </Form.Group>
                           </Form>
                           </Col>
                           <Row className="pp-btn1 mx-0">
                              <Col><NavLink to="#" onClick={() => handleClose()}><Button className="blc" variant="">Pay Now</Button></NavLink></Col>
                           </Row>
                        </Modal.Body>
                        <Modal.Footer className="d-none"></Modal.Footer>
                     </Modal>
      {/* ========== Subscription Plan Popup End ========== */}
                  </Col>
                  </Row>
               </Container>
            </section>
      {/* ========== Footer Start ========== */}
            <Footer/>
      {/* ========== Footer End ========== */}
      {/* ========== Merchant Subscription Page End ========== */}
        </>
    )
}

export default MerSubscr;