import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Form, Modal} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './MerStore.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { MultiSelect } from "react-multi-select-component";

const MerStore = () => {
const [bsc,setBsc] = useState(false);
const [options1, setOptions1] = useState([
  { value: 0,  label: "Plumbers" },
  { value: 1,  label: "Garage door Services" },
  { value: 2,  label: "Carpet Cleaner" },
  { value: 3,  label: "Tree Treat" },
  { value: 4,  label: "Dog Training" },
  { value: 5,  label: "Swimming Pool Services" },
  { value: 6,  label: "Air Condition Services" },
  { value: 7,  label: "Electrician" },
  { value: 8,  label: "Locksmith" },
  { value: 9,  label: "Landscaping" },
  { value: 10, label: "Other " },
]);
const [isSunChecked, setSunIsChecked] = useState(true);
const [isMonChecked, setMonIsChecked] = useState(false);
const [isTueChecked, setTueIsChecked] = useState(false);
const [isWedChecked, setWedIsChecked] = useState(false);
const [isThuChecked, setThuIsChecked] = useState(false);
const [isFriChecked, setFriIsChecked] = useState(false);
const [isSatChecked, setSatIsChecked] = useState(false);

const handleSunCheckboxChange = () => {setSunIsChecked(!isSunChecked);};
const handleMonCheckboxChange = () => {setMonIsChecked(!isMonChecked);};
const handleTueCheckboxChange = () => {setTueIsChecked(!isTueChecked);};
const handleWedCheckboxChange = () => {setWedIsChecked(!isWedChecked);};
const handleThuCheckboxChange = () => {setThuIsChecked(!isThuChecked);};
const handleFriCheckboxChange = () => {setFriIsChecked(!isFriChecked);};
const handleSatCheckboxChange = () => {setSatIsChecked(!isSatChecked);};

const navigate = useNavigate();
const [values, setValues] = useState({password: "",showPassword: false,});
const [values1, setValues1] = useState({password: "",showPassword: false,});

const [show1, setShow1] = useState(false);
const handleClose1 = () => setShow1(false);
const handleShow1 = () => setShow1(true);

const [show2, setShow2] = useState(false);
const handleClose2 = () => setShow2(false);
const handleShow2 = () => setShow2(true);

const [show3, setShow3] = useState(false);
const handleClose3 = () => setShow3(false);
const handleShow3 = () => setShow3(true);

const [show4, setShow4] = useState(false);
const handleClose4 = () => setShow4(false);
const handleShow4 = () => setShow4(true);

const [optionSelected, setSelected] = useState([]);
  const handleChange = (selected: Option[]) => {
    setSelected(selected);
  };


useEffect(() => {
      document.title = `Merchant Store Setup | Blue Faucet`;
      document.getElementById('bodywrap').className='strstppgmain grenicn grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function(e) {
            $('#imagePreview').attr("src",e.target.result);
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
        }
        reader.readAsDataURL(input.files[0]);
    }
}
$("#imageUpload").change(function() {
    readURL(this);
});

},[]) 
const handleClickShowPassword = () => {setValues({ ...values, showPassword: !values.showPassword });}
const handleClickShowPassword1 = () => {setValues1({ ...values1, showPassword: !values1.showPassword });}

function getcat(val) {
     setBsc(true);
     if(val==='Home')
     {
         setOptions1([
  { value: 0,  label: "Plumbers" },
  { value: 1,  label: "Garage door Services" },
  { value: 2,  label: "Carpet Cleaner" },
  { value: 3,  label: "Tree Treat" },
  { value: 4,  label: "Dog Training" },
  { value: 5,  label: "Swimming Pool Services" },
  { value: 6,  label: "Air Condition Services" },
  { value: 7,  label: "Electrician" },
  { value: 8,  label: "Locksmith" },
  { value: 9,  label: "Landscaping" },
  { value: 10, label: "Other " },
]);
     }else if(val==='Restaurants'){
         setOptions1([
        { value: 0,  label: "Steak House" },
        { value: 1,  label: "Burgers" },
        { value: 2,  label: "Japanese" },
        { value: 3,  label: "Chinese" },
        { value: 4,  label: "Mexican" },
        { value: 5,  label: "Italian" },
        { value: 6,  label: "Thai" },
        { value: 7,  label: "Catering" },
        { value: 8,  label: "Coffee Shop" },
        { value: 9,  label: "Other " },
      ]);
     }else if(val==='Professional'){
         setOptions1([
           { value: 0,  label: "CPA" },
           { value: 1,  label: "Lawyer" },
           { value: 2,  label: "Dry Cleaner" },
           { value: 3,  label: "Hair Salon" },
           { value: 4,  label: "Barber Shop" },
           { value: 5,  label: "Pet Store" },
           { value: 6,  label: "Massage" },
           { value: 7,  label: "Party Planner" },
           { value: 8,  label: "Body Trainer" },
           { value: 9,  label: "Other " },
         ]);
     }
}
    return (
        <>
      {/* ========== Merchant Store Setup Page Start ========== */}
         <section className="gen-main">
      {/* ========== Navbar Start ========== */}
         <Row className="maincntmrcnt mx-0">
            <Col xs={2} md={2} className="align-self-center px-0">
            <button onClick={() =>
               navigate(-1)} className="back-btn">
               <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
               </svg>
            </button>
            </Col>
            <Col xs={8} md={8} className="align-self-center text-center">
            <h3>Store Setup</h3>
            </Col>
            <Col xs={2} md={2} className="align-self-center">
            <p></p>
            </Col>
         </Row>
      {/* ========== Navbar End ========== */}
         <Container>
            <Row md={12} className="genscrnmrcnt">
               <Col>
               <Row md={12} className="maincntsrcnmrcnt">
                  <Col className="px-0">
      {/* ========== Profile Upload Content Start ========== */}
                  <Col className="prflupld">
                  <div className="avatar-upload">
                     <div className="avatar-edit">
                        <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg"/>
                        <label htmlFor="imageUpload"></label>
                     </div>
                     <div className="avatar-preview">
                        <div ><img src="assets/images/merchant/usrbgup.png" id="imagePreview" className="img-fluid" alt="Logo"/></div>
                     </div>
                  </div>
                  </Col>
      {/* ========== Profile Upload Content Start ========== */}
                  <Form>
                     <Form.Group className="mb-3">
                        <Form.Label>Store Name<span>*</span></Form.Label>
                        <Form.Control type="text" placeholder="Enter your store name" />
                     </Form.Group>
                     <Form.Group className="mb-3">
                        <Form.Label>Enter Email<span>*</span></Form.Label>
                        <Form.Control type="email" placeholder="Enter your Email ID" />
                     </Form.Group>
                     <Form.Group className="mb-3">
                        <Form.Label>Store Owner Name<span>*</span></Form.Label>
                        <Form.Control type="text" placeholder="Enter store owner name" />
                     </Form.Group>
                     <Form.Group className="mb-3">
                        <Form.Label>Store's Location<span>*</span></Form.Label>
                        <Form.Select className="mb-2" aria-label="Default select example">
                           <option>Store's Location (default)</option>
                           <option defaultValue="1">Your Location</option>
                           <option defaultValue="2">Your Location</option>
                           <option defaultValue="3">Your Location</option>
                        </Form.Select>
                        <Form.Group className="mb-2">
                           <Form.Control type="location" placeholder="Street Name" />
                        </Form.Group>
                        <Form.Group className="mb-2">
                           <Form.Control placeholder="Area/apt "/>
                        </Form.Group>
                        <Form.Select className="mb-2" aria-label="Default select example">
                           <option>Select City</option>
                           <option defaultValue="1">New York</option>
                           <option defaultValue="2">Los Angeles</option>
                           <option defaultValue="3">Chicago</option>
                        </Form.Select>
                        <Form.Select className="mb-2" aria-label="Default select example">
                           <option>Select State</option>
                           <option defaultValue="1">option 1</option>
                           <option defaultValue="2">option 2</option>
                           <option defaultValue="3">option 3</option>
                        </Form.Select>
                        <Form.Group>
                           <Form.Control placeholder="Zip cide"/>
                        </Form.Group>
                     </Form.Group>
                     <Form.Group className="mb-3">
                        <Form.Label>Store Phone Number<span>*</span></Form.Label>
                        <Form.Control type="tel" placeholder="+1 984 236 564 0" />
                     </Form.Group>
      {/* ========== Select Business Category Content Start ========== */}
                     <Form.Label>Select Business Category<span>*</span></Form.Label>
                     <OwlCarousel items={2.4}  className='owl-theme mb-3 mstrstpsld' margin={10} >
                        <div className="csrd1" onClick={() => getcat('Home')}>
                           <div className="slctbox">
                              <div className="checkbox">
                                 <label className="checkbox-wrapper">
                                 <input className="checkbox-input" type="radio" name="slctbtnstrstp" id="one" defaultChecked={true}/>
                                 <span className="checkbox-tile">
                                 <span className="checkbox-icon">
                                 <img src= {'assets/images/merchant/hm-srvc.png'} className="img-fluid" alt="Sld Img"/>
                                 </span>
                                 <span className="checkbox-label">Home Services</span>
                                 </span>
                                 </label>
                              </div>
                           </div>
                        </div>
                        <div className="csrd1" onClick={() => getcat('Restaurants')}>
                              <div className="slctbox">
                                 <div className="checkbox">
                                    <label className="checkbox-wrapper">
                                    <input className="checkbox-input" type="radio" name="slctbtnstrstp" id="two" />
                                    <span className="checkbox-tile">
                                    <span className="checkbox-icon">
                                    <img src= {'assets/images/merchant/hm-img2.png'} className="img-fluid" alt="Sld Img"/>
                                    </span>
                                    <span className="checkbox-label">Restaurants</span>
                                    </span>
                                    </label>
                                 </div>
                              </div>
                        </div>
                        <div className="csrd1" onClick={() => getcat('Professional')}>
                              <div className="slctbox">
                                 <div className="checkbox">
                                    <label className="checkbox-wrapper">
                                    <input className="checkbox-input" type="radio" name="slctbtnstrstp" id="three" />
                                    <span className="checkbox-tile">
                                    <span className="checkbox-icon">
                                    <img src= {'assets/images/merchant/prfsnlsrvc-icn.png'} className="img-fluid" alt="Sld Img"/>
                                    </span>
                                    <span className="checkbox-label">Professional Services</span>
                                    </span>
                                    </label>
                                 </div>
                              </div>
                        </div>
                     </OwlCarousel>
      {/* ========== Select Business Category Content End ========== */}
                     {bsc && (
                     <Form.Group className="mb-3">
                       <Form.Label>Select Subcategory For Business<span></span></Form.Label>
                       <MultiSelect key="example_id" options={options1} onChange={handleChange} defaultValue={optionSelected} isSelectAll={true} menuPlacement={"bottom"}/>
                     </Form.Group>
                      )}  
                     <Col md={12} xs={12} className="mb-3">
                     <div className="custm-add d-none">
                        <NavLink to="#">
                           <Button variant="primary" onClick={handleShow1}>
                              Add Employee
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                 <path d="M7.62132 0.0863132C7.43385 0.183171 7.27138 0.373764 7.21826 0.558107C7.19952 0.633095 7.18702 1.7829 7.18702 3.92941V7.18822H3.93134C1.78172 7.18822 0.631923 7.20072 0.556936 7.21947C0.0945177 7.34757 -0.139816 7.92872 0.0882688 8.37864C0.182002 8.56611 0.372594 8.72858 0.556936 8.7817C0.631923 8.80045 1.78172 8.81295 3.93134 8.81295H7.18702V12.0686C7.18702 14.2183 7.19952 15.3681 7.21826 15.4431C7.34637 15.9055 7.92751 16.1398 8.37743 15.9117C8.5649 15.818 8.72737 15.6274 8.78049 15.4431C8.79923 15.3681 8.81173 14.2183 8.81173 12.0686V8.81295H12.0705C14.217 8.81295 15.3668 8.80045 15.4418 8.7817C15.6262 8.72858 15.8167 8.56611 15.9136 8.37864C16.1386 7.92872 15.9042 7.34757 15.4418 7.21947C15.3668 7.20072 14.217 7.18822 12.0705 7.18822H8.81173V3.92941C8.81173 1.7829 8.79923 0.633095 8.78049 0.558107C8.68988 0.233163 8.36181 0.00195217 7.99938 0.00195217C7.83378 0.00195217 7.75567 0.0206995 7.62132 0.0863132Z" fill="#3F9534"/>
                              </svg>
                           </Button>
                        </NavLink>
                     </div>
                     <div className="custm-add">
                        <NavLink to="#">
                           <Button variant="primary" onClick={handleShow2}>
                              Working Hours Setup
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                 <path d="M7.62132 0.0863132C7.43385 0.183171 7.27138 0.373764 7.21826 0.558107C7.19952 0.633095 7.18702 1.7829 7.18702 3.92941V7.18822H3.93134C1.78172 7.18822 0.631923 7.20072 0.556936 7.21947C0.0945177 7.34757 -0.139816 7.92872 0.0882688 8.37864C0.182002 8.56611 0.372594 8.72858 0.556936 8.7817C0.631923 8.80045 1.78172 8.81295 3.93134 8.81295H7.18702V12.0686C7.18702 14.2183 7.19952 15.3681 7.21826 15.4431C7.34637 15.9055 7.92751 16.1398 8.37743 15.9117C8.5649 15.818 8.72737 15.6274 8.78049 15.4431C8.79923 15.3681 8.81173 14.2183 8.81173 12.0686V8.81295H12.0705C14.217 8.81295 15.3668 8.80045 15.4418 8.7817C15.6262 8.72858 15.8167 8.56611 15.9136 8.37864C16.1386 7.92872 15.9042 7.34757 15.4418 7.21947C15.3668 7.20072 14.217 7.18822 12.0705 7.18822H8.81173V3.92941C8.81173 1.7829 8.79923 0.633095 8.78049 0.558107C8.68988 0.233163 8.36181 0.00195217 7.99938 0.00195217C7.83378 0.00195217 7.75567 0.0206995 7.62132 0.0863132Z" fill="#3F9534"/>
                              </svg>
                           </Button>
                        </NavLink>
                     </div>
                     <div className="custm-add">
                        <NavLink to="#">
                           <Button variant="primary" onClick={handleShow4}>
                              Stripe Accout Setup
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                 <path d="M7.62132 0.0863132C7.43385 0.183171 7.27138 0.373764 7.21826 0.558107C7.19952 0.633095 7.18702 1.7829 7.18702 3.92941V7.18822H3.93134C1.78172 7.18822 0.631923 7.20072 0.556936 7.21947C0.0945177 7.34757 -0.139816 7.92872 0.0882688 8.37864C0.182002 8.56611 0.372594 8.72858 0.556936 8.7817C0.631923 8.80045 1.78172 8.81295 3.93134 8.81295H7.18702V12.0686C7.18702 14.2183 7.19952 15.3681 7.21826 15.4431C7.34637 15.9055 7.92751 16.1398 8.37743 15.9117C8.5649 15.818 8.72737 15.6274 8.78049 15.4431C8.79923 15.3681 8.81173 14.2183 8.81173 12.0686V8.81295H12.0705C14.217 8.81295 15.3668 8.80045 15.4418 8.7817C15.6262 8.72858 15.8167 8.56611 15.9136 8.37864C16.1386 7.92872 15.9042 7.34757 15.4418 7.21947C15.3668 7.20072 14.217 7.18822 12.0705 7.18822H8.81173V3.92941C8.81173 1.7829 8.79923 0.633095 8.78049 0.558107C8.68988 0.233163 8.36181 0.00195217 7.99938 0.00195217C7.83378 0.00195217 7.75567 0.0206995 7.62132 0.0863132Z" fill="#3F9534"/>
                              </svg>
                           </Button>
                        </NavLink>
                     </div>
                     <div className="custm-add d-none">
                        <NavLink to="#">
                           <Button variant="primary" onClick={handleShow3}>
                              Invite Customer
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                 <path d="M7.62132 0.0863132C7.43385 0.183171 7.27138 0.373764 7.21826 0.558107C7.19952 0.633095 7.18702 1.7829 7.18702 3.92941V7.18822H3.93134C1.78172 7.18822 0.631923 7.20072 0.556936 7.21947C0.0945177 7.34757 -0.139816 7.92872 0.0882688 8.37864C0.182002 8.56611 0.372594 8.72858 0.556936 8.7817C0.631923 8.80045 1.78172 8.81295 3.93134 8.81295H7.18702V12.0686C7.18702 14.2183 7.19952 15.3681 7.21826 15.4431C7.34637 15.9055 7.92751 16.1398 8.37743 15.9117C8.5649 15.818 8.72737 15.6274 8.78049 15.4431C8.79923 15.3681 8.81173 14.2183 8.81173 12.0686V8.81295H12.0705C14.217 8.81295 15.3668 8.80045 15.4418 8.7817C15.6262 8.72858 15.8167 8.56611 15.9136 8.37864C16.1386 7.92872 15.9042 7.34757 15.4418 7.21947C15.3668 7.20072 14.217 7.18822 12.0705 7.18822H8.81173V3.92941C8.81173 1.7829 8.79923 0.633095 8.78049 0.558107C8.68988 0.233163 8.36181 0.00195217 7.99938 0.00195217C7.83378 0.00195217 7.75567 0.0206995 7.62132 0.0863132Z" fill="#3F9534"/>
                              </svg>
                           </Button>
                        </NavLink>
                     </div>
                     </Col>
                     <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Store Policy and Refund Policy</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Enter your store policy and Refund Policy..."/>
                     </Form.Group>
                     <Form.Group className="mb-3 pswinpt">
                        <Form.Label>Password<span>*</span></Form.Label>
                        <Form.Control type={values.showPassword ? "text" : "password"} id="enpsd1"/>
                        {values.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword}></i>}
                     </Form.Group>
                     <Form.Group className="mb-3 pswinpt">
                        <Form.Label>Confirm Password<span>*</span></Form.Label>
                        <Form.Control type={values1.showPassword ? "text" : "password"} id="renpsd1" />
                        {values1.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword1}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword1}></i>}
                     </Form.Group>
                     <Form.Group className="mb-3 ckbox" controlId="">
                        <Form.Check aria-label="option 1" defaultChecked={true}/>
                        <Form.Label>
                           I agree with 
                           <NavLink to="/mer-terms"> Terms </NavLink>
                           and 
                           <NavLink to="/mer-privacy">Privacy Policy</NavLink>
                        </Form.Label>
                     </Form.Group>
                     <div className="mb-3 btn-dv">
                        <NavLink to="/mer-login"><button type="button" className="btn"> Sign Up</button></NavLink>
                     </div>
                     <div className="snguplsttxt text-center">
                        <p>
                           Have an account? 
                           <NavLink to="/mer-login"> Login</NavLink>
                        </p>
                     </div>
                  </Form>
                  </Col>
               </Row>
               </Col>
               <div className="popupcntmrcntflw">
                  <div className="popupmrcntflow1">
      {/* ========== Employee Setup Popup Content Start ========== */}
                     <Modal show={show1} onHide={handleClose1} className="popupwrngcnt">
                        <Modal.Header closeButton>
                           <h3 className="mdlttltxt">Employee Setup</h3>
                        </Modal.Header>
                        <Modal.Body>
                           <Col md={12} xs={12} className="ppinpt">
                           <Form>
                              <Form.Group className="mb-3">
                                 <Form.Label>Employee ID</Form.Label>
                                 <Form.Control type="text" placeholder="Enter employee ID" />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                 <Form.Label>Employee Name</Form.Label>
                                 <Form.Control type="text" placeholder="Enter employee name" />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                 <Form.Label>Employee Contact No.</Form.Label>
                                 <Form.Control type="tel" placeholder="+1 984 236 564 0" />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                 <Form.Label>Date of Birth</Form.Label>
                                 <Form.Control type="date" placeholder="dd/mm/yy" />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                 <Form.Label>Commission (in %)<span></span></Form.Label>
                                 <Form.Control type="Number" placeholder="5%" min="0" />
                              </Form.Group>
                              <div className="custm-add mb-3">
                                 <NavLink to="#">
                                    <div className="linetp-cutmadd"></div>
                                    <Button variant="" onClick={handleShow2}>
                                       Working Hours Setup
                                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                          <path d="M7.62132 0.0863132C7.43385 0.183171 7.27138 0.373764 7.21826 0.558107C7.19952 0.633095 7.18702 1.7829 7.18702 3.92941V7.18822H3.93134C1.78172 7.18822 0.631923 7.20072 0.556936 7.21947C0.0945177 7.34757 -0.139816 7.92872 0.0882688 8.37864C0.182002 8.56611 0.372594 8.72858 0.556936 8.7817C0.631923 8.80045 1.78172 8.81295 3.93134 8.81295H7.18702V12.0686C7.18702 14.2183 7.19952 15.3681 7.21826 15.4431C7.34637 15.9055 7.92751 16.1398 8.37743 15.9117C8.5649 15.818 8.72737 15.6274 8.78049 15.4431C8.79923 15.3681 8.81173 14.2183 8.81173 12.0686V8.81295H12.0705C14.217 8.81295 15.3668 8.80045 15.4418 8.7817C15.6262 8.72858 15.8167 8.56611 15.9136 8.37864C16.1386 7.92872 15.9042 7.34757 15.4418 7.21947C15.3668 7.20072 14.217 7.18822 12.0705 7.18822H8.81173V3.92941C8.81173 1.7829 8.79923 0.633095 8.78049 0.558107C8.68988 0.233163 8.36181 0.00195217 7.99938 0.00195217C7.83378 0.00195217 7.75567 0.0206995 7.62132 0.0863132Z" fill="#3F9534"/>
                                       </svg>
                                    </Button>
                                 </NavLink>
                              </div>
                              <Form.Group className="mb-3 pswinpt">
                                 <Form.Label>Password<span></span></Form.Label>
                                 <Form.Control type={values.showPassword ? "text" : "password"} id="enpsw" defaultValue="password" placeholder="At least 8 characters"/>
                                 {values.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword}></i>}
                              </Form.Group>
                              <Form.Group className="mb-3 pswinpt">
                                 <Form.Label>Re-enter Password<span></span></Form.Label>
                                 <Form.Control type={values1.showPassword ? "text" : "password"} id="renpsw" defaultValue="password" placeholder="At least 8 characters" />
                                 {values1.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword1}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword1}></i>}
                              </Form.Group>
                           </Form>
                           </Col>
                           <Row className="pp-btn1 mx-0">
                              <Col>
                              <NavLink to="#" onClick={() => handleClose1()}><Button className="wht" variant="">Delete</Button></NavLink>
                              </Col>
                              <Col>
                              <NavLink to="#" onClick={() => handleClose1()}><Button className="blc" variant="">Add</Button></NavLink>
                              </Col>
                           </Row>
                        </Modal.Body>
                        <Modal.Footer className="d-none"></Modal.Footer>
                     </Modal>
      {/* ========== Employee Setup Popup Content End ========== */}
                  </div>
                  <div className="inmodal">
      {/* ========== Working Hours Setup Popup Content Start ========== */}
                     <Modal show={show2} onHide={handleClose2} className="popupwrngcnt">
                        <Modal.Header closeButton>
                           <h3 className="mdlttltxt">Working Hours Setup</h3>
                        </Modal.Header>
                        <Modal.Body>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className="align-self-center">
                              <Form.Label>Sunday</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label=""  checked={isSunChecked} onChange={handleSunCheckboxChange} />
                              </Col>
                              {isSunChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                              </Row>
                              </Col>
                              }
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className="align-self-center">
                              <Form.Label>Monday</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={isMonChecked} onChange={handleMonCheckboxChange} />
                              </Col>
                              {isMonChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                              </Row>
                              </Col>   
                              }                              
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className="align-self-center">
                              <Form.Label>Tuesday</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={isTueChecked} onChange={handleTueCheckboxChange} />
                              </Col>
                              {isTueChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                              </Row>
                              </Col>   
                              }                                  
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className=" mb-2 align-self-center">
                              <Form.Label>Wednesday</Form.Label>
                              </Col>
                              <Col xs={2} className=" mb-2 align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={isWedChecked} onChange={handleWedCheckboxChange}/>
                              </Col>
                              {isWedChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                              </Row>
                              </Col>   
                              }    
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className=" mb-2 align-self-center">
                              <Form.Label>Thursday</Form.Label>
                              </Col>
                              <Col xs={2} className=" mb-2 align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={isThuChecked} onChange={handleThuCheckboxChange}/>
                              </Col>
                              {isThuChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                              </Row>
                              </Col>   
                              }         
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className=" mb-2 align-self-center">
                              <Form.Label>Friday</Form.Label>
                              </Col>
                              <Col xs={2} className=" mb-2 align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={isFriChecked} onChange={handleFriCheckboxChange}/>
                              </Col>
                              {isFriChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                              </Row>
                              </Col>   
                              }    
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className=" mb-2 align-self-center">
                              <Form.Label>Saturday</Form.Label>
                              </Col>
                              <Col xs={2} className=" mb-2 align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={isSatChecked} onChange={handleSatCheckboxChange}/>
                              </Col>
                              {isSatChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                              </Row>
                              </Col>   
                              }                                  
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={12} className="align-self-center">
                              <h3 className="mdlttltxt float-start">Orders/Appointments settings</h3>
                              <span style={{ }}>
                                 <OverlayTrigger delay={{ hide: 450, show: 300 }} overlay={(props) => (
                                 <Tooltip {...props}>Get more information in FAQs!</Tooltip>
                                 )}placement="bottom">
                                 <NavLink to="/mer-help"> <span className="mdlttltxt text-danger">&nbsp;? </span></NavLink>
                                 </OverlayTrigger>
                              </span>
                              </Col>
                              <div className="linepop my-1"></div>
                              <Row className="chkbtn mx-0 mb-3 mt-3 px-0">
                                 <Col xs={9} className="align-self-center">
                                 <Form.Label>How Many customers can be accepted per time slot (per hour):</Form.Label>
                                 </Col>
                                 <Col xs={3} className="align-self-center">
                                  <Form.Control type="Number" placeholder="4" />
                                 </Col>
                              </Row>
                              <Col xs={10} className="align-self-center mb-2">
                              <Form.Label>Auto accept during working hours</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" defaultChecked={true}/>
                              </Col>
                              <Col xs={10} className="align-self-center mb-2">
                              <Form.Label>Need to be Confirmed</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label=""/>
                              </Col>
                              <Col xs={10} className="align-self-center mb-2">
                              <Form.Label>Auto Refuse (customers in the blocklist)</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" defaultChecked={true}/>
                              </Col>
                           </Row>
                           <Row className="pp-btn1 mx-0">
                              <Col>
                              <NavLink to="#" onClick={() => handleClose2()}><Button className="blc" variant="">Confirm</Button></NavLink>
                              </Col>
                           </Row>
                        </Modal.Body>
                        <Modal.Footer className="d-none"></Modal.Footer>
                     </Modal>
      {/* ========== Working Hours Setup Popup Content End ========== */}
                  </div>
                  <div className="modal3">
      {/* ========== Share Popup Content Start ========== */}
                     <Modal size="lg" show={show3} onHide={handleClose3} aria-labelledby="example-modal-sizes-title-lg" className="trdmodal" id="trdmdl3">
                        <Modal.Body>
                           <Row className="mx-0 tpcrd-pop3">
                              <Col md={2} xs={2} className="align-self-center">
                              <img src="assets/images/merchant/user-img.png" className="img-fluid" alt="Hm Img"/></Col>
                              <Col md={10} xs={10} className="align-self-center">
                              <h3>R-Cube Spa</h3>
                              <p>lorem.ipsum@gmail.com</p>
                              </Col>
                              <Col md={12} xs={12} className="dwncnt3">
                              <h4>Share via</h4>
                              <ul>
                                 <NavLink to="#">
                                    <li>
                                       <img src="assets/images/merchant/icn-1.png" className="img-fluid" alt="Hm Img"/>
                                       <p>Yelp</p>
                                    </li>
                                 </NavLink>
                                 <NavLink to="#">
                                    <li>
                                       <img src="assets/images/merchant/icn-2.png" className="img-fluid" alt="Hm Img"/>
                                       <p>Twitter</p>
                                    </li>
                                 </NavLink>
                                 <NavLink to="#">
                                    <li>
                                       <img src="assets/images/merchant/icn-3.png" className="img-fluid" alt="Hm Img"/>
                                       <p>Instagram</p>
                                    </li>
                                 </NavLink>
                                 <NavLink to="#">
                                    <li>
                                       <img src="assets/images/merchant/icn-4.png" className="img-fluid" alt="Hm Img"/>
                                       <p>Meta</p>
                                    </li>
                                 </NavLink>
                                 <NavLink to="#">
                                    <li>
                                       <img src="assets/images/merchant/icn-5.png" className="img-fluid" alt="Hm Img"/>
                                       <p>WhatsApp</p>
                                    </li>
                                 </NavLink>
                              </ul>
                              <Col md={12} xs={12} className="cpytxtdv">
                              <h4>Or copy link</h4>
                              <div className="copy-text">
                                 <input type="text" className="text" defaultValue="https://app.bluefaucet.co..." />
                                 <button>Copy</button>
                              </div>
                              </Col>
                              </Col>
                           </Row>
                        </Modal.Body>
                        <Modal.Footer className="d-none"></Modal.Footer>
                     </Modal>
      {/* ========== Share Popup Content End ========== */}
                  </div>
                  <div className="modal4">
      {/* ========== Stripe Account Details Popup Content Start ========== */}
                     <Modal size="" show={show4} onHide={handleClose4} aria-labelledby="example-modal-sizes-title-lg" className="frthmodal" id="trdmdl4">
                        <Modal.Header closeButton>
                           <h3 className="mdlttltxt">
                              Stripe Account Details
                              <span style={{ }}>
                                 <OverlayTrigger delay={{ hide: 450, show: 300 }} overlay={(props) => (
                                 <Tooltip {...props}>Get more information in FAQs!</Tooltip>
                                 )}placement="bottom">
                                 <NavLink to="/mer-help"> <span className="mdlttltxt text-danger">&nbsp;? </span></NavLink>
                                 </OverlayTrigger>
                              </span>
                           </h3>
                        </Modal.Header>
                        <Modal.Body>
                           <Form.Group className="mb-3">
                              <Form.Label>Bank Account Name </Form.Label>
                              <Form.Control type="text" placeholder="Enter bank account name" />
                           </Form.Group>
                           <Form.Group className="mb-3">
                              <Form.Label>Bank Name</Form.Label>
                              <Form.Control type="text" placeholder="Enter bank name" />
                           </Form.Group>
                           <Form.Group className="mb-3">
                              <Form.Label>Routing Number</Form.Label>
                              <Form.Control type="text" placeholder="Enter routing number" />
                           </Form.Group>
                           <Form.Group className="mb-3">
                              <Form.Label>Account Number</Form.Label>
                              <Form.Control type="text" placeholder="Enter account number" />
                           </Form.Group>
                           <Row className="pp-btn1">
                              <Col className="gren-btn">
                              <NavLink to="#" onClick={() => handleClose4()}><Button className="blc" variant="">Add Account</Button></NavLink>
                              </Col>
                           </Row>
                        </Modal.Body>
                        <Modal.Footer className="d-none"></Modal.Footer>
                     </Modal>
      {/* ========== Stripe Account Details Popup Content End ========== */}
                  </div>
               </div>
            </Row>
         </Container>
      </section>
      {/* ========== Merchant Store Setup Page Start ========== */}
      </>
    )
}
export default MerStore;