import React, { useEffect } from 'react';
import { Row, Col, Container  } from 'react-bootstrap';
import './SalSignup.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';

const SalSignup = () => {
const navigate = useNavigate();
useEffect(() => {
      document.title = `Sales Signup | Blue Faucet`;
      document.getElementById('bodywrap').className='mabutpgmain ylwthem slsagrmnt';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function(e) {
            $('#imagePreview').attr("src",e.target.result);
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
        }
        reader.readAsDataURL(input.files[0]);
    }
}
$("#imageUpload").change(function() {
    readURL(this);
});

},[])  
    return (
        <>
        {/* ========== Sales Signup Page Start ========== */}
          <section className="gen-main">
        {/* ========== Navbar Start ========== */}
            <Row className="maincntylwtm mx-0">
              <Col xs={2} md={2} className="align-self-center px-0">
              <button onClick={() => navigate(-1)} className="back-btn">
                <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
                 </svg>
              </button>
              </Col>
              <Col xs={8} md={8} className="align-self-center text-center"><img src= {'assets/images/sales/svg/white-logo.svg'} className="img-fluid" alt="Sld Img"/></Col>
              <Col xs={2} md={2} className="align-self-center"><p></p></Col>
            </Row>
        {/* ========== Navbar End ========== */}
               <Container>
                <Row md={12} className="genscrnylwtm">
                  <Col>
        {/* ========== Payment Setup Content Start ========== */}
                     <Row md={12} className="maincntsrnylwtm">
                      <Col md={12} xs={12} className="px-0 maincnt-ssgnup">
                        <h3>BlueFaucet Sales Agreement</h3>
                        <p>This Sales Agreement (the “Agreement”) is entered into today by and between BlueFaucet Inc., a California corporation, and the SALES AGNET (the “Agent”) (collectively, “the parties’). The Agreement shall be effective immediately upon the parties’ checked and dated on the Sales Page.</p>
                        <p>WHEREAS, BlueFaucet Inc. is a developer of BlueFaucet software and a “BlueFaucet” brand owner in the United States (“U.S.”).</p>
                        <p>WHEREAS, Agent is a contracted sales agent with great relationships with local merchants in the U.S.</p>
                        <p>NOW, THEREFORE, in consideration of the mutual promises and undertakings stated herein, the parties hereto agree as follows:</p>
                        <h5>DEFINITIONS</h5>
                        <ul>
                          <li className="bullet">“Merchant”: In this context, a "Merchant" refers to a small business that is either based in or operated within the United States. Additionally, the term can also apply to the owner of a small business that operates within the U.S.</li>
                          <li className="bullet">“Commission”: The term "Commission" indicates a one-time payment that an Agent receives as compensation for their efforts. When the Agent successfully invites a merchant who then becomes a paid merchant of BlueFaucet, the Agent is eligible to receive a one-time commission of $60 for that specific sale.</li>
                          <li className="bullet">“Price”: "Price" represents the specific amount of money required to purchase one of BlueFaucet's membership packages. It's important to note that BlueFaucet retains the right to adjust the price as they see fit.</li>
                          <li className="bullet">“Paid Merchant”: A "Paid Merchant" is a merchant who has already completed the purchase of a membership package from BlueFaucet. This means the merchant is no longer a potential customer but has acquired one of the offered membership packages from BlueFaucet.</li>
                        </ul>
                        <h5>AGENT DEVELOPMENT</h5>
                        <ul>
                          <li className="bullet">The agent must ensure that they are familiar with BlueFaucet's services.</li>
                          <li className="bullet">The agent will leverage their relationships with local merchants to introduce BlueFaucet's services to them and persuade them to become Paid Merchants.</li>
                          <ul><li className="circle">The agent will use BlueFaucet's Invitation page to send email invitations to merchants or utilize the "Copy" button to copy their unique URL and share it with merchants through other communication apps, aiming to encourage local merchants to become Paid Merchants.</li></ul>
                          <li className="bullet">BlueFaucet may assist the Sales team in providing information to the merchants to update them about the services that BlueFaucet can offer.</li>
                        </ul>
                        <h5>COMMISSION</h5>
                        <ul>
                          <li className="bullet">Agent’s Commission Payment:</li>
                          <ul>
                            <li className="circle">BlueFaucet offers three membership packages for merchants to choose from 3 months, 6 months (get 1 extra month and an employee account free), and 12 months (get 2 extra months and an employee account free).</li>
                            <li className="circle">Every merchant is provided a 30-day free trial period.</li>
                            <li className="circle">If a merchant's number of customers is 50 or below, they can keep their membership for free.</li>
                            <li className="circle">BlueFaucet reserves the right to implement promotional programs aimed at extending free membership for merchants or offering exclusive agent agreements to specific areas.</li>
                          </ul>
                          <li className="bullet">Commission Crediting and Sales Page:</li>
                          <ul><li className="circle">The commission will be credited to the Agent's account, and they can view their sales-related information on their Sales Page.</li></ul>
                          <li className="bullet">IRS W-9 Form and Eligibility to Work in the US:</li>
                          <ul>
                            <li className="circle">To receive payment, the Agent must complete and return an IRS W-9 Form online.</li>
                            <li className="circle">BlueFaucet can only pay sales commissions to Agents who are eligible to work in the United States.</li>
                          </ul>
                          <li className="bullet">Direct Bank Deposit and Commission Payments:</li>
                          <ul>
                            <li className="circle">The Agent can request to receive their commission payments via direct bank deposit from the Sales Page.</li>
                            <li className="circle">To be eligible for free direct bank deposit, the Agent must withdraw at least $200 of commission payments per transaction.</li>
                            <li className="circle">If the total amount of commission the Agent withdraw is less than $200, they will be charged a $20 processing fee for each transaction.</li>
                            <li className="circle">merchant can keep their free membership.  Before the merchant becomes a Paid Merchant, Sales won’t get their commission.</li>
                          </ul>
                        </ul>
                        <h5>INDEMNIFICATION</h5>
                        <ul>
                          <li className="bullet">BlueFaucet agrees to indemnify, defend, and hold harmless the Agent, its employees, officers, directors, shareholders, agents, representatives, and affiliates from any and all claims, causes of action, judgments, settlement costs, and other expenses (including reasonable attorney fees and expenses) collectively referred to as "Liabilities." These Liabilities arise from the Agent's breach of any representation, warranty, or covenant outlined in this Agreement, as well as any claims related to service liability, strict liability in tort, negligence, or any other claim arising from the development of BlueFaucet's products.</li>
                          <li className="bullet">The Agent agrees to indemnify, defend, and hold BlueFaucet, its employees, officers, directors, shareholders, agents, representatives, and Affiliates harmless from any and all claims, causes of action, judgments, settlement costs, and other expenses (referred to as "Liabilities") arising from the following: (a) The advertising, marketing, or sales of any of BlueFaucet's services in the United States, including compliance with all legal requirements. (b) BlueFaucet's breach of any representation, warranty, or covenant stated in this Agreement. (c) Any claim alleging trademark, trade dress, or branding infringement, including the use of the "BlueFaucet" trademark or trade name.</li>
                          <li className="bullet">Conditions of Indemnification: In the event that a party (referred to as the "Indemnified Party") seeks indemnification from the other party (referred to as the "Indemnifying Party") under this Agreement for any claim, the Indemnified Party shall adhere to the following conditions: </li>
                          <ul>
                            <li className="lower-alpha">The Indemnified Party shall promptly notify the Indemnifying Party in writing of any claim, proceeding, or suit alleging a Liability (collectively, a "Claim") within fifteen (15) days after receiving notice of such Claim, or within a reasonable period that would not substantially prejudice the rights of the alleged Indemnifying Party in defending such Claim, whichever period is shorter.</li>
                            <li className="lower-alpha">The Indemnifying Party will be given sole control over the defense and investigation of the Claim, and the Indemnified Party shall cooperate and assist the Indemnifying Party and its representatives in the defense of the Claim.</li>
                            <li className="lower-alpha">The Indemnified Party shall not attempt to settle, compromise, or resolve the claim or action without obtaining the Indemnifying Party's prior written consent unless such settlement fully releases the Indemnifying Party from any liability, loss, cost, or obligation without any adverse consequences for the Indemnifying Party. The consent for settlement will not be unreasonably withheld by the Indemnifying Party.</li>
                          </ul>
                        </ul>
                        <h5>TERMINATION</h5>
                        <ul>
                          <li className="bullet">Both parties have the right to terminate this agreement. When one party unchecks the box corresponding to the words “Sales Agreement” on the Sales Page, BlueFaucet will immediately stop the sales activities.</li>
                        </ul>
                        <h5>NONDISCLOSURE</h5>
                        <ul>
                          <li className="bullet">During the course of performing this Agreement, it is expected that both parties may share Confidential Information with each other. </li>
                          <li className="bullet">Both parties agree that, except as explicitly stated in this Agreement, they will not disclose any Confidential Information received from the other party. Furthermore, they shall not use the Confidential Information provided by the other party for any purpose other than fulfilling their obligations under this Agreement.</li>
                          <li className="bullet">However, it is clarified that nothing in this Agreement shall prevent either BlueFaucet or the Agent from utilizing Confidential Information if it is necessary for the development of the Product, as per the terms and conditions specified in this Agreement.</li>
                        </ul>
                        <h5>MISCELLANEOUS</h5>
                        <ul>
                          <li className="bullet">Governing Law, Forum, and Jurisdiction. This Agreement will be construed according to the laws of the State of California in the U.S. without regard to principles of conflicts of law. The parties agree that any action at law or in equity arising out of or relating to this Agreement shall be filed only in state or federal courts for the Superior Court in Pomona of California. The parties hereby consent and submit to the personal jurisdiction of such courts for the purpose of litigating any such action.</li>
                          <li className="bullet">Notices. All notices that either party is required or may desire to serve upon the other party shall be in writing and emailed or posted to the app to each other.</li>
                          <li className="bullet">Independent Contractor. Agent is an independent agent. Nothing herein shall be construed to constitute the parties as principal and agent, employer and employee, partners or joint venturers, nor shall any similar relationship be deemed to exist between the parties. Neither party shall have the power to obligate or bind the other party. You agree that you will be responsible for, and BlueFaucet will not reimburse you for, any expenses incurred by you in connection with your performance under this</li>
                          <li className="bullet">Cumulative Remedies; Waiver. Except as may be specifically set forth in this Agreement, the rights and remedies of the parties set forth herein are not exclusive and are in addition to any other rights and remedies provided hereunder or by law. No waiver of any provision of this Agreement or any rights or obligations hereunder shall be effective, except pursuant to a written instrument signed by the party waiving compliance.</li>
                          <li className="bullet">Entire Agreement. This Agreement states the entire agreement between the parties with respect to its subject matter and supersedes any prior oral or written agreements between the parties. This Agreement is made for the benefit of BlueFaucet and Agent only, and the Agreement is not for the benefit of and was not created for the benefit of, any third parties. This Agreement may not be amended except in writing signed by both parties.</li>
                          <li className="bullet">Counterparts. This Agreement may be executed in counterparts, including by facsimile, with each counterpart deemed an original document and all counterparts together a single instrument.</li>
                          <li className="bullet">Obligation of Company. The Company shall at all times and keep accurate records and books relating to customers under this agreement, and when requested in writing, the Company shall permit Agent or Agent’s representative to, not more than twice per calendar year, inspect said records which relate to commission payments due to Agent.</li>
                        </ul>
                      </Col>
                     </Row>
        {/* ========== Payment Setup Content End ========== */}
                  </Col>
                </Row>
               </Container>
          </section>
        {/* ========== Sales Signup Page End ========== */}
        </>
    )
}
export default SalSignup;