import React, { useEffect } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import './UserCtgrylst.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
// import $ from 'jquery';

const UserCtgrylst = () => {
useEffect(() => {
      document.title = `User Category List | Blue Faucet`;
      document.getElementById('bodywrap').className='uctgrylstpgmain srcblk';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
},[]) 

    return (
        <>
            {/* ========== User Ctagory List Page Start ========== */}
            <section className="gen-wrap">
            {/* ========== Navbar Start ========== */}
               <Navbar headerText=""/>
            {/* ========== Navbar End ========== */}
               <Container>
                  <Row className="hmtpfixdv">
                     <Col md={12} lg={12} xl={12} xxl={12} className="hmtp-scrl uclsrldv">
                     <ul className="hmtp-scldv">
                        <Row className="mx-0 ucl-sfbtn">
                           <Col>
                           <NavLink to="#">
                              <Dropdown>
                                 <Dropdown.Toggle variant="" id="dropdown-basic"><span><img src="assets/images/svg/ucl-srtimg.svg" className="img-fluid" alt="Usl Img"/></span>Sort</Dropdown.Toggle>
                                 <Dropdown.Menu>
                                    <Dropdown.Item href={void(0)}>Popular</Dropdown.Item>
                                    <Dropdown.Item href={void(0)}>Rating</Dropdown.Item>
                                    <Dropdown.Item href={void(0)}>Price Low to high</Dropdown.Item>
                                    <Dropdown.Item href={void(0)}>Price High to Low</Dropdown.Item>
                                 </Dropdown.Menu>
                              </Dropdown>
                           </NavLink>
                           </Col>
                           <Col>
                           <NavLink to="#"><Button variant="" type="submit"><span><img src="assets/images/svg/ucl-fltrimg.svg" className="img-fluid" alt="Usl Img"/></span>Filter</Button></NavLink>
                           </Col>
                        </Row>
                     </ul>
                     </Col>
                  </Row>
                  {/* ========== Ctagory List Box Start ========== */}
                  <Row className="mx-0 mianhmdwncnt">
                     <Col className="mnhmcnt">
                     <Col md={12} xs={12} className="py-2">
                     <Row className="mx-0 ucl-box">
                        <Col md={1} xs={2} className="align-self-center px-0 text-center">
                        <img src="assets/images/hm-img1.png" className="img-fluid" alt="Hm Img"/></Col>
                        <Col md={9} xs={7} className="align-self-center px-3 px-md-3">
                        <h3>Saloon Shop</h3>
                        <p>Starting from <span>$49.99</span></p>
                        </Col>
                        <Col md={2} xs={3} className="align-self-center text-end px-0">
                        <NavLink to="/store-list"><Button variant="" type="submit">View All</Button></NavLink>
                        </Col>
                     </Row>
                     </Col>
                     <Col md={12} xs={12} className="py-2">
                     <Row className="mx-0 ucl-box">
                        <Col md={1} xs={2} className="align-self-center px-0 text-center">
                        <img src="assets/images/hm-img2.png" className="img-fluid" alt="Hm Img"/></Col>
                        <Col md={9} xs={7} className="align-self-center px-3 px-md-3">
                        <h3>Restaurants</h3>
                        <p>Starting from <span>$49.99</span></p>
                        </Col>
                        <Col md={2} xs={3} className="align-self-center text-end px-0">
                        <NavLink to="/store-list"><Button variant="" type="submit">View All</Button></NavLink>
                        </Col>
                     </Row>
                     </Col>
                     <Col md={12} xs={12} className="py-2">
                     <Row className="mx-0 ucl-box">
                        <Col md={1} xs={2} className="align-self-center px-0 text-center">
                        <img src="assets/images/hm-img3.png" className="img-fluid" alt="Hm Img"/></Col>
                        <Col md={9} xs={7} className="align-self-center px-3 px-md-3">
                        <h3>Grocery</h3>
                        <p>Starting from <span>$49.99</span></p>
                        </Col>
                        <Col md={2} xs={3} className="align-self-center text-end px-0">
                        <NavLink to="/store-list"><Button variant="" type="submit">View All</Button></NavLink>
                        </Col>
                     </Row>
                     </Col>
                     <Col md={12} xs={12} className="py-2">
                     <Row className="mx-0 ucl-box">
                        <Col md={1} xs={2} className="align-self-center px-0 text-center">
                        <img src="assets/images/hm-img4.png" className="img-fluid" alt="Hm Img"/></Col>
                        <Col md={9} xs={7} className="align-self-center px-3 px-md-3">
                        <h3>Big Deals</h3>
                        <p>Starting from <span>$49.99</span></p>
                        </Col>
                        <Col md={2} xs={3} className="align-self-center text-end px-0">
                        <NavLink to="/store-list"><Button variant="" type="submit">View All</Button></NavLink>
                        </Col>
                     </Row>
                     </Col>
                     <Col md={12} xs={12} className="py-2">
                     <Row className="mx-0 ucl-box">
                        <Col md={1} xs={2} className="align-self-center px-0 text-center">
                        <img src="assets/images/hm-img5.png" className="img-fluid" alt="Hm Img"/></Col>
                        <Col md={9} xs={7} className="align-self-center px-3 px-md-3">
                        <h3>Spa Deals</h3>
                        <p>Starting from <span>$49.99</span></p>
                        </Col>
                        <Col md={2} xs={3} className="align-self-center text-end px-0">
                        <NavLink to="/store-list"><Button variant="" type="submit">View All</Button></NavLink>
                        </Col>
                     </Row>
                     </Col>
                     <Col md={12} xs={12} className="py-2">
                     <Row className="mx-0 ucl-box">
                        <Col md={1} xs={2} className="align-self-center px-0 text-center">
                        <img src="assets/images/hm-img1.png" className="img-fluid" alt="Hm Img"/></Col>
                        <Col md={9} xs={7} className="align-self-center px-3 px-md-3">
                        <h3>Saloon Shop</h3>
                        <p>Starting from <span>$49.99</span></p>
                        </Col>
                        <Col md={2} xs={3} className="align-self-center text-end px-0">
                        <NavLink to="/store-list"><Button variant="" type="submit">View All</Button></NavLink>
                        </Col>
                     </Row>
                     </Col>
                     <Col md={12} xs={12} className="py-2">
                     <Row className="mx-0 ucl-box">
                        <Col md={1} xs={2} className="align-self-center px-0 text-center">
                        <img src="assets/images/hm-img2.png" className="img-fluid" alt="Hm Img"/></Col>
                        <Col md={9} xs={7} className="align-self-center px-3 px-md-3">
                        <h3>Restaurants</h3>
                        <p>Starting from <span>$49.99</span></p>
                        </Col>
                        <Col md={2} xs={3} className="align-self-center text-end px-0">
                        <NavLink to="/store-list"><Button variant="" type="submit">View All</Button></NavLink>
                        </Col>
                     </Row>
                     </Col>
                     <Col md={12} xs={12} className="py-2">
                     <Row className="mx-0 ucl-box">
                        <Col md={1} xs={2} className="align-self-center px-0 text-center">
                        <img src="assets/images/hm-img3.png" className="img-fluid" alt="Hm Img"/></Col>
                        <Col md={9} xs={7} className="align-self-center px-3 px-md-3">
                        <h3>Grocery</h3>
                        <p>Starting from <span>$49.99</span></p>
                        </Col>
                        <Col md={2} xs={3} className="align-self-center text-end px-0">
                        <NavLink to="/store-list"><Button variant="" type="submit">View All</Button></NavLink>
                        </Col>
                     </Row>
                     </Col>
                     <Col md={12} xs={12} className="py-2">
                     <Row className="mx-0 ucl-box">
                        <Col md={1} xs={2} className="align-self-center px-0 text-center">
                        <img src="assets/images/hm-img4.png" className="img-fluid" alt="Hm Img"/></Col>
                        <Col md={9} xs={7} className="align-self-center px-3 px-md-3">
                        <h3>Big Deals</h3>
                        <p>Starting from <span>$49.99</span></p>
                        </Col>
                        <Col md={2} xs={3} className="align-self-center text-end px-0">
                        <NavLink to="/store-list"><Button variant="" type="submit">View All</Button></NavLink>
                        </Col>
                     </Row>
                     </Col>
                     <Col md={12} xs={12} className="py-2">
                     <Row className="mx-0 ucl-box">
                        <Col md={1} xs={2} className="align-self-center px-0 text-center">
                        <img src="assets/images/hm-img5.png" className="img-fluid" alt="Hm Img"/></Col>
                        <Col md={9} xs={7} className="align-self-center px-3 px-md-3">
                        <h3>Spa Deals</h3>
                        <p>Starting from <span>$49.99</span></p>
                        </Col>
                        <Col md={2} xs={3} className="align-self-center text-end px-0">
                        <NavLink to="/store-list"> <Button variant="" type="submit">View All</Button></NavLink>
                        </Col>
                     </Row>
                     </Col>
                     </Col>
                  </Row>
                  {/* ========== Ctagory List Box Start ========== */}
               </Container>
            </section>
            {/* ========== User Ctagory List Page End ========== */}
        </>
    )
}

export default UserCtgrylst;