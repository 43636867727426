import React, { useEffect } from 'react';
import { Row, Col, Container, Button, Form } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './MerForgot.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
//import $ from 'jquery';

const MerForgot = () => {
const navigate = useNavigate();
useEffect(() => {
      document.title = `Merchant Forgot Password | Blue Faucet`;
      document.getElementById('bodywrap').className='frgtpgmain mfrgt grenicn grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });       
},[])  
    return (
        
        <> 
        {/* ========== Merchant Forget Page Start ========== */}
        <section className="gen-wrap gen-main">
        {/* ========== Navbar Start ========== */}
          <Row className="maincnttpnw mx-0">
              <Col xs={2} md={2} className="align-self-center px-0">
                <Button onClick={() => navigate(-1)} className="back-btn">
                <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
                 </svg>
              </Button>
              </Col>
              <Col xs={8} md={8} className="align-self-center text-center"><h3>Forgot Password</h3></Col>
              <Col xs={2} md={2} className="align-self-center"><p></p></Col>
            </Row>
        {/* ========== Navbar End ========== */}
             <Container>
                <Row className="genscrn genscrnmrcnt">
        {/* ========== Forget Content Start ========== */}
                <Col md={12} lg={12} xl={12} xxl={12} className="toptxtcnt px-0">
                <div className="maincntsrcn">
                  <h5>Enter your email address that you used to register your account, so we can send you a link to reset your password.</h5>
                   <Form>
                      <Form.Group className="mb-3">
                        <Form.Label>Email Address<span>*</span></Form.Label>
                        <Form.Control type="text" placeholder="Ex. Johndoe@gmail.com" />
                      </Form.Group>
                      <div className="mb-3 btn-dv"><NavLink to="/mer-otp"><Button type="button" className="btn">Send Link</Button></NavLink></div>
                    </Form>
                    </div>
                </Col>
         {/* ========== Forget Content End ========== */}
                </Row>
             </Container>
          </section>
        {/* ========== Merchant Forget Page End ========== */}
        </>
    )
}
export default MerForgot;