import React, { useEffect } from 'react';
import { Row, Col, Container, Form, Tab, Nav } from 'react-bootstrap';
import './SalDash.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";

import {Chart as ChartJS,CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Filler,Legend,} 
from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Filler,Legend);
export const options = {responsive: true,plugins: {legend: {position: 'top',display: false,},title: {display: false,text: '',},},};
const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
export const data = {labels,datasets: [{fill: true,label: '',data: [12, 19, 3, 5,15,20,25],borderColor: 'rgba(202, 124, 0, 1)',backgroundColor: 'rgb(246 197 120)',borderDash: [10,5],},],};
//import $ from 'jquery';
const SalDash = () => {

useEffect(() => {
      document.title = `Sales Dashboard | Blue Faucet`;
      document.getElementById('bodywrap').className='Homepgmain ylwthem sdsbord';      
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });         
},[])  
    return (
        <>
        {/* ========== Sales Dashboard Page Start ========== */}
            <section className="gen-wrap">
        {/* ========== Navbar Start ========== */}
              <Navbar headerText={'Your Dashboard !'}/>
        {/* ========== Navbar End ========== */}
               <Container>
                  <Row className="mx-0 mianhmdwncnt">
                   <Col className="mnhmcnt">
        {/* ========== Sales Dashboard Content Start ========== */}
                    <Col md={12} xs={12} className="mdsbrdchrtdv mb-3">
                      <Row className="mx-0">
                        <Col md={8} xs={8} className="mb-3">
                          <Row className="dwngrftptxt">
                            <Col md={3} xs={3} className="text-center"><img src="assets/images/sales/susr-icn.png" className="img-fluid" alt="Graph Img"/></Col>
                            <Col md={9} xs={9}>
                              <p>Total Commissions</p>
                              <h3>1,025</h3>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={4} xs={4}>
                          <Form.Select aria-label="Default select example">
                            <option>Day</option>
                            <option defaultValue="1">Week</option>
                            <option defaultValue="2">Month</option>
                            <option defaultValue="3">Year</option>
                          </Form.Select>
                        </Col>
                      </Row>
                       <Line options={options} data={data} />
                    </Col>
        {/* ========== Sales Dashboard Content End ========== */}
                    <Col md={12} xs={12} className="tbscntylw">
        {/* ========== Sales Dashboard Tab Content Start ========== */}
                      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                          <Col md={12} sm={12}>
                            <Nav variant="pills" className="">
                            <Row>
                             <Col md={6} xs={6}>
                              <Nav.Item>
                                <Nav.Link eventKey="first">Merchant List</Nav.Link>
                              </Nav.Item>
                             </Col>
                             <Col md={6} xs={6}>
                              <Nav.Item>
                                <Nav.Link href="sales-commission" eventKey={void(0)}>Payment History</Nav.Link>
                              </Nav.Item>
                             </Col>
                            </Row>
                            </Nav>
                          </Col>
                          <Col md={12} xs={12} className="srcinpt">
                            <div className="constrain hide-inpt">
                              <input className="input-elevated" type="text" placeholder="Search here.."/>
                            </div>
                          </Col>
                          <Col md={12} sm={12}>
        {/* ========== Sales Dashboard Merchant List Content Start ========== */}
                            <Tab.Content>
                              <Tab.Pane eventKey="first">
                                <Col md={12} xs={12} className="mcusbx mt-3">
                                  <Row className="mx-0">
                                    <Col md={2} xs={2} className="align-self-center px-1"><img src= {'assets/images/employee/usrrund-img.png'} className="img-fluid" alt="Mcust Img"/></Col>
                                    <Col md={5} xs={5} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                                      <h3>Merchant Name</h3>
                                    </Col>
                                    <Col md={3} xs={4} className="align-self-center cnt-txt">
                                       <p>Status <span className="ylw"><img src= {'assets/images/employee/svg/dots.svg'} className="img-fluid" alt="Mcust Img"/></span></p>
                                    </Col>
                                    <Col md={2} xs={1} className="align-self-center text-end">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                        <path d="M0.603935 9.79545C0.686317 9.93182 0.816994 10 0.995963 10C1.07835 10 1.18061 9.98295 1.22323 9.96023C1.31697 9.90909 5.92472 5.29545 5.97017 5.20739C6.00994 5.12784 6.00994 4.87216 5.97017 4.79261C5.92472 4.70455 1.31697 0.0909092 1.22323 0.0397722C1.18061 0.0170452 1.07834 2.15132e-07 0.995962 2.18733e-07C0.720407 2.30778e-07 0.547119 0.173295 0.547119 0.448863C0.547119 0.53125 0.561323 0.627841 0.578367 0.661932C0.595412 0.696022 1.5698 1.6875 2.74588 2.8608L4.87931 5L2.74588 7.13636C1.5698 8.3125 0.595413 9.30398 0.578368 9.33807C0.527234 9.44034 0.541438 9.69034 0.603935 9.79545Z" fill="#CA7C00"/>
                                      </svg>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md={12} xs={12} className="mcusbx mt-3">
                                  <Row className="mx-0">
                                    <Col md={2} xs={2} className="align-self-center px-1"><img src= {'assets/images/employee/usrrund-img.png'} className="img-fluid" alt="Mcust Img"/></Col>
                                    <Col md={5} xs={5} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                                      <h3>Merchant Name</h3>
                                    </Col>
                                    <Col md={3} xs={4} className="align-self-center cnt-txt">
                                       <p>Status <span className="grn"><img src= {'assets/images/employee/svg/dots.svg'} className="img-fluid" alt="Mcust Img"/></span></p>
                                    </Col>
                                    <Col md={2} xs={1} className="align-self-center text-end">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                        <path d="M0.603935 9.79545C0.686317 9.93182 0.816994 10 0.995963 10C1.07835 10 1.18061 9.98295 1.22323 9.96023C1.31697 9.90909 5.92472 5.29545 5.97017 5.20739C6.00994 5.12784 6.00994 4.87216 5.97017 4.79261C5.92472 4.70455 1.31697 0.0909092 1.22323 0.0397722C1.18061 0.0170452 1.07834 2.15132e-07 0.995962 2.18733e-07C0.720407 2.30778e-07 0.547119 0.173295 0.547119 0.448863C0.547119 0.53125 0.561323 0.627841 0.578367 0.661932C0.595412 0.696022 1.5698 1.6875 2.74588 2.8608L4.87931 5L2.74588 7.13636C1.5698 8.3125 0.595413 9.30398 0.578368 9.33807C0.527234 9.44034 0.541438 9.69034 0.603935 9.79545Z" fill="#CA7C00"/>
                                      </svg>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md={12} xs={12} className="mcusbx mt-3">
                                  <Row className="mx-0">
                                    <Col md={2} xs={2} className="align-self-center px-1"><img src= {'assets/images/employee/usrrund-img.png'} className="img-fluid" alt="Mcust Img"/></Col>
                                    <Col md={5} xs={5} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                                      <h3>Merchant Name</h3>
                                    </Col>
                                    <Col md={3} xs={4} className="align-self-center cnt-txt">
                                       <p>Status <span className="red"><img src= {'assets/images/employee/svg/dots.svg'} className="img-fluid" alt="Mcust Img"/></span></p>
                                    </Col>
                                    <Col md={2} xs={1} className="align-self-center text-end">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                        <path d="M0.603935 9.79545C0.686317 9.93182 0.816994 10 0.995963 10C1.07835 10 1.18061 9.98295 1.22323 9.96023C1.31697 9.90909 5.92472 5.29545 5.97017 5.20739C6.00994 5.12784 6.00994 4.87216 5.97017 4.79261C5.92472 4.70455 1.31697 0.0909092 1.22323 0.0397722C1.18061 0.0170452 1.07834 2.15132e-07 0.995962 2.18733e-07C0.720407 2.30778e-07 0.547119 0.173295 0.547119 0.448863C0.547119 0.53125 0.561323 0.627841 0.578367 0.661932C0.595412 0.696022 1.5698 1.6875 2.74588 2.8608L4.87931 5L2.74588 7.13636C1.5698 8.3125 0.595413 9.30398 0.578368 9.33807C0.527234 9.44034 0.541438 9.69034 0.603935 9.79545Z" fill="#CA7C00"/>
                                      </svg>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md={12} xs={12} className="mcusbx mt-3">
                                  <Row className="mx-0">
                                    <Col md={2} xs={2} className="align-self-center px-1"><img src= {'assets/images/employee/usrrund-img.png'} className="img-fluid" alt="Mcust Img"/></Col>
                                    <Col md={5} xs={5} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                                      <h3>Merchant Name</h3>
                                    </Col>
                                    <Col md={3} xs={4} className="align-self-center cnt-txt">
                                       <p>Status <span className="ylw"><img src= {'assets/images/employee/svg/dots.svg'} className="img-fluid" alt="Mcust Img"/></span></p>
                                    </Col>
                                    <Col md={2} xs={1} className="align-self-center text-end">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                        <path d="M0.603935 9.79545C0.686317 9.93182 0.816994 10 0.995963 10C1.07835 10 1.18061 9.98295 1.22323 9.96023C1.31697 9.90909 5.92472 5.29545 5.97017 5.20739C6.00994 5.12784 6.00994 4.87216 5.97017 4.79261C5.92472 4.70455 1.31697 0.0909092 1.22323 0.0397722C1.18061 0.0170452 1.07834 2.15132e-07 0.995962 2.18733e-07C0.720407 2.30778e-07 0.547119 0.173295 0.547119 0.448863C0.547119 0.53125 0.561323 0.627841 0.578367 0.661932C0.595412 0.696022 1.5698 1.6875 2.74588 2.8608L4.87931 5L2.74588 7.13636C1.5698 8.3125 0.595413 9.30398 0.578368 9.33807C0.527234 9.44034 0.541438 9.69034 0.603935 9.79545Z" fill="#CA7C00"/>
                                      </svg>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md={12} xs={12} className="mcusbx mt-3">
                                  <Row className="mx-0">
                                    <Col md={2} xs={2} className="align-self-center px-1"><img src= {'assets/images/employee/usrrund-img.png'} className="img-fluid" alt="Mcust Img"/></Col>
                                    <Col md={5} xs={5} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                                      <h3>Merchant Name</h3>
                                    </Col>
                                    <Col md={3} xs={4} className="align-self-center cnt-txt">
                                       <p>Status <span className="grn"><img src= {'assets/images/employee/svg/dots.svg'} className="img-fluid" alt="Mcust Img"/></span></p>
                                    </Col>
                                    <Col md={2} xs={1} className="align-self-center text-end">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                        <path d="M0.603935 9.79545C0.686317 9.93182 0.816994 10 0.995963 10C1.07835 10 1.18061 9.98295 1.22323 9.96023C1.31697 9.90909 5.92472 5.29545 5.97017 5.20739C6.00994 5.12784 6.00994 4.87216 5.97017 4.79261C5.92472 4.70455 1.31697 0.0909092 1.22323 0.0397722C1.18061 0.0170452 1.07834 2.15132e-07 0.995962 2.18733e-07C0.720407 2.30778e-07 0.547119 0.173295 0.547119 0.448863C0.547119 0.53125 0.561323 0.627841 0.578367 0.661932C0.595412 0.696022 1.5698 1.6875 2.74588 2.8608L4.87931 5L2.74588 7.13636C1.5698 8.3125 0.595413 9.30398 0.578368 9.33807C0.527234 9.44034 0.541438 9.69034 0.603935 9.79545Z" fill="#CA7C00"/>
                                      </svg>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md={12} xs={12} className="mcusbx mt-3">
                                  <Row className="mx-0">
                                    <Col md={2} xs={2} className="align-self-center px-1"><img src= {'assets/images/employee/usrrund-img.png'} className="img-fluid" alt="Mcust Img"/></Col>
                                    <Col md={5} xs={5} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                                      <h3>Merchant Name</h3>
                                    </Col>
                                    <Col md={3} xs={4} className="align-self-center cnt-txt">
                                       <p>Status <span className="red"><img src= {'assets/images/employee/svg/dots.svg'} className="img-fluid" alt="Mcust Img"/></span></p>
                                    </Col>
                                    <Col md={2} xs={1} className="align-self-center text-end">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                        <path d="M0.603935 9.79545C0.686317 9.93182 0.816994 10 0.995963 10C1.07835 10 1.18061 9.98295 1.22323 9.96023C1.31697 9.90909 5.92472 5.29545 5.97017 5.20739C6.00994 5.12784 6.00994 4.87216 5.97017 4.79261C5.92472 4.70455 1.31697 0.0909092 1.22323 0.0397722C1.18061 0.0170452 1.07834 2.15132e-07 0.995962 2.18733e-07C0.720407 2.30778e-07 0.547119 0.173295 0.547119 0.448863C0.547119 0.53125 0.561323 0.627841 0.578367 0.661932C0.595412 0.696022 1.5698 1.6875 2.74588 2.8608L4.87931 5L2.74588 7.13636C1.5698 8.3125 0.595413 9.30398 0.578368 9.33807C0.527234 9.44034 0.541438 9.69034 0.603935 9.79545Z" fill="#CA7C00"/>
                                      </svg>
                                    </Col>
                                  </Row>
                                </Col>
                              </Tab.Pane>
                              <Tab.Pane eventKey="second">
                                <Col md={12} xs={12} className="mcusbx mt-3">
                                  <Row className="mx-0">
                                    <Col md={2} xs={2} className="align-self-center px-1"><img src= {'assets/images/employee/usrrund-img.png'} className="img-fluid" alt="Mcust Img"/></Col>
                                    <Col md={5} xs={5} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                                      <h3>Merchant Name</h3>
                                    </Col>
                                    <Col md={3} xs={4} className="align-self-center cnt-txt">
                                       <p>Status <span className="ylw"><img src= {'assets/images/employee/svg/dots.svg'} className="img-fluid" alt="Mcust Img"/></span></p>
                                    </Col>
                                    <Col md={2} xs={1} className="align-self-center text-end">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                        <path d="M0.603935 9.79545C0.686317 9.93182 0.816994 10 0.995963 10C1.07835 10 1.18061 9.98295 1.22323 9.96023C1.31697 9.90909 5.92472 5.29545 5.97017 5.20739C6.00994 5.12784 6.00994 4.87216 5.97017 4.79261C5.92472 4.70455 1.31697 0.0909092 1.22323 0.0397722C1.18061 0.0170452 1.07834 2.15132e-07 0.995962 2.18733e-07C0.720407 2.30778e-07 0.547119 0.173295 0.547119 0.448863C0.547119 0.53125 0.561323 0.627841 0.578367 0.661932C0.595412 0.696022 1.5698 1.6875 2.74588 2.8608L4.87931 5L2.74588 7.13636C1.5698 8.3125 0.595413 9.30398 0.578368 9.33807C0.527234 9.44034 0.541438 9.69034 0.603935 9.79545Z" fill="#CA7C00"/>
                                      </svg>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md={12} xs={12} className="mcusbx mt-3">
                                  <Row className="mx-0">
                                    <Col md={2} xs={2} className="align-self-center px-1"><img src= {'assets/images/employee/usrrund-img.png'} className="img-fluid" alt="Mcust Img"/></Col>
                                    <Col md={5} xs={5} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                                      <h3>Merchant Name</h3>
                                    </Col>
                                    <Col md={3} xs={4} className="align-self-center cnt-txt">
                                       <p>Status <span className="grn"><img src= {'assets/images/employee/svg/dots.svg'} className="img-fluid" alt="Mcust Img"/></span></p>
                                    </Col>
                                    <Col md={2} xs={1} className="align-self-center text-end">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                        <path d="M0.603935 9.79545C0.686317 9.93182 0.816994 10 0.995963 10C1.07835 10 1.18061 9.98295 1.22323 9.96023C1.31697 9.90909 5.92472 5.29545 5.97017 5.20739C6.00994 5.12784 6.00994 4.87216 5.97017 4.79261C5.92472 4.70455 1.31697 0.0909092 1.22323 0.0397722C1.18061 0.0170452 1.07834 2.15132e-07 0.995962 2.18733e-07C0.720407 2.30778e-07 0.547119 0.173295 0.547119 0.448863C0.547119 0.53125 0.561323 0.627841 0.578367 0.661932C0.595412 0.696022 1.5698 1.6875 2.74588 2.8608L4.87931 5L2.74588 7.13636C1.5698 8.3125 0.595413 9.30398 0.578368 9.33807C0.527234 9.44034 0.541438 9.69034 0.603935 9.79545Z" fill="#CA7C00"/>
                                      </svg>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md={12} xs={12} className="mcusbx mt-3">
                                  <Row className="mx-0">
                                    <Col md={2} xs={2} className="align-self-center px-1"><img src= {'assets/images/employee/usrrund-img.png'} className="img-fluid" alt="Mcust Img"/></Col>
                                    <Col md={5} xs={5} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                                      <h3>Merchant Name</h3>
                                    </Col>
                                    <Col md={3} xs={4} className="align-self-center cnt-txt">
                                       <p>Status <span className="red"><img src= {'assets/images/employee/svg/dots.svg'} className="img-fluid" alt="Mcust Img"/></span></p>
                                    </Col>
                                    <Col md={2} xs={1} className="align-self-center text-end">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                        <path d="M0.603935 9.79545C0.686317 9.93182 0.816994 10 0.995963 10C1.07835 10 1.18061 9.98295 1.22323 9.96023C1.31697 9.90909 5.92472 5.29545 5.97017 5.20739C6.00994 5.12784 6.00994 4.87216 5.97017 4.79261C5.92472 4.70455 1.31697 0.0909092 1.22323 0.0397722C1.18061 0.0170452 1.07834 2.15132e-07 0.995962 2.18733e-07C0.720407 2.30778e-07 0.547119 0.173295 0.547119 0.448863C0.547119 0.53125 0.561323 0.627841 0.578367 0.661932C0.595412 0.696022 1.5698 1.6875 2.74588 2.8608L4.87931 5L2.74588 7.13636C1.5698 8.3125 0.595413 9.30398 0.578368 9.33807C0.527234 9.44034 0.541438 9.69034 0.603935 9.79545Z" fill="#CA7C00"/>
                                      </svg>
                                    </Col>
                                  </Row>
                                </Col>
                              </Tab.Pane>
                            </Tab.Content>
        {/* ========== Sales Dashboard Merchant List Content End ========== */}
                          </Col>
                      </Tab.Container>
        {/* ========== Sales Dashboard Tab Content End ========== */}
                    </Col>
                     </Col>
                  </Row>
               </Container>
            </section>
        {/* ========== Footer Start ========== */}
            <Footer/>
        {/* ========== Footer End ========== */}
        {/* ========== Sales Dashboard Page End ========== */}
        </>
    )
}

export default SalDash;