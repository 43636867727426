import React, { useEffect } from 'react';
import { Row, Col, Container, Accordion } from 'react-bootstrap';
import './MerHelp.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
// import $ from 'jquery';

const MerHelp = () => {
useEffect(() => {
      document.title = `Merchant Help | Blue Faucet`;
      document.getElementById('bodywrap').className='mhelppgmain grnbg grenicn';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });       
},[])  
    return (
      
        <>
        {/* ========== Merchant Help Page Start ========== */}
         <section className="gen-wrap">
        {/* ========== Navbar Start ========== */}
         <Navbar headerText={'Help'}/>
        {/* ========== Navbar End ========== */}
         <Container>
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
               <Col md={12} xs={12} className="py-3">
               <Row className="mx-0">
        {/* ========== Help Content Start ========== */}
                  <Col md={12} xs={12} className="px-0 usrhlp">
                  <Accordion defaultActiveKey="1">
                     <Accordion.Item eventKey="0">
                        <Accordion.Header>Lorem Ipsum is simply dummy text of...</Accordion.Header>
                        <Accordion.Body>
                           Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting...
                        </Accordion.Body>
                     </Accordion.Item>
                     <Accordion.Item eventKey="1">
                        <Accordion.Header>Lorem Ipsum is simply dummy text of...</Accordion.Header>
                        <Accordion.Body>
                           Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting...
                        </Accordion.Body>
                     </Accordion.Item>
                     <Accordion.Item eventKey="2">
                        <Accordion.Header>Lorem Ipsum is simply dummy text of...</Accordion.Header>
                        <Accordion.Body>
                           Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting...
                        </Accordion.Body>
                     </Accordion.Item>
                     <Accordion.Item eventKey="3">
                        <Accordion.Header>Lorem Ipsum is simply dummy text of...</Accordion.Header>
                        <Accordion.Body>
                           Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting...
                        </Accordion.Body>
                     </Accordion.Item>
                     <Accordion.Item eventKey="4">
                        <Accordion.Header>Lorem Ipsum is simply dummy text of...</Accordion.Header>
                        <Accordion.Body>
                           Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting...
                        </Accordion.Body>
                     </Accordion.Item>
                     <Accordion.Item eventKey="5">
                        <Accordion.Header>Lorem Ipsum is simply dummy text of...</Accordion.Header>
                        <Accordion.Body>
                           Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting...
                        </Accordion.Body>
                     </Accordion.Item>
                     <Accordion.Item eventKey="6">
                        <Accordion.Header>Lorem Ipsum is simply dummy text of...</Accordion.Header>
                        <Accordion.Body>
                           Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting...
                        </Accordion.Body>
                     </Accordion.Item>
                     <Accordion.Item eventKey="7">
                        <Accordion.Header>Lorem Ipsum is simply dummy text of...</Accordion.Header>
                        <Accordion.Body>
                           Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting...
                        </Accordion.Body>
                     </Accordion.Item>
                     <Accordion.Item eventKey="8">
                        <Accordion.Header>Lorem Ipsum is simply dummy text of...</Accordion.Header>
                        <Accordion.Body>
                           Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting...
                        </Accordion.Body>
                     </Accordion.Item>
                     <Accordion.Item eventKey="9">
                        <Accordion.Header>Lorem Ipsum is simply dummy text of...</Accordion.Header>
                        <Accordion.Body>
                           Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting...
                        </Accordion.Body>
                     </Accordion.Item>
                  </Accordion>
                  </Col>
        {/* ========== Help Content End ========== */}
               </Row>
               </Col>
               </Col>
            </Row>
         </Container>
        </section>
        {/* ========== Merchant Help Page End ========== */}
        </>
    )
}
export default MerHelp;