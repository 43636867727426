import React, { useEffect } from 'react';
import { Row, Col, Container} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './MerPrivacy.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';

const MerPrivacy = () => {
const navigate = useNavigate();
useEffect(() => {
      document.title = `Merchant Privacy Policy | Blue Faucet`;
      document.getElementById('bodywrap').className='mprvcypgmain grenicn grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });       
function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function(e) {
            $('#imagePreview').attr("src",e.target.result);
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
        }
        reader.readAsDataURL(input.files[0]);
    }
}
$("#imageUpload").change(function() {
    readURL(this);
});

},[])  
    return (
      
        <>
        {/* ========== Merchant Privacy Policy Page Start ========== */}
          <section className="gen-main">
        {/* ========== Navbar Start ========== */}
              <Row className="maincnttpnw mx-0">
                <Col xs={2} md={2} className="align-self-center px-0">
                <button onClick={() => navigate(-1)} className="back-btn">
                  <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
                   </svg>
                </button>
                </Col>
                <Col xs={8} md={8} className="align-self-center text-center"><h3>Privacy Policy</h3></Col>
                <Col xs={2} md={2} className="align-self-center"><p></p></Col>
              </Row>
        {/* ========== Navbar End ========== */}
               <Container>
                <Row md={12} className="genscrnmrcnt genscrn">
                  <Col>
        {/* ========== Privacy Policy Content Start ========== */}
                     <Row md={12} className="maincntsrcn">
                      <Col md={12} xs={12} className="px-0 mb-4">
                        <img src= {'assets/images/merchant/svg/m-logo.svg'} className="img-fluid" alt="Logo"/>
                      </Col>
                      <Col md={12} xs={12} className="px-0 maincnt-prvcyplcy">
                        <h3>Privacy Policy for BlueFaucet - California Privacy Act</h3>
                        <h3>1. Introduction</h3>
                        <ul>
                          <li>This Privacy Policy explains how BlueFaucet Inc. handles the personal information that residents of California and the United States provide to us on <NavLink to="/home"> www.bluefaucet.com</NavLink> and/or its app (BlueFaucet). This Privacy Policy applies to all residents of California and the United States who use BlueFaucet's services, and it governs the collection, use, and protection of their personal information.</li>
                          <li>In addition to the general provisions of this Privacy Policy, this clause specifically addresses the relationship between BlueFaucet, merchants, and clients with regard to the handling of clients' personal information. BlueFaucet works with merchants who may collect and maintain clients' personal information when transactions or inquiries are made through BlueFaucet's platform.</li>
                          <li>Both merchants and clients using BlueFaucet's services agree to be bound by the terms of this Privacy Policy, including the provisions outlined in this section pertaining to the handling of clients' personal information by merchants. It is important for merchants to comply with all applicable laws and regulations regarding the collection, use, and protection of clients' personal information. Merchants are responsible for handling clients' personal information in accordance with their own privacy policies and applicable laws.</li>
                          <li>By using BlueFaucet's platform, merchants agree to indemnify and hold harmless BlueFaucet Inc. from any claims, damages, or liabilities arising from the merchants' collection, use, or disclosure of clients' personal information. Clients are encouraged to review the privacy policies of the merchants they interact with through BlueFaucet's platform to understand how their personal information will be used and protected.</li>
                          <li>It is important for both merchants and clients to understand and adhere to this Privacy Policy, as well as the Terms of Use, to ensure the proper handling and protection of personal information within the context of BlueFaucet's services.</li>
                        </ul>
                        <h3>2. Definition of "Personal Information"</h3>
                        <ul>
                          <li>"Personal information" refers to any information that either directly identifies you or could reasonably be used, when combined with other data, to identify you. This information is submitted to and/or collected by BlueFaucet and merchants, and it is maintained by BlueFaucet Inc. and merchants in a format that allows accessibility. Examples of personal information include but are not limited to, your name, postal address, and telephone number. </li>
                        </ul>
                        <h3>3. Information Collection</h3>
                        <p className="lft-spc">BlueFaucet and merchants collect information in the following ways:</p>
                        <ul>
                          <li><span>A.</span>  Personal Information You Submit: BlueFaucet collects personal information that you voluntarily enter into data fields on BlueFaucet. For instance, when you engage in activities such as joining a merchants' membership, contacting BlueFaucet customer service, or responding to merchants' surveys, you may submit your name, postal address, email address, and/or other relevant details.</li>
                          <li>To safeguard your privacy, it is important not to provide any information that is not specifically requested. Only share the information that is necessary for the intended purpose, and refrain from disclosing any additional personal details beyond what is asked for. This helps ensure that your privacy is protected and that you have control over the information you share with BlueFaucet and its merchants.</li>
                          <li><span>B.</span>  Passive Collection of Unidentifiable Information: BlueFaucet employs various passive information collection technologies, such as Internet tags, web beacons, and databases, to gather data while you use its services, even if you do not actively provide that information. The collected data is unidentifiable and includes details about your interactions with BlueFaucet, such as the products you purchased and payment receipts. This information is automatically transmitted to BlueFaucet's database.</li>
                          <li>These passive information collection technologies enhance your experience with BlueFaucet by allowing the platform to offer improved services, such as personalized coupons, pre-order schedules, refund processes, and loyalty programs tailored to your consumer preferences. Moreover, these technologies assist in compiling statistics, analyzing trends, and facilitating the overall administration and enhancement of BlueFaucet.</li>
                          <li>It is important to understand that while certain features of BlueFaucet may rely on these passive information collection technologies to function effectively, the data collected through these technologies alone cannot personally identify you without additional identifiable information. Consequently, your privacy is protected, and BlueFaucet ensures that the collected data is used in a manner that respects your anonymity unless you voluntarily provide additional identifying information.</li>
                          <li>Regarding clients' information with merchants, BlueFaucet collaborates with merchants who may collect clients' information. When clients engage in transactions or make inquiries through BlueFaucet's platform, merchants may receive information from clients. It is crucial to note that the handling of clients' personal information by merchants is governed by their individual privacy policies, and BlueFaucet is not accountable for the privacy practices of these merchants.</li>
                        </ul>
                        <h3>4. Use of Personal Information</h3>
                        <ul>
                          <li>BlueFaucet will utilize the personal information you provided to address your inquiries and offer efficient customer service. Additionally, once you enter personal information into a form or data field on BlueFaucet, certain identifying technologies may be used by BlueFaucet to remember your personal preferences, including the sections of the store you frequently visit, and, if you choose to provide it, your user ID.</li>
                          <li>Furthermore, we may use this personal information for other business purposes, such as providing you with opportunities to receive notifications about stores' products or services, inviting you to participate in surveys regarding the products, or informing you about special promotions. Rest assured that your personal information will be handled responsibly and in accordance with the privacy policy outlined here.</li>
                        </ul>
                        <h3>5. Choices and Control</h3>
                        <ul>
                          <li>You have the following choices regarding how BlueFaucet collects and uses personal information about you:</li>
                          <li>You may limit the amount and type of personal information that BlueFaucet receives about you by choosing not to enter any personal information into forms or data fields on BlueFaucet. However, some services may only be provided to you if you provide us with appropriate personal information.</li>
                          <li>You may be provided with preference questions or preference boxes allowing you to indicate that you do not want BlueFaucet to use tracking technologies, such as cookies, to "remember" your personal information, such as user IDs or mailing addresses, on return visits. Some Internet browsers allow you to limit or disable the use of tracking technologies that collect unidentifiable information.</li>
                          <li>If you are a merchant partnering with BlueFaucet, you agree to comply with all applicable laws and regulations concerning the collection, use, and protection of clients' personal information. You further agree to use clients' personal information only for the purposes explicitly permitted by the applicable laws and in accordance with your own privacy policy. BlueFaucet may audit your compliance with these requirements to ensure the privacy and security of clients' personal information.</li>
                          <li>By using BlueFaucet's platform, merchants agree to indemnify and hold harmless BlueFaucet Inc. from any claims, damages, or liabilities arising from the merchants' collection, use, or disclosure of clients' personal information.</li>
                        </ul>
                        <h3>6. Disclosure of Personal Information</h3>
                        <ul>
                          <li>As a general rule, BlueFaucet does not share your personal information with third parties, unless it is necessary to conduct a joint program or to carry out a program or activity through a contractor. Where required by law, BlueFaucet will not disclose your personal information to third parties for those third parties' marketing purposes, unless you have been provided with an opportunity to opt into or opt out of this disclosure. If BlueFaucet provides your personal information to individuals and entities that assist us with our business activities, it is BlueFaucet's practice to require those individuals and entities to keep your personal information confidential and to use your personal information only to perform functions for BlueFaucet. BlueFaucet reserves the right to disclose your personal information to respond to authorized information requests from government authorities, to address national security situations, or when otherwise required by law.</li>
                        </ul>
                        <h3>7. Security of Personal Information</h3>
                        <ul>
                          <li>It is BlueFaucet's practice to secure the database that collects personal information; however, the confidentiality of personal information transmitted over the Internet cannot be guaranteed. We urge you to exercise caution when transmitting personal information over the Internet, especially personal information related to your health. BlueFaucet cannot guarantee that unauthorized third parties will not gain access to your personal information; therefore, when submitting personal information to BlueFaucet, you must weigh both the benefits and the risks.</li>
                          <li>BlueFaucet takes reasonable steps to ensure that the merchants it partners with handle clients' personal information in compliance with applicable laws and regulations, and that they maintain appropriate security measures to protect this information. However, BlueFaucet does not control or have access to the personal information collected and maintained by merchants. Therefore, clients are encouraged to review the merchants' privacy policies to understand how their personal information will be used, stored, and protected.</li>
                        </ul>
                        <h3>8. Privacy of Children</h3>
                        <ul>
                          <li>BlueFaucet does not knowingly collect or use any personal information from children (we define "children" as minors younger than 13) on BlueFaucet. We do not knowingly allow children to communicate with us, or use any of our online services. If you are a parent and become aware that your child has provided us with information, please contact us using one of the methods specified below, and we will work with you to address this issue.</li>
                        </ul>
                        <h3>9. Access and Correction</h3>
                        <ul>
                          <li>You may request to remove your personal information from our current customer records or to amend your personal information. Please notify us of your wishes by contacting us using one of the ways specified below. For our record-keeping purposes, we will retain certain personal information you submit in conjunction with commercial transactions.</li>
                        </ul>
                        <h3>10. Contact Information</h3>
                        <ul>
                          <li>If you have questions about the use, amendment, or deletion of personal information that you have provided to us, or if you would like to opt-out of future communications from a BlueFaucet business or a particular program, please contact us by clicking on the "Contact Us" link on the Web site or App you are visiting.</li>
                        </ul>
                        <h3>11. Updates to this Privacy Policy</h3>
                        <ul>
                          <li>BlueFaucet reserves the right to amend this Privacy Policy without prior notice to reflect technological advancements, legal and regulatory changes, and good business practices. If BlueFaucet changes its privacy practices, a new Privacy Policy will reflect those changes and the effective date of the revised Privacy Policy will be set forth in this paragraph. This Privacy Policy was last updated on Nov 1, 2023, and is effective as of that date.</li>
                        </ul>
                      </Col>
                     </Row>
        {/* ========== Privacy Policy Content End ========== */}
                  </Col>
                </Row>
               </Container>
          </section>
        {/* ========== Merchant Privacy Policy Page End ========== */}
        </>
    )
}
export default MerPrivacy;