import React, { useEffect } from 'react';
import { Row, Col, Container, Button  } from 'react-bootstrap';
import './SalAbout.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';

const SalAbout = () => {
const navigate = useNavigate();
useEffect(() => {
      document.title = `Sales About | Blue Faucet`;
      document.getElementById('bodywrap').className='mabutpgmain ylwthem';

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 

function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function(e) {
            $('#imagePreview').attr("src",e.target.result);
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
        }
        reader.readAsDataURL(input.files[0]);
    }
}
$("#imageUpload").change(function() {
    readURL(this);
});
},[])  
    return (
        <>
        {/* ========== About Page Start ========== */}
          <section className="gen-main">
        {/* ========== Navbar Start ========== */}
              <Row className="maincntylwtm mx-0">
                <Col xs={2} md={2} className="align-self-center px-0">
                <Button onClick={() => navigate(-1)} className="back-btn">
                  <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
                   </svg>
                </Button>
                </Col>
                <Col xs={8} md={8} className="align-self-center text-center"><h3>About BlueFaucet</h3></Col>
                <Col xs={2} md={2} className="align-self-center"><p></p></Col>
              </Row>
        {/* ========== Navbar End ========== */}
               <Container>
                <Row md={12} className="genscrnylwtm">
                  <Col>
                     <Row md={12} className="maincntsrnylwtm">
        {/* ========== About Content Start ========== */}
                      <Col md={12} xs={12} className="px-0 mb-4">
                        <img src= {'assets/images/sales/svg/ylogo.svg'} className="img-fluid" alt="Sld Img"/>
                      </Col>
                      <Col md={12} xs={12} className="px-0 maincnt-abut">
                        <h3>BlueFaucet was founded in Oct 2018</h3>
                        <p>BlueFaucet aims to be a P2P platform for a wide variety of services. After talking to many small business owners and individuals, we found that there are limited resources and services that can help them with connecting with their customers, scheduling appointments and other tasks needed for their businesses.</p>
                        <h3>Challenges:</h3>
                        <ul>
                          <li>Limited staff to connect to customers and give reminders to return for appointments in regular intervals </li>
                          <li>Limited ability to care for customers </li>
                          <li>Limited budget and means for advertisement </li>
                          <li>High competition in the local market </li>
                          <li>Limited manpower </li>
                          <li>Lack of technological knowledge</li>
                        </ul>
                        <p>BlueFaucet provides easy to use features that allow users to manage their service business. Such features include overseeing appointments, customers, orders, marketing, and more. Compared to traditional CRM software, BlueFaucet focuses more on ease of usability. However, this ease of use does not limit its capabilities. Our AI engine is constantly learning to provide information to identify potential customers and better services to existing customers.</p>
                        <h3>Our Spirit:</h3>
                        <p>"Together, we serve the world. We want to help build the best experience between merchants and their customers."</p>
                        <h3>Case Study:</h3>
                        <ul>
                          <li>John is an animal lover; he enjoys walking dogs and playing with cats. In his spare time, he uses BlueFaucet to offer dog-walking services and pet watching/hotel services to his neighbors as a side job.</li>
                          <li>Mary has a beauty salon. Some customers come on a regular basis, but some need to be reminded. BlueFaucet does the reminding and enables the customer to book the appointment without calling or checking with Mary. Mary can also provide coupons, gift cards and discount memberships for her customers. After each interaction, she and her customers both can record their experiences and everyone can have a better experience.</li>
                        </ul>
                        <h3>Goals & Steps:</h3>
                        <p>First, BlueFaucet will target existing small businesses and help them manage their existing customer base by providing online appointment function, management of customer records, marketing, etc.</p>
                        <p>Second, BlueFaucet will appeal to individuals that have time and would like to provide services. Implementing a ‘Contacts’ function will allow users to officially make appointments and transactions between friends and family.</p>
                        <p>By growing our member base in both directions, BlueFaucet will become an ecosystem that allows for comfortable and efficient exchanges between customers and merchant members.</p>
                      </Col>
        {/* ========== About Content End ========== */}
                     </Row>
                  </Col>
            
                </Row>
               </Container>
          </section>
          {/* ========== About Page End ========== */}
        </>
    )
}
export default SalAbout;