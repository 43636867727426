import React, { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './EmpCommi.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";

const EmpCommi = () => {
useEffect(() => {
      document.title = `Employee Commission | Blue Faucet`;
      document.getElementById('bodywrap').className='ecmisionpgmain grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });       
},[])  
    return (
        <>
        {/* ========== Employee Commission Page Start ========== */}
          <section className="gen-wrap">
        {/* ========== Navbar Start ========== */}
              <Navbar headerText={'Commissions'}/>
        {/* ========== Navbar End ========== */}
               <Container>
                  <Row className="mx-0 mianhmdwncnt">
                   <Col className="mnhmcnt">
        {/* ========== Employee Commission Content Start ========== */}
                     <Col md={12} xs={12} className="">
                      <Row className="mx-0 maintpcmsnbox">
                       <Col md={2} xs={2} className="px-1"><img src= {'assets/images/employee/prfl-icn.png'} className="img-fluid" alt="Mcust Img"/></Col>
                          <Col md={8} xs={8} className="mcmsn-txtcnt ps-md-0 ">
                          <p>Bank Account Information</p>
                            <h3>John Jane Doe</h3>
                            <Row className="mx-0 intxt-cmsnbox">
                             <Col md={4} xs={6} className="lft-txt">Account Name</Col>
                             <Col md={8} xs={6} className="rgt-txt">: Lorem Ispum</Col>
                             <Col md={4} xs={6} className="lft-txt">Account Number</Col>
                             <Col md={8} xs={6} className="rgt-txt">: Lorem Ispum</Col>
                             <Col md={4} xs={6} className="lft-txt">IFSC Code</Col>
                             <Col md={8} xs={6} className="rgt-txt">: Lorem Ispum</Col>
                            </Row>
                          </Col>
                          <Col md={2} xs={2} className="text-end">
                            <img src= {'assets/images/employee/edt-icn.png'} className="img-fluid" alt="Mcust Img"/>
                          </Col>
                      </Row>
        {/* ========== Employee Commission Box Content Start ========== */}
                      <Row xs={{ cols:2, gutter: 2 }} lg={{ cols: 2, gutter: 3}} className="mt-2 ecmsnbox">
                        <Col>
                         <div className=" minbx-flw">
                          <Row className="mx-0">
                            <Col md={3} xs={4} className="align-self-center px-0"><img src= {'assets/images/employee/prfl-icn.png'} className="img-fluid" alt="Mcust Img"/></Col>
                            <Col md={9} xs={8} className="align-self-center">
                              <h3>12,536</h3>
                              <p>Total Balance</p>
                            </Col>
                          </Row>
                         </div>
                        </Col>
                        <Col>
                         <div className="p-3 minbx-flw">
                          <Row className="mx-0">
                            <Col md={3} xs={4} className="align-self-center px-0"><img src= {'assets/images/employee/prfl-icn.png'} className="img-fluid" alt="Mcust Img"/></Col>
                            <Col md={9} xs={8} className="align-self-center">
                              <h3>12,536</h3>
                              <p>Withdraw</p>
                            </Col>
                          </Row>
                         </div>
                        </Col>
                        <Col>
                         <div className="p-3 minbx-flw">
                          <Row className="mx-0">
                            <Col md={3} xs={4} className="align-self-center px-0"><img src= {'assets/images/employee/prfl-icn.png'} className="img-fluid" alt="Mcust Img"/></Col>
                            <Col md={9} xs={8} className="align-self-center">
                              <h3>12,536</h3>
                              <p>Send Invitations</p>
                            </Col>
                          </Row>
                         </div>
                        </Col>
                        <Col>
                         <div className="p-3 minbx-flw">
                          <Row className="mx-0">
                            <Col md={3} xs={4} className="align-self-center px-0"><img src= {'assets/images/employee/prfl-icn.png'} className="img-fluid" alt="Mcust Img"/></Col>
                            <Col md={9} xs={8} className="align-self-center">
                              <h3>12,536</h3>
                              <p>Commission History</p>
                            </Col>
                          </Row>
                         </div>
                        </Col>
                      </Row>
        {/* ========== Employee Commission Box Content End ========== */}  
                      </Col>
        {/* ========== Employee Commission Customer Box Content Start ========== */}
                      <Col md={12} xs={12} className="mcusbx">
                        <Row className="mx-0">
                          <Col md={2} xs={2} className="align-self-center px-1"><img src= {'assets/images/employee/usrrund-img.png'} className="img-fluid" alt="Mcust Img"/></Col>
                          <Col md={5} xs={5} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                            <h3>Customer Name</h3>
                          </Col>
                          <Col md={3} xs={4} className="align-self-center cnt-txt d-none">
                             <p>Status <span className="ylw"><img src= {'assets/images/employee/svg/dots.svg'} className="img-fluid" alt="Mcust Img"/></span></p>
                          </Col>
                          <Col md={5} xs={5} className="align-self-center text-end">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 6 10" fill="none">
                              <path d="M0.603935 9.79545C0.686317 9.93182 0.816994 10 0.995963 10C1.07835 10 1.18061 9.98295 1.22323 9.96023C1.31697 9.90909 5.92472 5.29545 5.97017 5.20739C6.00994 5.12784 6.00994 4.87216 5.97017 4.79261C5.92472 4.70455 1.31697 0.0909092 1.22323 0.0397722C1.18061 0.0170452 1.07834 2.15132e-07 0.995962 2.18733e-07C0.720407 2.30778e-07 0.547119 0.173295 0.547119 0.448863C0.547119 0.53125 0.561323 0.627841 0.578367 0.661932C0.595412 0.696022 1.5698 1.6875 2.74588 2.8608L4.87931 5L2.74588 7.13636C1.5698 8.3125 0.595413 9.30398 0.578368 9.33807C0.527234 9.44034 0.541438 9.69034 0.603935 9.79545Z" fill="#3F9534"/>
                            </svg>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={12} xs={12} className="mcusbx">
                        <Row className="mx-0">
                          <Col md={2} xs={2} className="align-self-center px-1"><img src= {'assets/images/employee/usrrund-img.png'} className="img-fluid" alt="Mcust Img"/></Col>
                          <Col md={5} xs={5} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                            <h3>Customer Name</h3>
                          </Col>
                          <Col md={3} xs={4} className="align-self-center cnt-txt d-none">
                             <p>Status <span className="grn"><img src= {'assets/images/employee/svg/dots.svg'} className="img-fluid" alt="Mcust Img"/></span></p>
                          </Col>
                          <Col md={5} xs={5} className="align-self-center text-end">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 6 10" fill="none">
                              <path d="M0.603935 9.79545C0.686317 9.93182 0.816994 10 0.995963 10C1.07835 10 1.18061 9.98295 1.22323 9.96023C1.31697 9.90909 5.92472 5.29545 5.97017 5.20739C6.00994 5.12784 6.00994 4.87216 5.97017 4.79261C5.92472 4.70455 1.31697 0.0909092 1.22323 0.0397722C1.18061 0.0170452 1.07834 2.15132e-07 0.995962 2.18733e-07C0.720407 2.30778e-07 0.547119 0.173295 0.547119 0.448863C0.547119 0.53125 0.561323 0.627841 0.578367 0.661932C0.595412 0.696022 1.5698 1.6875 2.74588 2.8608L4.87931 5L2.74588 7.13636C1.5698 8.3125 0.595413 9.30398 0.578368 9.33807C0.527234 9.44034 0.541438 9.69034 0.603935 9.79545Z" fill="#3F9534"/>
                            </svg>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={12} xs={12} className="mcusbx">
                        <Row className="mx-0">
                          <Col md={2} xs={2} className="align-self-center px-1"><img src= {'assets/images/employee/usrrund-img.png'} className="img-fluid" alt="Mcust Img"/></Col>
                          <Col md={5} xs={5} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                            <h3>Customer Name</h3>
                          </Col>
                          <Col md={3} xs={4} className="align-self-center cnt-txt d-none">
                             <p>Status <span className="red"><img src= {'assets/images/employee/svg/dots.svg'} className="img-fluid" alt="Mcust Img"/></span></p>
                          </Col>
                          <Col md={5} xs={5} className="align-self-center text-end">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 6 10" fill="none">
                              <path d="M0.603935 9.79545C0.686317 9.93182 0.816994 10 0.995963 10C1.07835 10 1.18061 9.98295 1.22323 9.96023C1.31697 9.90909 5.92472 5.29545 5.97017 5.20739C6.00994 5.12784 6.00994 4.87216 5.97017 4.79261C5.92472 4.70455 1.31697 0.0909092 1.22323 0.0397722C1.18061 0.0170452 1.07834 2.15132e-07 0.995962 2.18733e-07C0.720407 2.30778e-07 0.547119 0.173295 0.547119 0.448863C0.547119 0.53125 0.561323 0.627841 0.578367 0.661932C0.595412 0.696022 1.5698 1.6875 2.74588 2.8608L4.87931 5L2.74588 7.13636C1.5698 8.3125 0.595413 9.30398 0.578368 9.33807C0.527234 9.44034 0.541438 9.69034 0.603935 9.79545Z" fill="#3F9534"/>
                            </svg>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={12} xs={12} className="mcusbx">
                        <Row className="mx-0">
                          <Col md={2} xs={2} className="align-self-center px-1"><img src= {'assets/images/employee/usrrund-img.png'} className="img-fluid" alt="Mcust Img"/></Col>
                          <Col md={5} xs={5} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                            <h3>Customer Name</h3>
                          </Col>
                          <Col md={3} xs={4} className="align-self-center cnt-txt d-none">
                             <p>Status <span className="ylw"><img src= {'assets/images/employee/svg/dots.svg'} className="img-fluid" alt="Mcust Img"/></span></p>
                          </Col>
                          <Col md={5} xs={5} className="align-self-center text-end">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 6 10" fill="none">
                              <path d="M0.603935 9.79545C0.686317 9.93182 0.816994 10 0.995963 10C1.07835 10 1.18061 9.98295 1.22323 9.96023C1.31697 9.90909 5.92472 5.29545 5.97017 5.20739C6.00994 5.12784 6.00994 4.87216 5.97017 4.79261C5.92472 4.70455 1.31697 0.0909092 1.22323 0.0397722C1.18061 0.0170452 1.07834 2.15132e-07 0.995962 2.18733e-07C0.720407 2.30778e-07 0.547119 0.173295 0.547119 0.448863C0.547119 0.53125 0.561323 0.627841 0.578367 0.661932C0.595412 0.696022 1.5698 1.6875 2.74588 2.8608L4.87931 5L2.74588 7.13636C1.5698 8.3125 0.595413 9.30398 0.578368 9.33807C0.527234 9.44034 0.541438 9.69034 0.603935 9.79545Z" fill="#3F9534"/>
                            </svg>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={12} xs={12} className="mcusbx">
                        <Row className="mx-0">
                          <Col md={2} xs={2} className="align-self-center px-1"><img src= {'assets/images/employee/usrrund-img.png'} className="img-fluid" alt="Mcust Img"/></Col>
                          <Col md={5} xs={5} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                            <h3>Customer Name</h3>
                          </Col>
                          <Col md={3} xs={4} className="align-self-center cnt-txt d-none">
                             <p>Status <span className="grn"><img src= {'assets/images/employee/svg/dots.svg'} className="img-fluid" alt="Mcust Img"/></span></p>
                          </Col>
                          <Col md={5} xs={5} className="align-self-center text-end">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 6 10" fill="none">
                              <path d="M0.603935 9.79545C0.686317 9.93182 0.816994 10 0.995963 10C1.07835 10 1.18061 9.98295 1.22323 9.96023C1.31697 9.90909 5.92472 5.29545 5.97017 5.20739C6.00994 5.12784 6.00994 4.87216 5.97017 4.79261C5.92472 4.70455 1.31697 0.0909092 1.22323 0.0397722C1.18061 0.0170452 1.07834 2.15132e-07 0.995962 2.18733e-07C0.720407 2.30778e-07 0.547119 0.173295 0.547119 0.448863C0.547119 0.53125 0.561323 0.627841 0.578367 0.661932C0.595412 0.696022 1.5698 1.6875 2.74588 2.8608L4.87931 5L2.74588 7.13636C1.5698 8.3125 0.595413 9.30398 0.578368 9.33807C0.527234 9.44034 0.541438 9.69034 0.603935 9.79545Z" fill="#3F9534"/>
                            </svg>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={12} xs={12} className="mcusbx">
                        <Row className="mx-0">
                          <Col md={2} xs={2} className="align-self-center px-1"><img src= {'assets/images/employee/usrrund-img.png'} className="img-fluid" alt="Mcust Img"/></Col>
                          <Col md={5} xs={5} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                            <h3>Customer Name</h3>
                          </Col>
                          <Col md={3} xs={4} className="align-self-center cnt-txt d-none">
                             <p>Status <span className="red"><img src= {'assets/images/employee/svg/dots.svg'} className="img-fluid" alt="Mcust Img"/></span></p>
                          </Col>
                          <Col md={5} xs={5} className="align-self-center text-end">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 6 10" fill="none">
                              <path d="M0.603935 9.79545C0.686317 9.93182 0.816994 10 0.995963 10C1.07835 10 1.18061 9.98295 1.22323 9.96023C1.31697 9.90909 5.92472 5.29545 5.97017 5.20739C6.00994 5.12784 6.00994 4.87216 5.97017 4.79261C5.92472 4.70455 1.31697 0.0909092 1.22323 0.0397722C1.18061 0.0170452 1.07834 2.15132e-07 0.995962 2.18733e-07C0.720407 2.30778e-07 0.547119 0.173295 0.547119 0.448863C0.547119 0.53125 0.561323 0.627841 0.578367 0.661932C0.595412 0.696022 1.5698 1.6875 2.74588 2.8608L4.87931 5L2.74588 7.13636C1.5698 8.3125 0.595413 9.30398 0.578368 9.33807C0.527234 9.44034 0.541438 9.69034 0.603935 9.79545Z" fill="#3F9534"/>
                            </svg>
                          </Col>
                        </Row>
                      </Col>
        {/* ========== Employee Commission Customer Box Content End ========== */}
        {/* ========== Employee Commission Content End ========== */}
                     </Col>
                  </Row>
               </Container>
            </section>
        {/* ========== Footer Start ========== */}
            <Footer/>
        {/* ========== Footer End ========== */}
        {/* ========== Employee Commission Page End ========== */}
        </>
    )
}
export default EmpCommi;