import React, { useEffect } from 'react';
import { Row, Col, Container, Button, Table, Stack } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './MerReceipts.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
// import $ from 'jquery';

const MerReceipts = () => {
useEffect(() => {
      document.title = `Merchant Receipts | Blue Faucet`;
      document.getElementById('bodywrap').className='usrrcptcpgmain grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
},[])  
    return (
      
        <>
      {/* ========== Merchant Receipt Page Start ========== */}
         <section className="gen-wrap">
      {/* ========== Navbar Start ========== */}
         <Navbar headerText={'Receipts'}/>
      {/* ========== Navbar End ========== */}
         <Container>
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
               <Col md={12} xs={12} className="py-2">
      {/* ========== Receipt Content Start ========== */}
               <Row className="mx-0 maincnt-recepts">
                  <Col md={8} xs={8}>
                  <h3>Zylker Dezigns</h3>
                  <p>4606 Benson Park Drive Rosedale, U.S.A</p>
                  <p>Phone: 555-2938</p>
                  <div className="dwncmt">
                     <h4>Serviced by: <span>xxxxxxxxxxxx</span></h4>
                     <h5>Information:</h5>
                     <h4>Invoice No.<span>#-0023</span></h4>
                     <h4>Invoice Date: <span>08May 2023</span></h4>
                  </div>
                  </Col>
                  <Col md={4} xs={4} className="text-end left-cnt-rcpt">
                  <img src="assets/images/invicepgicn.png" className="img-fluid ps-2" alt="Hm Img"/>
                  <h4>Due Date:<span>20May 2023</span></h4>
                  </Col>
                  <Col md={12} xs={12} className="tblinvcpg">
                  <Table responsive>
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Service/Items</th>
                           <th>Price</th>
                           <th>Q’ty</th>
                           <th>Amount</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>1</td>
                           <td>Nivea Shampoo</td>
                           <td>$49</td>
                           <td>1</td>
                           <td>$49</td>
                        </tr>
                        <tr>
                           <td>2</td>
                           <td>Nivea Shampoo</td>
                           <td>$49</td>
                           <td>1</td>
                           <td>$49</td>
                        </tr>
                     </tbody>
                  </Table>
                  <div className="text-end tbldwndv">
                     <Stack direction="horizontal" gap={4}>
                        <div className="pb-1 ms-auto">
                           <p>SubTotal</p>
                        </div>
                        <div className="pb-1"><span>$99</span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={4}>
                        <div className="pb-1 ms-auto">
                           <p>Tax(10.25%)</p>
                        </div>
                        <div className="pb-1"><span>$10</span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={4}>
                        <div className="pb-1 ms-auto">
                           <p>Coupon/Discount Code</p>
                        </div>
                        <div className="pb-1">-<span>$xx</span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={4}>
                        <div className="pb-1 ms-auto">
                           <p>Total</p>
                        </div>
                        <div className="pb-1"><span>$99</span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={4}>
                        <div className="pb-1 pe-1 ms-auto">
                           <p>Add Tips</p>
                        </div>
                        <div className="pb-1 ps-3"><span> - </span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={4} className="lstprcdv">
                        <div className=" ms-auto">
                           <h6>Total Balance</h6>
                        </div>
                        <div className="">
                           <h6>$99</h6>
                        </div>
                     </Stack>
                  </div>
                  </Col>
                  <Col md={6} xs={7} className="dwn-cntrecpt">
                  <h3>Transaction Records</h3>
                  <p>Account Name: ABC Bank</p>
                  <p>Account Number: xxxxxx123</p>
                  <p>Expires xx/xx Chip: 12345</p>
                  <img src="assets/images/svg/sing.svg" className="img-fluid ps-2" alt="Hm Img"/>
                  </Col>
                  <Row className="mx-0 btnlst-usrrcpt">
                     <Col md={6} xs={6} className="px-0">
                     <NavLink to="#"><Button variant="" type="submit">Approved</Button></NavLink>
                     </Col>
                     <Col md={6} xs={6} className="px-0 text-end">
                     <NavLink to="#">Refund Policy</NavLink>
                     </Col>
                  </Row>
               </Row>
      {/* ========== Receipt Content End ========== */}
               <Col className="rcptbtn mt-3 d-none"><NavLink to="#"><Button variant="" type="submit"> Apply For Refund</Button></NavLink></Col>
               </Col>
               </Col>
            </Row>
         </Container>
         </section>
      {/* ========== Merchant Receipt Page End ========== */}
        </>
    )
}
export default MerReceipts;