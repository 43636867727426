import React, { useEffect } from 'react';
import { Row, Col, Container, Button, Table, Stack } from 'react-bootstrap';
import './EmpInvoice.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import { NavLink } from 'react-router-dom';

const EmpInvoice = () => {
useEffect(() => {
      document.title = `Employee Invoice | Blue Faucet`;
      document.getElementById('bodywrap').className='einvicepgmain grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
},[])  
    return (
        <>
          {/* ========== Employee Invoice Page Start ========== */}
            <section className="gen-wrap">
          {/* ========== Navbar Start ========== */}
             <Navbar headerText={'Invoice'}/>
          {/* ========== Navbar End ========== */}
             <Container>
                <Row className="mx-0 mianhmdwncnt">
                  <Col className="mnhmcnt">
          {/* ========== Employee Invoice Content Start ========== */}
                   <Col md={12} xs={12} className="py-2">
                     <Row className="mx-0 maincnt-invc">
                       <Col md={8} xs={8}>
                          <h3>Zylker Dezigns</h3>
                          <p>4606 Benson Park Drive Rosedale, U.S.A</p>
                          <p>Phone: 555-2938</p>
                          <div className="dwncmt">
                             <h4>Serviced by: <span>xxxxxxxxxxxx</span></h4>
                             <h4>Receipts 2445</h4>
                             <h4>Date: <span>08May 2023</span></h4>
                          </div>
                       </Col>
                       <Col md={4} xs={4} className="text-end"><img src="assets/images/invicepgicn.png" className="img-fluid ps-2" alt="Hm Img"/></Col>
                       <Col md={12} xs={12} className="tblinvcpg">
                          <Table responsive>
                           <thead>
                             <tr>
                               <th>#</th>
                               <th>Service/Items</th>
                               <th>Price</th>
                               <th>Q’ty</th>
                               <th>Amount</th>
                             </tr>
                           </thead>
                           <tbody>
                             <tr>
                               <td>1</td>
                               <td>Nivea Shampoo</td>
                               <td>$49</td>
                               <td>1</td>
                               <td>$49</td>
                             </tr>
                             <tr>
                               <td>2</td>
                               <td>Nivea Shampoo</td>
                               <td>$49</td>
                               <td>1</td>
                               <td>$49</td>
                             </tr>
                           </tbody>
                         </Table>
                         <div className="text-end tbldwndv">
                            <Stack direction="horizontal" gap={4}>
                              <div className="pb-1 ms-auto"><p>SubTotal</p></div>
                              <div className="pb-1"><span>$99</span></div>
                            </Stack>
                            <Stack direction="horizontal" gap={4}>
                              <div className="pb-1 ms-auto"><p>Tax(10.25%)</p></div>
                              <div className="pb-1"><span>$10</span></div>
                            </Stack>
                            <Stack direction="horizontal" gap={4}>
                              <div className="pb-1 ms-auto"><p>Coupon/Discount Code</p></div>
                              <div className="pb-1">-<span>$xx</span></div>
                            </Stack>
                            <Stack direction="horizontal" gap={4}>
                              <div className="pb-1 ms-auto"><p>Total</p></div>
                              <div className="pb-1"><span>$99</span></div>
                            </Stack>
                            <Stack direction="horizontal" gap={4}>
                              <div className="pb-1 pe-1 ms-auto"><p>Add Tips</p></div>
                              <div className="pb-1 ps-3"><span> - </span></div>
                            </Stack>
                            <Stack direction="horizontal" gap={4} className="lstprcdv">
                              <div className=" ms-auto"><h6>Total Balance</h6></div>
                              <div className=""><h6>$99</h6></div>
                            </Stack>
                         </div>
                       </Col>
                       <Col md={12} xs={12} className="gren-btn mt-3"><NavLink to="/emp-order"><Button variant="" type="submit">Done</Button></NavLink></Col>
                       <Col className="btmreting text-center d-none">
        {/* ========== Employee Invoice Rating Content Start ========== */}
                          <div id="str-trng" className="mb-1">
                           <div className="rating-group strrtg-rating">
                            <label aria-label="1 star" className="rating__label" htmlFor="rating-1"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                            <input className="rating__input" name="rating" id="rating-1" defaultValue="1" type="radio"/>
                            <label aria-label="2 stars" className="rating__label" htmlFor="rating-2"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                            <input className="rating__input" name="rating" id="rating-2" defaultValue="2" type="radio"/>
                            <label aria-label="3 stars" className="rating__label" htmlFor="rating-3"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                            <input className="rating__input" name="rating" id="rating-3" defaultValue="3" type="radio" defaultChecked={true}/>
                            <label aria-label="4 stars" className="rating__label" htmlFor="rating-4"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                            <input className="rating__input" name="rating" id="rating-4" defaultValue="4" type="radio"/>
                            <label aria-label="5 stars" className="rating__label" htmlFor="rating-5"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                            <input className="rating__input" name="rating" id="rating-5" defaultValue="5" type="radio"/>
                           </div>
                        </div>
        {/* ========== Employee Invoice Rating Content End ========== */}
                       </Col>
                     </Row>
                   </Col>
        {/* ========== Employee Invoice Content End ========== */}
                  </Col>
                </Row>
             </Container>
          </section>
        {/* ========== Employee Invoice Page End ========== */}
        </>
    )
}
export default EmpInvoice;