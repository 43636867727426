import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Modal, Form } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './MerEmp.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
import $ from 'jquery';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const MerEmp = () => {
const [isSunChecked, setSunIsChecked] = useState(true);
const [isMonChecked, setMonIsChecked] = useState(false);
const [isTueChecked, setTueIsChecked] = useState(false);
const [isWedChecked, setWedIsChecked] = useState(false);
const [isThuChecked, setThuIsChecked] = useState(false);
const [isFriChecked, setFriIsChecked] = useState(false);
const [isSatChecked, setSatIsChecked] = useState(false);

const handleSunCheckboxChange = () => {setSunIsChecked(!isSunChecked);};
const handleMonCheckboxChange = () => {setMonIsChecked(!isMonChecked);};
const handleTueCheckboxChange = () => {setTueIsChecked(!isTueChecked);};
const handleWedCheckboxChange = () => {setWedIsChecked(!isWedChecked);};
const handleThuCheckboxChange = () => {setThuIsChecked(!isThuChecked);};
const handleFriCheckboxChange = () => {setFriIsChecked(!isFriChecked);};
const handleSatCheckboxChange = () => {setSatIsChecked(!isSatChecked);};   

const [values, setValues] = useState({password: "",showPassword: false,});
const [values1, setValues1] = useState({password: "",showPassword: false,});

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const [show1, setShow1] = useState(false);
const handleClose1 = () => setShow1(false);
const handleShow1 = () => setShow1(true);

const [show2, setShow2] = useState(false);
const handleClose2 = () => setShow2(false);
const handleShow2 = () => setShow2(true);

useEffect(() => {
      document.title = `Merchant Employee Management | Blue Faucet`;
      document.getElementById('bodywrap').className='memplyscrnpgmain grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });     
},[])  
const like = (id) => {$('.lik'+id).hide();$('.dis'+id).show();}
const dislike = (id) => {$('.dis'+id).hide();$('.lik'+id).show();}

const handleClickShowPassword = () => {setValues({ ...values, showPassword: !values.showPassword });}
const handleClickShowPassword1 = () => {setValues1({ ...values1, showPassword: !values1.showPassword });}
    return (
      
        <>
        {/* ========== Merchant Employee Management Page Start ========== */}
         <section className="gen-wrap">
         {/* ========== Navbar Start ========== */}
            <Navbar headerText={'Employees'}/>
         {/* ========== Navbar End ========== */}
         <Container>
         {/* ========== Employee Management Top Content Start ========== */}
            <Row className="uespgfixdv mx-0">
               <Col md={9} xs={7} className="align-self-center pe-0">
               <div className="constrain hide-inpt">
                  <input className="input-elevated" type="text" placeholder="Search here.."/>
               </div>
               </Col>
               <Col md={3} xs={5} className="align-self-center text-end usctpbtn">
               <NavLink to="#"><Button variant="" type="submit" onClick={handleShow}>Add Employee</Button></NavLink>
               </Col>     
            </Row>
         {/* ========== Employee Management Top Content End ========== */}
         {/* ========== Employee Management Main Content Box Start ========== */}
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
               <Col md={12} xs={12} className="py-2">
               <NavLink to="#" onClick={handleShow1}>
                  <Col className="ustrlpg">
                  <Row className="mx-0 ustrl-box uesbox">
                     <Col md={2} xs={2} className="px-0 align-self-center prflimgues">
                     <img src="assets/images/usrprfl.png" className="img-fluid" alt="Hm Img"/><span></span></Col>
                     <Col md={8} xs={9} className="px-2 px-md-0 align-self-center">
                     <h3>Eliza Norton</h3>
                     <p>Sr. IOS Developer</p>
                     </Col>
                     <Col md={2} xs={1} className="text-end px-0 useeslinedv">
                     <div className="ginlike">
                        <div className="active">
                           <svg className="lik1 lik" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" onClick={() =>
                              like(1)}>
                              <circle cx="12" cy="12" r="12" fill="#EAF0FD"/>
                              <path d="M8.79002 6.9229C8.31907 7.0049 7.74971 7.25093 7.38185 7.52975C6.7586 8.0007 6.29468 8.73173 6.10255 9.5354C5.99945 9.97589 5.96899 10.6437 6.0346 11.0701C6.20564 12.1854 6.87107 13.2445 8.16677 14.4628C8.64475 14.915 8.97981 15.2103 10.3458 16.3841C11.187 17.1105 11.6064 17.4549 11.6954 17.4994C11.8782 17.5861 12.1218 17.5861 12.3046 17.4994C12.485 17.4127 14.9944 15.2524 15.8332 14.4628C17.1289 13.2445 17.7944 12.1854 17.9654 11.0701C18.031 10.6437 18.0005 9.97589 17.8975 9.5354C17.7053 8.73173 17.2414 8.0007 16.6181 7.52975C16.2409 7.2439 15.6762 7.00256 15.1865 6.9229C14.9218 6.87838 14.3805 6.87838 14.1181 6.9229C13.3918 7.04942 12.7099 7.45243 12.1968 8.06396C12.0961 8.18346 12.0094 8.28187 12 8.28187C11.9906 8.28187 11.9039 8.18346 11.8032 8.06396C11.2901 7.45243 10.6082 7.04942 9.88188 6.9229C9.62883 6.88072 9.04307 6.88072 8.79002 6.9229Z" fill="#FF0000"/>
                           </svg>
                           <svg className="dis1 dis" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() =>
                              dislike(1)}>
                              <circle id="Ellipse_40" data-name="Ellipse 40" cx="12" cy="12" r="12" fill="#EAF0FD"/>
                              <g id="Black">
                                 <path id="Vector" d="M8.79,6.923a3.579,3.579,0,0,0-1.408.607A3.579,3.579,0,0,0,6.1,9.535a4.716,4.716,0,0,0-.068,1.535,6.039,6.039,0,0,0,2.132,3.393c.478.452.813.748,2.179,1.921.841.726,1.261,1.071,1.35,1.115a.746.746,0,0,0,.609,0c.18-.087,2.69-2.247,3.529-3.037a6.039,6.039,0,0,0,2.132-3.393A4.719,4.719,0,0,0,17.9,9.535,3.579,3.579,0,0,0,16.618,7.53a3.491,3.491,0,0,0-1.432-.607,4.29,4.29,0,0,0-1.068,0A3.262,3.262,0,0,0,12.2,8.064a1.991,1.991,0,0,1-.2.218,1.991,1.991,0,0,1-.2-.218A3.262,3.262,0,0,0,9.882,6.923,4.726,4.726,0,0,0,8.79,6.923Z" fill="none" stroke="black" strokeWidth="1"/>
                              </g>
                           </svg>
                        </div>
                     </div>
                     </Col>
                     <Col md={12} xs={12} className="uesdwn-cntcrd">
                     <h6>Services</h6>
                     <p>Migrate and build apps. Azure App Service is a fully managed platform for creating web applications..</p>
                     </Col>
                     <Col md={6} xs={6} className="align-self-center mt-2 left-uespg">
                     <div id="str-trng" className="">
                        <div className="rating-group strrtg-rating">
                           <label aria-label="1 star" className="rating__label" htmlFor="rating-1"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-1" defaultValue="1" type="radio"/>
                           <label aria-label="2 stars" className="rating__label" htmlFor="rating-2"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-2" defaultValue="2" type="radio"/>
                           <label aria-label="3 stars" className="rating__label" htmlFor="rating-3"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-3" defaultValue="3" type="radio"/>
                           <label aria-label="4 stars" className="rating__label" htmlFor="rating-4"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-4" defaultValue="4" type="radio"/>
                           <label aria-label="5 stars" className="rating__label" htmlFor="rating-5"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-5" defaultValue="5" type="radio" defaultChecked/>
                        </div>
                     </div>
                     <p>4.9 Ratings</p>
                     </Col>
                  </Row>
                  </Col>
               </NavLink>
               </Col>
               </Col>
            </Row>
         {/* ========== Employee Management Main Content Box End ========== */}
            <Col className="popupmsunscptn">
            <div className="frstpop">
         {/* ========== Employee Management Subscription Plan Popup Start ========== */}
               <Modal show={show} onHide={handleClose} className="popupwrngcnt">
                  <Modal.Header closeButton>
                     <h3 className="mdlttltxt">Subscription Plan</h3>
                  </Modal.Header>
                  <Modal.Body>
                     <Col md={12} xs={12} className="ppinpt">
                     <h3 className="sbsrptn-tital">Price : $30</h3>
                     <Form>
                        <Form.Group className="mb-3">
                           <Form.Label> </Form.Label>
                        </Form.Group>
                     </Form>
                     </Col>
                     <Row className="pp-btn1 mx-0">
                        <Col>
                        <NavLink to="#" onClick={handleShow1}><Button className="blc" variant="">Pay Now</Button></NavLink>
                        </Col>
                     </Row>
                  </Modal.Body>
                  <Modal.Footer className="d-none"></Modal.Footer>
               </Modal>
         {/* ========== Employee Management Subscription Plan Popup End ========== */}
            </div>
            <div className="popupmrcntflow1">
         {/* ========== Employee Management Employee Setup Popup Start ========== */}
               <Modal show={show1} onHide={handleClose1} className="popupwrngcnt">
                  <Modal.Header closeButton>
                     <h3 className="mdlttltxt">Employee Setup</h3>
                  </Modal.Header>
                  <Modal.Body>
                     <Col md={12} xs={12} className="ppinpt">
                     <Form>
                        <Form.Group className="mb-3">
                           <Form.Label>Employee ID</Form.Label>
                           <Form.Control type="text" placeholder="Enter employee ID" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Employee Name</Form.Label>
                           <Form.Control type="text" placeholder="Enter employee name" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Employee Contact No.</Form.Label>
                           <Form.Control type="tel" placeholder="+1 984 236 564 0" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Date of Birth</Form.Label>
                           <Form.Control type="date" placeholder="dd/mm/yy" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Commission (in %)<span></span></Form.Label>
                           <Form.Control type="Number" placeholder="5%" min="0" />
                        </Form.Group>
                        <div className="custm-add mb-3">
                           <NavLink to="#">
                              <div className="linetp-cutmadd"></div>
                              <Button variant="" onClick={handleShow2}>
                                 Working Hours Setup
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M7.62132 0.0863132C7.43385 0.183171 7.27138 0.373764 7.21826 0.558107C7.19952 0.633095 7.18702 1.7829 7.18702 3.92941V7.18822H3.93134C1.78172 7.18822 0.631923 7.20072 0.556936 7.21947C0.0945177 7.34757 -0.139816 7.92872 0.0882688 8.37864C0.182002 8.56611 0.372594 8.72858 0.556936 8.7817C0.631923 8.80045 1.78172 8.81295 3.93134 8.81295H7.18702V12.0686C7.18702 14.2183 7.19952 15.3681 7.21826 15.4431C7.34637 15.9055 7.92751 16.1398 8.37743 15.9117C8.5649 15.818 8.72737 15.6274 8.78049 15.4431C8.79923 15.3681 8.81173 14.2183 8.81173 12.0686V8.81295H12.0705C14.217 8.81295 15.3668 8.80045 15.4418 8.7817C15.6262 8.72858 15.8167 8.56611 15.9136 8.37864C16.1386 7.92872 15.9042 7.34757 15.4418 7.21947C15.3668 7.20072 14.217 7.18822 12.0705 7.18822H8.81173V3.92941C8.81173 1.7829 8.79923 0.633095 8.78049 0.558107C8.68988 0.233163 8.36181 0.00195217 7.99938 0.00195217C7.83378 0.00195217 7.75567 0.0206995 7.62132 0.0863132Z" fill="#3F9534"/>
                                 </svg>
                              </Button>
                           </NavLink>
                        </div>
                        <Form.Group className="mb-3 pswinpt">
                           <Form.Label>Password<span></span></Form.Label>
                           <Form.Control type={values.showPassword ? "text" : "password"} id="enpsw" placeholder="At least 8 characters"/>
                           {values.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword}></i>}
                        </Form.Group>
                        <Form.Group className="mb-3 pswinpt">
                           <Form.Label>Re-enter Password<span></span></Form.Label>
                           <Form.Control type={values1.showPassword ? "text" : "password"} id="renpsw" placeholder="At least 8 characters" />
                           {values1.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword1}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword1}></i>}
                        </Form.Group>
                     </Form>
                     </Col>
                     <Row className="pp-btn1">
                        <Col>
                        <NavLink to="#" onClick={() => handleClose1()}><Button className="blc" variant="">Add</Button></NavLink>
                        </Col>
                     </Row>
                  </Modal.Body>
                  <Modal.Footer className="d-none"></Modal.Footer>
               </Modal>
            {/* ========== Employee Management Employee Setup Popup End ========== */}
            </div>
            <div className="inmodal">
            {/* ========== Employee Management Working Hours Setup Popup Start ========== */}
               <Modal show={show2} onHide={handleClose2} className="popupwrngcnt">
                  <Modal.Header closeButton>
                     <h3 className="mdlttltxt">Working Hours Setup</h3>
                  </Modal.Header>
                  <Modal.Body>
                     <Row className="chkbtn mx-0 mb-3">
                        <Col xs={10} className="align-self-center">
                        <Form.Label>Sunday</Form.Label>
                        </Col>
                        <Col xs={2} className="align-self-center text-end">
                        <Form.Check type="switch" id="custom-switch" label=""  checked={isSunChecked} onChange={handleSunCheckboxChange} />
                        </Col>
                        {isSunChecked &&
                        <Col xs={12}>
                        <Row>
                           <Col xs={6} className="align-self-center mt-2">
                           <Form.Label>Start</Form.Label>
                           <Form.Control type="time" placeholder="00:00 AM" />
                           </Col>
                           <Col xs={6} className="align-self-center mt-2">
                           <Form.Label>End</Form.Label>
                           <Form.Control type="time" placeholder="00:00 AM" />
                           </Col>
                        </Row>
                        </Col>
                        }
                     </Row>
                     <Row className="chkbtn mx-0 mb-3">
                        <Col xs={10} className="align-self-center">
                        <Form.Label>Monday</Form.Label>
                        </Col>
                        <Col xs={2} className="align-self-center text-end">
                        <Form.Check type="switch" id="custom-switch" label="" checked={isMonChecked} onChange={handleMonCheckboxChange} />
                        </Col>
                        {isMonChecked &&
                        <Col xs={12}>
                        <Row>
                           <Col xs={6} className="align-self-center mt-2">
                           <Form.Label>Start</Form.Label>
                           <Form.Control type="time" placeholder="00:00 AM" />
                           </Col>
                           <Col xs={6} className="align-self-center mt-2">
                           <Form.Label>End</Form.Label>
                           <Form.Control type="time" placeholder="00:00 AM" />
                           </Col>
                        </Row>
                        </Col>   
                        }                              
                     </Row>
                     <Row className="chkbtn mx-0 mb-3">
                        <Col xs={10} className="align-self-center">
                        <Form.Label>Tuesday</Form.Label>
                        </Col>
                        <Col xs={2} className="align-self-center text-end">
                        <Form.Check type="switch" id="custom-switch" label="" checked={isTueChecked} onChange={handleTueCheckboxChange} />
                        </Col>
                        {isTueChecked &&
                        <Col xs={12}>
                        <Row>
                           <Col xs={6} className="align-self-center mt-2">
                           <Form.Label>Start</Form.Label>
                           <Form.Control type="time" placeholder="00:00 AM" />
                           </Col>
                           <Col xs={6} className="align-self-center mt-2">
                           <Form.Label>End</Form.Label>
                           <Form.Control type="time" placeholder="00:00 AM" />
                           </Col>
                        </Row>
                        </Col>   
                        }                                  
                     </Row>
                     <Row className="chkbtn mx-0 mb-3">
                        <Col xs={10} className=" mb-2 align-self-center">
                        <Form.Label>Wednesday</Form.Label>
                        </Col>
                        <Col xs={2} className=" mb-2 align-self-center text-end">
                        <Form.Check type="switch" id="custom-switch" label="" checked={isWedChecked} onChange={handleWedCheckboxChange}/>
                        </Col>
                        {isWedChecked &&
                        <Col xs={12}>
                        <Row>
                           <Col xs={6} className="align-self-center mt-2">
                           <Form.Label>Start</Form.Label>
                           <Form.Control type="time" placeholder="00:00 AM" />
                           </Col>
                           <Col xs={6} className="align-self-center mt-2">
                           <Form.Label>End</Form.Label>
                           <Form.Control type="time" placeholder="00:00 AM" />
                           </Col>
                        </Row>
                        </Col>   
                        }    
                     </Row>
                     <Row className="chkbtn mx-0 mb-3">
                        <Col xs={10} className=" mb-2 align-self-center">
                        <Form.Label>Thursday</Form.Label>
                        </Col>
                        <Col xs={2} className=" mb-2 align-self-center text-end">
                        <Form.Check type="switch" id="custom-switch" label="" checked={isThuChecked} onChange={handleThuCheckboxChange}/>
                        </Col>
                        {isThuChecked &&
                        <Col xs={12}>
                        <Row>
                           <Col xs={6} className="align-self-center mt-2">
                           <Form.Label>Start</Form.Label>
                           <Form.Control type="time" placeholder="00:00 AM" />
                           </Col>
                           <Col xs={6} className="align-self-center mt-2">
                           <Form.Label>End</Form.Label>
                           <Form.Control type="time" placeholder="00:00 AM" />
                           </Col>
                        </Row>
                        </Col>   
                        }         
                     </Row>
                     <Row className="chkbtn mx-0 mb-3">
                        <Col xs={10} className=" mb-2 align-self-center">
                        <Form.Label>Friday</Form.Label>
                        </Col>
                        <Col xs={2} className=" mb-2 align-self-center text-end">
                        <Form.Check type="switch" id="custom-switch" label="" checked={isFriChecked} onChange={handleFriCheckboxChange}/>
                        </Col>
                        {isFriChecked &&
                        <Col xs={12}>
                        <Row>
                           <Col xs={6} className="align-self-center mt-2">
                           <Form.Label>Start</Form.Label>
                           <Form.Control type="time" placeholder="00:00 AM" />
                           </Col>
                           <Col xs={6} className="align-self-center mt-2">
                           <Form.Label>End</Form.Label>
                           <Form.Control type="time" placeholder="00:00 AM" />
                           </Col>
                        </Row>
                        </Col>   
                        }    
                     </Row>
                     <Row className="chkbtn mx-0 mb-3">
                        <Col xs={10} className=" mb-2 align-self-center">
                        <Form.Label>Saturday</Form.Label>
                        </Col>
                        <Col xs={2} className=" mb-2 align-self-center text-end">
                        <Form.Check type="switch" id="custom-switch" label="" defaultChecked={isSatChecked} onChange={handleSatCheckboxChange}/>
                        </Col>
                        {isSatChecked &&
                        <Col xs={12}>
                        <Row>
                           <Col xs={6} className="align-self-center mt-2">
                           <Form.Label>Start</Form.Label>
                           <Form.Control type="time" placeholder="00:00 AM" />
                           </Col>
                           <Col xs={6} className="align-self-center mt-2">
                           <Form.Label>End</Form.Label>
                           <Form.Control type="time" placeholder="00:00 AM" />
                           </Col>
                        </Row>
                        </Col>   
                        }                                  
                     </Row>
                     <Row className="chkbtn mx-0 mb-3">
                        <Col xs={12} className="align-self-center">
                        <h3 className="mdlttltxt float-start">Orders/Appointments settings</h3>
                        <span style={{ }}>
                           <OverlayTrigger delay={{ hide: 450, show: 300 }} overlay={(props) => (
                           <Tooltip {...props}>Get more information in FAQs!</Tooltip>
                           )}placement="bottom">
                           <NavLink to="/mer-help"> <span className="mdlttltxt text-danger">&nbsp;? </span></NavLink>
                           </OverlayTrigger>
                        </span>
                        </Col>
                        <div className="linepop my-1"></div>
                        <Row className="chkbtn mx-0 mb-3 mt-3 px-0">
                           <Col xs={9} className="align-self-center">
                           <Form.Label>How Many customers can be accepted per time slot (per hour):</Form.Label>
                           </Col>
                           <Col xs={3} className="align-self-center">
                            <Form.Control type="Number" placeholder="4" />
                           </Col>
                        </Row>
                        <Col xs={10} className="align-self-center mb-2">
                        <Form.Label>Auto accept during working hours</Form.Label>
                        </Col>
                        <Col xs={2} className="align-self-center text-end">
                        <Form.Check type="switch" id="custom-switch" label="" defaultChecked={true}/>
                        </Col>
                        <Col xs={10} className="align-self-center mb-2">
                        <Form.Label>Need to be Confirmed</Form.Label>
                        </Col>
                        <Col xs={2} className="align-self-center text-end">
                        <Form.Check type="switch" id="custom-switch" label=""/>
                        </Col>
                        <Col xs={10} className="align-self-center mb-2">
                        <Form.Label>Auto Refuse (customers in the blocklist)</Form.Label>
                        </Col>
                        <Col xs={2} className="align-self-center text-end">
                        <Form.Check type="switch" id="custom-switch" label="" defaultChecked={true}/>
                        </Col>
                     </Row>
                     <Row className="pp-btn1 mx-0">
                        <Col>
                        <NavLink to="#" onClick={() => handleClose2()}><Button className="blc" variant="">Confirm</Button></NavLink>
                        </Col>
                     </Row>
                  </Modal.Body>
                  <Modal.Footer className="d-none"></Modal.Footer>
               </Modal>
            {/* ========== Employee Management Working Hours Setup Popup End ========== */}
            </div>
            </Col>
         </Container>
      </section>
      <Footer/>
      {/* ========== Merchant Employee Management Page Start ========== */}
        </>
    )
}
export default MerEmp;