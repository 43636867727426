import React, { useEffect, } from 'react';
import { Row, Col, Container, Button} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './UserStrdtl.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 

const UserStrdtl = () => {
useEffect(() => {
      document.title = `User Store Details | Blue Faucet`;
      document.getElementById('bodywrap').className='usdtlpgmain';
    window.scrollTo({top: 0,left: 0,behavior: 'smooth', });  
},[])  

    return (
        <>
        {/* ========== User Store Detail Page Start ========== */}
         <section className="gen-wrap">
         {/* ========== Navbar Start ========== */}
            <Navbar headerText={'Store Name Here'}/>
         {/* ========== Navbar End ========== */}
            <Container>
               <Row>
                  <Col md={12} xs={12} className="px-0 usdtlsld">
                  {/* ========== OwlCarousel Top Slider Start ========== */}
                  <OwlCarousel items={1}  className='owl-theme' loop margin={10} >
                     <Col className="">
                     <img src= {'assets/images/ustrdtl-imgtp.png'} className="img-fluid" alt="Sld Img"/>
                     </Col> 
                     <Col className="">
                     <img src= {'assets/images/ustrdtl-imgtp.png'} className="img-fluid" alt="Sld Img"/>
                     </Col> 
                     <Col className="">
                     <img src= {'assets/images/ustrdtl-imgtp.png'} className="img-fluid" alt="Sld Img"/>
                     </Col> 
                     <Col className="">
                     <img src= {'assets/images/ustrdtl-imgtp.png'} className="img-fluid" alt="Sld Img"/>
                     </Col>   
                  </OwlCarousel>
                  {/* ========== OwlCarousel Top Slider End ========== */}
                  </Col>
               </Row>
               <Row className="mx-0 mianhmdwncnt">
               {/* ========== Store Detail Content Start ========== */}
                  <Col className="mnhmcnt my-3">
                  <Row className="sldrdwncnt">
                     <Col md={6} xs={5}>
                     <p>Saloon Services</p>
                     <h3>Beauty Saloon</h3>
                     </Col>
                     <Col md={6} xs={7} className="text-end">
                     <div id="str-trng" className="mb-1">
                        <div className="rating-group strrtg-rating">
                           <label aria-label="1 star" className="rating__label" htmlFor="rating-1"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-1" defaultValue="1" type="radio"/>
                           <label aria-label="2 stars" className="rating__label" htmlFor="rating-2"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-2" defaultValue="2" type="radio"/>
                           <label aria-label="3 stars" className="rating__label" htmlFor="rating-3"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-3" defaultValue="3" type="radio"/>
                           <label aria-label="4 stars" className="rating__label" htmlFor="rating-4"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-4" defaultValue="4" type="radio"/>
                           <label aria-label="5 stars" className="rating__label" htmlFor="rating-5"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-5" defaultValue="5" type="radio" defaultChecked={true}/>
                        </div>
                        <span>(2121 ratings)</span>
                     </div>
                     <p>4.2 Km - Address Here</p>
                     </Col>
                     <Col md={12} xs={12} className="adrsdv">
                     <Row className="mx-0">
                        <Col md={10} xs={8} className="mb-2 usdtl-textlft">
                        <p>Address</p>
                        </Col>
                        <Col md={2} xs={4}  className="mb-2 text-end usdtl-icn">
                        <img src= {'assets/images/svg/location.svg'} className="img-fluid" alt="Sld Img"/></Col>
                        <Col md={10} xs={10} className="mb-3 usdtl-textlft cntdtl">
                        <p>Lorem Ipsum Area, Lorem Colony, Opp. Lorem Ipsum, Lorem Highway, London, UK - Pin Code</p>
                        </Col>
                        <Col md={10} xs={8} className="mb-2 usdtl-textlft cntdtl">
                        <p>+1 987 654 321 00</p>
                        </Col>
                        <Col md={2} xs={4}  className="mb-2 text-end usdtl-icn">
                        <img src= {'assets/images/svg/call.svg'} className="img-fluid" alt="Sld Img"/></Col>
                        <Col md={10} xs={8} className="mb-2 usdtl-textlft cntdtl">
                        <p>+1 123 456 789 00</p>
                        </Col>
                        <Col md={2} xs={4}  className="mb-2 text-end usdtl-icn">
                        <img src= {'assets/images/svg/call.svg'} className="img-fluid" alt="Sld Img"/></Col>
                     </Row>
                     </Col>
                     <Col md={12} xs={12} className="px-0">
                     <Row className="btn-usrstrdtl mt-3 mx-0">
                        <Col>
                         <NavLink to="/product"><Button type="button" className="lft-btn">Products </Button></NavLink></Col>
                        <Col>
                         <NavLink to="/service"><Button type="button" className="rgt-btn">Services </Button></NavLink></Col>
                     </Row>
                     </Col>
                     <Col className="usdtllstdv">
                     <div className="tptxtcnt mb-1 mt-2">
                        <p>Coupon Box</p>
                         <NavLink to="/coupon-list">View All</NavLink>
                     </div>
                     {/* ========== OwlCarousel Coupon Box Start ========== */}
                     <Col md={12} xs={12} className="mb-3 usdtllstsldr">
                     <OwlCarousel items={1.2}  className='owl-theme' loop margin={20} >
                        <Col md={12} xs={12} className="mb-">
                        <NavLink to="#">
                         <Row className="mx-0 cuscoupn cpnblu mb-2">
                          <Col className="align-self-center">
                           <p>Limited Period Offer</p>
                           <h4>UP TO 50% OFF</h4>
                           <p>Stay Tuned</p>
                          </Col>
                          <Col className="align-self-end text-end"><img src="assets/images/cupn-img.png" className="img-fluid w-100" alt="Hmtp Postr"/></Col>
                         </Row>
                        </NavLink>
                        </Col>  
                        <Col md={12} xs={12} className="py-2 cupn-crd">
                        <div className="coupon">
                           <div className="left">
                              <div>Discount</div>
                           </div>
                           <div className="center">
                              <div>
                                 <h2>5% Off</h2>
                                 <h4 className="h3line">2KZYVZD1PP3F</h4>
                                 <p>Get 5% Discount on your first order</p>
                                 <p>Start Date : <span>2023/07/03</span> &nbsp;&nbsp;End Date :<span>2023/07/03</span> </p>
                              </div>
                           </div>
                           <div className="right">
                           </div>
                        </div>
                        </Col>  
                     </OwlCarousel>
                     </Col>
                     </Col>
                  </Row>
                  </Col>
                  {/* ========== OwlCarousel Coupon Box End ========== */}
               </Row>
            </Container>
         </section>
         {/* ========== User Store Detail Page End ========== */}
        </>
    )
}

export default UserStrdtl;