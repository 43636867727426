import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Form, Modal } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './MerCoupon.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
import $ from 'jquery';
import { MultiSelect } from "react-multi-select-component";
const options1 = [
  { value: 0, label: "Giana Lipshutz" },
  { value: 1, label: "James George" },
  { value: 2, label: "Jordyn George" },
];
const options2 = [
  { value: 0, label: "Get 5% Discount on your first order" },
  { value: 1, label: "Get 5% Discount Refer a Friend" },
  { value: 2, label: "Get 5% Discount Use Blue Faucet to Make Reservation" },
];

const MerCoupon = () => {

const [show1, setShow1] = useState(false);
const handleClose1 = () => setShow1(false);
const handleShow1 = () => setShow1(true);

const [show2, setShow2] = useState(false);
const handleClose2 = () => setShow2(false);
const handleShow2 = () => setShow2(true);

const [show3, setShow3] = useState(false);
const handleClose3 = () => setShow3(false);
const handleShow3 = () => setShow3(true);

const [optionSelected, setSelected] = useState([]);
  const handleChange = (selected: Option[]) => {
    setSelected(selected);
  };
useEffect(() => {
      document.title = `Merchant Sending Coupons | Blue Faucet`;
      document.getElementById('bodywrap').className='msndcpnpgmain grnbg';
    window.scrollTo({top: 0,left: 0,behavior: 'smooth', 
    }); 
      $("document").ready(function() {
          $('.dropdown-menu').on('click', function(e) {
              if($(this).hasClass('dropdown-menu-form')) {
                  e.stopPropagation();
              }
          });
        });
},[])  
    return (
      <>
      {/* ========== Merchant Sending Coupons Page Start ========== */}
         <section className="gen-wrap">
      {/* ========== Navbar Start ========== */}
         <Navbar headerText={'Sending Coupons'}/>
      {/* ========== Navbar End ========== */}
         <Container>
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
      {/* ========== Sending Coupons Content Start ========== */}
               <Col md={12} xs={12} className="tptxtucs mt-3">
               </Col>
               <Col md={12} xs={12} className="py-2 miansndngcupn">
               <Col md={12} xs={12} className="mb-2">
               <h3 className="mdlttltxt">Find your target customers</h3>
               <div className="inlnflexbox">
                  <span>
                     <Form.Check type="radio"label="All" name="formHorizontalRadiosmsctp1" id="formHorizontalRadiosmsctp1"/>
                  </span>
               </div>
               </Col>
               <Col md={12} xs={12} className="mb-2">
               <div className="inlnflexbox">
                  <span>
                     <Form.Check type="radio"label="The Last Visit Was" name="formHorizontalRadiosmsctp1" id="formHorizontalRadiosmsctp1"/>
                  </span>
                  <span>
                     <Form.Control type="text"/>
                  </span>
                  <span>Weeks ago</span>
               </div>
               </Col>
               <Col md={12} xs={12} className="mb-2">
               <div className="inlnflexbox">
                  <span>
                     <Form.Check type="radio"label="Spending Over" name="formHorizontalRadiosmsctp1" id="formHorizontalRadiosmsctp1"/>
                  </span>
                  <span>
                     <Form.Control type="text"/>
                  </span>
                  <span>In The Past</span>
                  <span>
                     <Form.Control type="text"/>
                  </span>
                  <span>Day</span>
               </div>
               </Col>
               <Form.Group className="mb-3 my-3">
                  <h3 className="mdlttltxt">Customers List</h3>
                  <MultiSelect key="example_id" options={options1} onChange={handleChange} defaultValue={optionSelected} isSelectAll={true} menuPlacement={"bottom"}/>
               </Form.Group>
               <Form.Group className="mb-3 d-none">
                  <Form.Label className="mb-2"></Form.Label>
                  <MultiSelect key="example_id" options={options2} onChange={handleChange} defaultValue={optionSelected} isSelectAll={true} menuPlacement={"bottom"}/>
               </Form.Group>
               <Row className="msndngcpnbtn my-4">
                  <Col className="btn-clr">
                  <NavLink to="/mer-marketing"><Button variant="">Setup Welcome Coupon</Button>{' '}</NavLink>
                  </Col>
                  <Col className="btn-dv">
                  <NavLink to="#" onClick={handleShow1}><Button variant="">Create Coupon</Button>{' '}</NavLink>
                  </Col>
               </Row>
      {/* ==========  Select Coupon Start ========== */}
               <Col md={12} xs={12} className="">
               <h3 className="mdlttltxt">Select Coupon</h3>
               </Col>
               <Col md={12} xs={12} className="py-2 cupn-crd">
               <div className="coupon">
                  <div className="left">
                     <div>Discount</div>
                  </div>
                  <div className="center">
                     <div>
                        <h2>5% Off</h2>
                        <h4 className="h3line">2KZYVZD1PP3F</h4>
                        <p>Get 5% Discount on your first order</p>
                        <p>Start Date : <span>2023/07/03</span> &nbsp;&nbsp;End Date :<span>2023/07/03</span> </p>
                     </div>
                  </div>
                  <div className="right">
                     <div>
                        <Form.Group className="ckbox-servicepg" controlId="">
                           <div key="default-1" className="">
                              <Form.Check type="checkbox" id="default-1" label=""/>
                           </div>
                        </Form.Group>
                     </div>
                     <NavLink to="#" onClick={handleShow2}>
                        <img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
                     </NavLink>
                  </div>
               </div>
               </Col>
               <Col md={12} xs={12} className="py-2 cupn-crd">
               <div className="coupon">
                  <div className="left">
                     <div>Discount</div>
                  </div>
                  <div className="center">
                     <div>
                        <h2>5% Off</h2>
                        <h4 className="h3line">2KZYVZD1PP3F</h4>
                        <p>Get 5% Discount Refer a Friend</p>
                        <p>Start Date : <span>2023/07/03</span> &nbsp;&nbsp;End Date :<span>2023/07/03</span> </p>
                     </div>
                  </div>
                  <div className="right">
                     <div>
                        <Form.Group className="ckbox-servicepg" controlId="">
                           <div key="default-1" className="">
                              <Form.Check type="checkbox" id="default-1" label=""/>
                           </div>
                        </Form.Group>
                     </div>
                     <NavLink to="#" onClick={handleShow2}>
                        <img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
                     </NavLink>
                  </div>
               </div>
               </Col>
               <Col md={12} xs={12} className="py-2 cupn-crd mb-2">
               <div className="coupon">
                  <div className="left">
                     <div>Discount</div>
                  </div>
                  <div className="center">
                     <div>
                        <h2>5% Off</h2>
                        <h4 className="h3line">2KZYVZD1PP3F</h4>
                        <p>Use BlueFaucet to Make Reservation</p>
                        <p>Start Date : <span>2023/07/03</span> &nbsp;&nbsp;End Date :<span>2023/07/03</span> </p>
                     </div>
                  </div>
                  <div className="right">
                     <div>
                        <Form.Group className="ckbox-servicepg" controlId="">
                           <div key="default-1" className="">
                              <Form.Check type="checkbox" id="default-1" label=""/>
                           </div>
                        </Form.Group>
                     </div>
                     <NavLink to="#" onClick={handleShow2}>
                        <img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
                     </NavLink>
                  </div>
               </div>
               </Col>
               <Col md={12} xs={12} className="mb-3">
                  <Row className="mx-0 cuscoupn cpnblu">
                  <Col className="align-self-center">
                    <p>Limited Period Offer</p>
                    <h4>UP TO 50% OFF</h4>
                    <p>Stay Tuned</p>
                  </Col>
                  <Col className="align-self-end text-end"><img src="assets/images/cupn-img.png" className="img-fluid w-100" alt="Coupon"/></Col>
                  <Col md={1} xs={1}>
                  <div className="right-boxgrdant-cupan">
                     <div>
                        <Form.Group className="ckbox-servicepg" controlId="">
                           <div key="default-1" className="">
                              <Form.Check type="checkbox" id="default-1" label=""/>
                           </div>
                        </Form.Group>
                     </div>
                     <NavLink to="#" onClick={handleShow2} className="dlt-icncupn">
                        <img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
                     </NavLink>
                  </div>
                   </Col>
                  </Row>
               </Col>
      {/* ========== Select Coupon End ========== */}
               <Row className="mt-3 mb-5">
                  <Col className="btndvcupn">
                  <NavLink to="#" onClick={handleShow3}>
                     <Button type="button" className="btn">Submit</Button>
                  </NavLink>
                  </Col>
               </Row>
               </Col>
               <div className="madcustmrpop">
                  <Modal show={show1} onHide={handleClose1} className="popupwrngcnt" centered>
                     <Modal.Header closeButton>
                        <h3 className="mdlttltxt">Create Coupon</h3>
                     </Modal.Header>
                     <Modal.Body>
                        <Col md={12} xs={12} className="ppinpt">
                        <Form>
                           <Form.Group className="mb-3">
                              <Form.Label className="fw-bold">Name of Coupon</Form.Label>
                              <Form.Control type="text" placeholder="Enter name of coupon" />
                           </Form.Group>
                           <Row className="mb-3">
                              <Col>
                              <Form.Group className="">
                                 <Form.Label className="fw-bold">Start (On 00:00 AM)</Form.Label>
                                 <Form.Control type="date" placeholder="" />
                              </Form.Group>
                              </Col>
                              <Col>
                              <Form.Group className="">
                                 <Form.Label className="fw-bold">End (On 12:59 PM)</Form.Label>
                                 <Form.Control type="date" placeholder="" />
                              </Form.Group>
                              </Col>
                           </Row>
                           <Col md={12} xs={12} className="mb-2">
                           <Form.Label column sm={12} className="px-md-1 fw-bold">Choose Your Offer</Form.Label>
                           <div className="inlnflexbox">
                              <span>
                                 <Form.Check type="checkbox"label="Double Coupon is “Not” Allowed."name="formHorizontalRadios"id="formHorizontalRadios1"/>
                              </span>
                              <span>
                                 <Form.Check type="checkbox"label="One-time use only"name="formHorizontalRadios"id="formHorizontalRadios2"/>
                              </span>
                              <span>Item only</span>
                           </div>
                           </Col>
                           <Col md={12} xs={12} className="mb-2">
                           <Form.Label column sm={12} className="px-md-1 fw-bold">Choose Your Offer</Form.Label>
                           <div className="inlnflexbox">
                              <span>
                                 <Form.Check type="radio"label="Buy" name="formHorizontalRadiosmsc1" id="formHorizontalRadiosmsc1"/>
                              </span>
                              <span>
                                 <Form.Control type="text"/>
                              </span>
                              <span>Get</span>
                              <span>
                                 <Form.Control type="text"/>
                              </span>
                              <span>Free, For</span>
                              <span>
                                 <Form.Control className="thdcrctrmore" type="text"/>
                              </span>
                              <span>Item only</span>
                           </div>
                           </Col>
                           <Col className="my-3">
                           <Form.Label className="">Ex.: Buy <u>1</u> get <u>1</u> free, for <u>Coca</u> item only. OR Buy <u>Cola</u> get <u>Soda</u> free, for ___ item only.</Form.Label>
                           </Col>
                           <Col md={12} xs={12} className="mb-2">
                           <div className="inlnflexbox">
                              <span>
                                 <Form.Check type="radio"label="" name="formHorizontalRadiosmsc1" id="formHorizontalRadiosmsc1"/>
                              </span>
                              <span>
                                 <Form.Control type="text"/>
                              </span>
                              <span>% Discount When Purchase Over $</span>
                              <span>
                                 <Form.Control type="text"/>
                              </span>
                           </div>
                           </Col>
                           <Col md={12} xs={12} className="mb-2">
                           <div className="inlnflexbox">
                              <span>
                                 <Form.Check type="radio"label="Return before" name="formHorizontalRadiosmsc1" id="formHorizontalRadiosmsc1"/>
                              </span>
                              <span>
                                 <Form.Control type="date"/>
                              </span>
                              <span>and get an</span>
                              <span>
                                 <Form.Control type="text"/>
                              </span>
                              <span>% Discount</span>
                           </div>
                           </Col>
                           <Col md={12} xs={12} className="mb-2">
                           <div className="inlnflexbox">
                              <span>
                                 <Form.Check type="radio"label="Or use your own words:" name="formHorizontalRadiosmsc1" id="formHorizontalRadiosmsc1"/>
                              </span>
                              <span>
                                 <Form.Control className="fifthwdtmore" type="text"/>
                              </span>
                           </div>
                           </Col>
                           <Col md={12} xs={12} className="mb-2">
                           <Form.Label column sm={12} className="px-md-1 fw-bold">Set Loyalty Program:</Form.Label>
                           <div className="inlnflexbox">
                              <span>
                                 <Form.Check type="radio"label="Buy" name="formHorizontalRadiosmsc2" id="formHorizontalRadiosmsc2"/>
                              </span>
                              <span>
                                 <Form.Control className="thdcrctrmore" type="text"/>
                              </span>
                              <span>Item</span>
                              <span>
                                 <Form.Control type="text"/>
                              </span>
                              <span>Times and Get</span>
                              <span>
                                 <Form.Control type="text"/>
                              </span>
                              <span>for free</span>
                           </div>
                           </Col>
                           <Col md={12} xm={12} className="selctimg-mrktng">
                           <Form.Label className="fw-bold">Choose Coupon Image</Form.Label>
                           <div className="cusradio">
                              <input className="radio-label" type="radio" name="yesno" id="yes" checked="checked"/>
                              <label className="button-label" htmlFor="yes">
                              <img src= {'assets/images/coupan.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
                              <input className="radio-label" type="radio" name="yesno" id="no"/>
                              <label className="button-label" htmlFor="no"><img src= {'assets/images/coupan-2.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
                           </div>
                           </Col>
                           <Form.Group className="mb-3">
                              <Form.Label className="fw-bold">Description</Form.Label>
                              <Form.Control as="textarea" rows={3} placeholder="Enter coupon description" />
                           </Form.Group>
                           <Col md={12} xs={12} className="btnpopmcstmr my-3">
                           <NavLink to="#" onClick={() => handleClose1()} ><Button variant="black" type="button">Create Coupon</Button></NavLink>
                           </Col>
                           <Col className="d-none">
                           <Form.Group className="mb-3">
                              <Form.Label>Category</Form.Label>
                              <Form.Select aria-label="Default select example">
                                 <option>Category</option>
                                 <option defaultValue="1">Select Category</option>
                              </Form.Select>
                           </Form.Group>
                           <Form.Group className="mb-3">
                              <Form.Label>Status</Form.Label>
                              <Form.Select aria-label="Default select example">
                                 <option>Active</option>
                                 <option defaultValue="1">deactivate</option>
                              </Form.Select>
                           </Form.Group>
                           <Form.Group className="mb-3">
                              <Form.Label>$ Amount or % Discount</Form.Label>
                              <Form.Control type="text" placeholder="$ or %" />
                           </Form.Group>
                           <Col className="upladcustm">
                           <Form.Label>Select Image</Form.Label>
                           <div id="dropzone">
                              <div><img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/> Upload Image</div>
                              <input type="file" accept="image/png, application/pdf" />
                           </div>
                           </Col>
                           </Col>
                        </Form>
                        </Col>
                     </Modal.Body>
                  </Modal>
                  <div className="inmodal">
      {/* ========== Delete Popup Start ========== */}
                     <Modal show={show2} onHide={handleClose2} className="popupwrngcnt">
                        <Modal.Header closeButton>
                           <h3 className="mdlttltxt">Are you sure you want to delete?</h3>
                        </Modal.Header>
                        <Modal.Body>
                           <Row>
                              <Col className="btn-clr">
                              <NavLink to="#" onClick={handleClose2}><Button variant="">Yes</Button></NavLink>
                              </Col>
                              <Col className="btn-dv">
                              <NavLink to="#" onClick={handleClose2}><Button variant="">No</Button></NavLink>
                              </Col>
                           </Row>
                        </Modal.Body>
                        <Modal.Footer className="d-none"></Modal.Footer>
                     </Modal>
      {/* ========== Delete Popup End ========== */}
                 <Col className="md={12} xs={12}">
                 <div className="inmodal">
                  {/* ========== Delete Popup Start ========== */}
                    <Modal show={show3} onHide={handleClose3} className="popupwrngcnt">
                       <Modal.Header closeButton></Modal.Header>
                       <Modal.Body>
                         <Row className="cnfmtnpg justify-content-center mx-0">
                           <Col md={12} xs={12} className="px-0 ">
                            <div className="text-center ">
                              <img src= {'assets/images//svg/confrmation-check.svg'} className="img-fluid mb-3" alt="Cnfrmation Img"/> 
                              <h3>Coupon Sent</h3>
                            </div>
                           </Col>
                           <Col md={8} xs={8} className="btn-ordrcnfrmation text-center mt-3 mb-5">
                            <NavLink to="/mer-dashboard"><Button variant="home">Done</Button>{' '}</NavLink>
                           </Col>
                         </Row>
                       </Modal.Body>
                       <Modal.Footer className="d-none"></Modal.Footer>
                    </Modal>
                  {/* ========== Delete Popup End ========== */}
                 </div>
                 </Col>
                  </div>
               </div>
      {/* ========== Sending Coupons Content End ========== */}
               </Col>
            </Row>
         </Container>
      </section>
      {/* ========== Footer Start ========== */}
      <Footer/>
      {/* ========== Footer End ========== */}
      {/* ========== Merchant Sending Coupons Page End ========== */}
        </>
    )
}
export default MerCoupon;