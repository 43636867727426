import * as React from "react";
import { Row, Col, Button, Table } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel'; 
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 

//{/* ========== Order Summary Content Start ========== */}
export const Ordersummary = (
  <Row className="ordr-smrypg mx-0">
  <Row className="mx-0 tpord-sum">
    <Col md={8} xs={8} className=" mb-3"><h3>Haircut + Hair Wash + Hair Therapy</h3></Col>
    <Col md={4} xs={4} className="text-end mb-3">
      <h3>$49.99</h3>
      <p className="linetxt colrchng">$69.99</p>
    </Col>
    <Col md={3} xs={3} className="colrchng">Date:</Col>
    <Col md={9} xs={9}><p>9 May 2023</p></Col>
    <Col md={3} xs={3} className="colrchng">Chosen Time:</Col>
    <Col md={9} xs={9}><p>10:00 AM - 11:00 AM</p></Col>
  </Row>
  <Col md={12} xs={12} className="px-0 ordrsmrysld">
  <h3>From Coupons Box</h3>
       <OwlCarousel items={1.2}  className='owl-theme' loop={false} margin={20} center>
          <Col className="crdordrsmry">
             <Row className="mx-0 mincrd">
               <Col md={9} xs={8} className="align-self-center mb-2">
               <h4>Get discount code of 25% Off</h4>
               </Col>
               <Col md={3} xs={4} className="align-self-center text-end mb-2"><Button variant="">Apply</Button>{' '}</Col>
               <Col md={12} xs={12} ><p>Get discount code of 25% Off value | All saloon deals T&C</p></Col>
             </Row>
          </Col> 
          <Col className="crdordrsmry">
             <Row className="mx-0 mincrd">
               <Col md={9} xs={8} className="align-self-center mb-2">
               <h4>Get discount code of 25% Off</h4>
               </Col>
               <Col md={3} xs={4} className="align-self-center text-end mb-2"><Button variant="">Apply</Button>{' '}</Col>
               <Col md={12} xs={12} ><p>Get discount code of 25% Off value | All saloon deals T&C</p></Col>
             </Row>
          </Col>  
       </OwlCarousel>
   </Col>
   <Col className="dwnordsmry">
     <h3 className="">Estimate price</h3>
     <div className="line"></div>
     <Table responsive>
      <thead>
        <tr>
          
        </tr>
      </thead>
      <tbody>
        <tr className="tp-lst">
          <td>Order Total</td>
          <td><h6>$49.99</h6></td>
        </tr>
        <tr className="tp-lst line">
          <td>Savings</td>
          <td><h6 className="grnclr">-$20.99</h6></td>
        </tr>
        <tr className="cnt-lst">
          <td>SubTotal</td>
          <td><h6>$49.99</h6></td>
        </tr>
        <tr className="cnt-lst">
          <td>Tips</td>
          <td><h6>-</h6></td>
        </tr>
        <tr className="cnt-lst line">
          <td>Tax</td>
          <td><h6>$5.99</h6></td>
        </tr>
        <tr className="dwn-lst line">
          <td>Total payable</td>
          <td><h6 className="bluclr">$54.98</h6></td>
        </tr>
      </tbody>
    </Table>
   </Col>
  </Row>
);
//{/* ========== Order Summary Content End ========== */}