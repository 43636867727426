import * as React from "react";
import { Row, Col, Form, Nav, Tab } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

//{/* ========== Paybill Content Start ========== */}
export const Paybill = (
  <Row className="mx-0 pybl-smrypg">
    <Col md={12} xs={12} className=" mb-3 shwbx-pyblsmry">
      <Row>
        <Col md={6} xs={6} className="align-self-center low"><p>Conf. No:</p></Col>
        <Col md={6} xs={6} className="align-self-center text-end"><p>1234zvsdvsvdvff</p></Col>
      </Row>
    </Col>
    <Col md={12} xs={12} className=" mb-3 shwbx-pyblsmry">
    <h3>Invoice</h3>
    <div className="line specer"></div>
      <Row className="mx-0">
        <Col md={6} xs={6} className="align-self-center low"><p>Tax</p></Col>
        <Col md={6} xs={6} className="align-self-center text-end"><p>$5.99</p></Col>
        <Col md={6} xs={6} className="align-self-center low"><p>Tips</p></Col>
        <Col md={6} xs={6} className="align-self-center text-end"><Form.Control type="email" placeholder="-" /></Col>
        <div className="line specer col-12"></div>
        <Col md={6} xs={6} className="align-self-center low"><p>Total Payable</p></Col>
        <Col md={6} xs={6} className="align-self-center text-end"><p className="bluclr">$54.98</p></Col>
      </Row>
    </Col>
    <Col md={12} xs={12} className=" mb-3 shwbx-pyblsmry">
      <Row>
        <Col md={12} xs={12} className="">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                 <Nav variant="pills" className="flex-column">
                  <Row>
                    <Col>
                      <Nav.Item>
                       <Nav.Link eventKey="first">Cash</Nav.Link>
                      </Nav.Item>
                    </Col>
                    <Col>
                      <Nav.Item>
                       <Nav.Link eventKey="second">Credit Card</Nav.Link>
                      </Nav.Item>
                    </Col>
                    <Col>
                      <Nav.Item>
                       <Nav.Link eventKey="third">Debit Card</Nav.Link>
                      </Nav.Item>
                    </Col>                    
                  </Row>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <Row xs={{ gutterX: 1 }} className="mx-0 d-none">
                        <Col md={12} xs={12}>
                         <Form.Group className="mb-3">
                          <Form.Control type="text" placeholder="Cardholder Name" />
                         </Form.Group>
                        </Col>
                        <Col md={12} xs={12}>
                         <Form.Group className="mb-3">
                          <Form.Control type="text" placeholder="Card Number" />
                         </Form.Group>
                        </Col> 
                        <Col md={6} xs={6} className="">
                         <Form.Select aria-label="Default select example" className="mb-3">
                            <option>Month</option>
                            <option defaultValue="1">01</option>
                            <option defaultValue="2">02</option>
                            <option defaultValue="3">03</option>
                            <option defaultValue="4">04</option>
                            <option defaultValue="5">05</option>
                            <option defaultValue="6">06</option>
                            <option defaultValue="7">07</option>
                            <option defaultValue="8">08</option>
                            <option defaultValue="9">09</option>
                            <option defaultValue="10">10</option>
                            <option defaultValue="11">11</option>
                            <option defaultValue="12">12</option>
                          </Form.Select>
                        </Col>
                        <Col md={6} xs={6} className="">
                         <Form.Select aria-label="Default select example" className="mb-3">
                            <option>Year</option>
                            <option defaultValue="1">2022</option>
                            <option defaultValue="2">2023</option>
                            <option defaultValue="3">2024</option>
                            <option defaultValue="4">2025</option>
                            <option defaultValue="5">2026</option>
                            <option defaultValue="6">2027</option>
                          </Form.Select>
                        </Col>
                        <Col md={6} xs={6} className="">
                         <Form.Group controlId="exampleForm.ControlInput1" className="mb-3">
                          <Form.Control type="text" placeholder="CVV" />
                         </Form.Group>
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row xs={{ gutterX: 1 }} className="mx-0">
                        <Col md={12} xs={12} >
                         <Form.Group className="mb-3">
                          <Form.Control type="text" placeholder="Cardholder Name" />
                         </Form.Group>
                        </Col>
                        <Col md={12} xs={12} >
                         <Form.Group className="mb-3">
                          <Form.Control type="text" placeholder="Card Number" />
                         </Form.Group>
                        </Col>                        
                        <Col md={6} xs={6} className="">
                         <Form.Select aria-label="Default select example" className="mb-3">
                            <option>Month</option>
                            <option defaultValue="1">01</option>
                            <option defaultValue="2">02</option>
                            <option defaultValue="3">03</option>
                            <option defaultValue="4">04</option>
                            <option defaultValue="5">05</option>
                            <option defaultValue="6">06</option>
                            <option defaultValue="7">07</option>
                            <option defaultValue="8">08</option>
                            <option defaultValue="9">09</option>
                            <option defaultValue="10">10</option>
                            <option defaultValue="11">11</option>
                            <option defaultValue="12">12</option>
                          </Form.Select>
                        </Col>
                        <Col md={6} xs={6} className="">
                         <Form.Select aria-label="Default select example" className="mb-3">
                            <option>Year</option>
                            <option defaultValue="1">2022</option>
                            <option defaultValue="2">2023</option>
                            <option defaultValue="3">2024</option>
                            <option defaultValue="4">2025</option>
                            <option defaultValue="5">2026</option>
                            <option defaultValue="6">2027</option>
                          </Form.Select>
                        </Col>
                        <Col md={6} xs={6} className="">
                         <Form.Group controlId="exampleForm.ControlInput1" className="mb-3">
                          <Form.Control type="text" placeholder="CVV" />
                         </Form.Group>
                        </Col>
                        <Col md={12}>
                          <p>Note: There is no card details you added. <NavLink to="/payment">CLICK HERE</NavLink> to add your card details.</p>
                        </Col>                        
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row xs={{ gutterX: 1 }} className="mx-0">
                        <Col md={12} xs={12} >
                         <Form.Group className="mb-3">
                          <Form.Control type="text" placeholder="Cardholder Name" />
                         </Form.Group>
                        </Col>
                        <Col md={12} xs={12} >
                         <Form.Group className="mb-3">
                          <Form.Control type="text" placeholder="Card Number" />
                         </Form.Group>
                        </Col>                        
                        <Col md={6} xs={6} className="">
                         <Form.Select aria-label="Default select example" className="mb-3">
                            <option>Month</option>
                            <option defaultValue="1">01</option>
                            <option defaultValue="2">02</option>
                            <option defaultValue="3">03</option>
                            <option defaultValue="4">04</option>
                            <option defaultValue="5">05</option>
                            <option defaultValue="6">06</option>
                            <option defaultValue="7">07</option>
                            <option defaultValue="8">08</option>
                            <option defaultValue="9">09</option>
                            <option defaultValue="10">10</option>
                            <option defaultValue="11">11</option>
                            <option defaultValue="12">12</option>
                          </Form.Select>
                        </Col>
                        <Col md={6} xs={6} className="">
                         <Form.Select aria-label="Default select example" className="mb-3">
                            <option>Year</option>
                            <option defaultValue="1">2022</option>
                            <option defaultValue="2">2023</option>
                            <option defaultValue="3">2024</option>
                            <option defaultValue="4">2025</option>
                            <option defaultValue="5">2026</option>
                            <option defaultValue="6">2027</option>
                          </Form.Select>
                        </Col>
                        <Col md={6} xs={6} className="">
                         <Form.Group controlId="exampleForm.ControlInput1" className="mb-3">
                          <Form.Control type="text" placeholder="CVV" />
                         </Form.Group>
                        </Col>
                        <Col md={12}>
                          <p>Note: There is no card details you added. <NavLink to="/payment">CLICK HERE</NavLink> to add your card details.</p>
                        </Col>
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
        </Col>
      </Row>
    </Col>
  </Row>
);
//{/* ========== Paybill Content End ========== */}