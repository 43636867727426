import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Modal, Form} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

const Navbar = (props) => {
const navigate = useNavigate();

const [latitude, setLatitude] = useState(null);
const [longitude, setLongitude] = useState(null);
const [address, setAddress] = useState("");
// const [zipcode, setZipcode] = useState("");

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });   
    // Get the current position using the browser's geolocation API
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      },
      (error) => {
        console.error("Error getting location:", error.message);
      }
    );
  }, []);
useEffect(() => {
    if (latitude && longitude) {
      // Fetch the address and zip code using the Google Maps Geocoding API
      const apiKey = "AIzaSyCQczb6EDbU29iO1aCLOwZVVWIyD17Yc_c";
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          if (data.results.length > 0) {
            var add=data.results[0].address_components[5].long_name+' '+data.results[0].address_components[6].long_name+' '+data.results[0].address_components[7].long_name+' '+data.results[0].address_components[8].long_name;
            setAddress(add);

            // Find the zip code in the address components
            const addressComponents = data.results[0].address_components;
            const zipCodeComponent = addressComponents.find(
              (component) => component.types.includes("postal_code")
            );
            if (zipCodeComponent) {
              // setZipcode(zipCodeComponent.long_name);
            } else {
              // setZipcode("N/A");
            }
          } else {
            setAddress("Address not found");
            // setZipcode("N/A");
          }
        })
        .catch((error) => {
          console.error("Error fetching address:", error.message);
        });
    }
  }, [latitude, longitude]);

  const handleSelect = async (address) => {
  try {
    const results = await geocodeByAddress(address);
    console.log(results);
    // Do something with the results (e.g., get latitude and longitude)
  } catch (error) {
    console.error('Error geocoding address:', error);
  }
};
 return (
        <>
{/* ========== Nevbar Content Start ========== */}
        <Row className="nvcus-main">
         <Col md={12} className="mb-2 tpnv-cntnon">
         <img src="assets/images/logo.png" className="img-fluid" alt="Logo"/>
         <div className="demo">
          <NavLink to="#" onClick={handleShow}>
          <i className="">
          <img src="assets/images/location.svg" className="img-fluid" alt="Logo"/>
          </i>
          <span className="text-white ps-1">{address}</span>
          </NavLink>
         </div>
         </Col>
         <Col md={1} xs={1} className="align-self-center bcnv-arwbtn px-0">
         <button onClick={() =>
            navigate(-1)} className="back-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
               <path d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28533 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
            </svg>
         </button>
         </Col>
         <Col md={10} xs={9} className="align-self-center">
         <div className="constrain hide-inpt">
            <input className="input-elevated" type="text" placeholder="Search here.."/>
            <h3 className="svdo">Your Dashboard !</h3>
         </div>
         <h3 className="showtxt cnttxtnev">{props.headerText}</h3>
         </Col>
         <Col md={1} xs={2} className="align-self-center nvbtn">
         <Button className="grnshw" href="{void(0)}" variant=""><img src="assets/images/merchant/svg/plus-icn.svg" className="img-fluid" alt="Logo"/></Button>{' '}
         <Button className="mdsbrdshow" href="emp-notification" variant=""><img src="assets/images/svg/notiicn.svg" className="img-fluid" alt="Logo"/><span></span></Button>{' '}
         </Col>
        </Row>
{/* ========== Popup Invitation Form Start ========== */}
         <div className="popupwrng">
           <Modal show={show} onHide={handleClose} className="popupwrngcnt">
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                 <Col md={12} xs={12} className="mb-3 sercpop">
                    <Form.Label className="fw-normal">Ex: Zipcode/City/State `<span></span></Form.Label>
                    <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect} >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <input {...getInputProps({ placeholder: 'Enter address' })} />
                          <div>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                              };
                              return (
                                <div {...getSuggestionItemProps(suggestion, { style })}>
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                 </Col>
              </Modal.Body>
              <Modal.Footer className="d-none">
                 <Button variant="secondary" onClick={handleClose}>
                 Close
                 </Button>
                 <Button variant="primary" onClick={handleClose}>
                 Save Changes
                 </Button>
              </Modal.Footer>
           </Modal>
        </div>
{/* ========== Popup Invitation Form End ========== */}
{/* ========== Nevbar Content End ========== */}
</>
  )
}
export default Navbar;