import React, { useEffect } from 'react';
import { Row, Col, Container, Nav } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import './UserOrdpymt.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar"; 
// import $ from 'jquery';
// import Footer from "../footer";
const UserOrdpymt = () => {
useEffect(() => {
    document.title = `User Order Payments | Blue Faucet`;
    document.getElementById('bodywrap').className='usrnw usordrpymtpg uf1';

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 

},[])  
    return (
        <>
        {/* ========== User Order Payment Page Start ========== */}
         <section className="gen-wrap">
         {/* ========== Navbar Start ========== */}
            <Navbar headerText={'Orders & Payments'}/>
         {/* ========== Navbar End ========== */}
           <Container>
              <Row className="mx-0 mianhmdwncnt">
                 <Col className="mnhmcnt">
                 <Col md={12} xs={12} className="py-2">
                 {/* ========== Content Box Start ========== */}
                 <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                       <Col md={12} sm={12} className="tpuspymt-tbpill px-0">
                       <Nav variant="pills" className="d-flex">
                          <Nav.Item>
                             <Nav.Link eventKey="first">Invoices</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                             <Nav.Link eventKey="second">Receipts/Refund</Nav.Link>
                          </Nav.Item>
                       </Nav>
                       </Col>
                       <Col md={12} sm={12}>
                       <Tab.Content>
                          <Tab.Pane eventKey="first">
                             <Col className="fsttb-bxcnt mb-1">
                             <NavLink to="/invoice">
                                <Row className="mx-0 fsttbcnt">
                                   <Col md={1} xs={2} className="align-self-center">
                                   <img src= {'assets/images/ordrtb-imgs.png'} className="img-fluid" alt="Sld Img"/></Col>
                                   <Col md={5} xs={5} className="align-self-center">
                                   <h3>Order Name</h3>
                                   <p>2022-09-27 18:00:00</p>
                                   </Col>
                                   <Col md={6} xs={5} className="text-end fstrgtcnt">
                                   <h5>$13.99</h5>
                                   <p></p>
                                   </Col>
                                </Row>
                             </NavLink>
                             </Col>
                             <Col className="fsttb-bxcnt mb-1">
                             <NavLink to="/invoice">
                                <Row className="mx-0 fsttbcnt">
                                   <Col md={1} xs={2} className="align-self-center">
                                   <img src= {'assets/images/ordrtb-imgs.png'} className="img-fluid" alt="Sld Img"/></Col>
                                   <Col md={5} xs={5} className="align-self-center">
                                   <h3>Order Name</h3>
                                   <p>2022-09-27 18:00:00</p>
                                   </Col>
                                   <Col md={6} xs={5} className="text-end fstrgtcnt">
                                   <h5>$1.99</h5>
                                   <p>to card On 5/13/2013</p>
                                   </Col>
                                </Row>
                             </NavLink>
                             </Col>
                             <Col className="fsttb-bxcnt mb-1">
                             <NavLink to="/invoice">
                                <Row className="mx-0 fsttbcnt">
                                   <Col md={1} xs={2} className="align-self-center">
                                   <img src= {'assets/images/ordrtb-imgs.png'} className="img-fluid" alt="Sld Img"/></Col>
                                   <Col md={5} xs={5} className="align-self-center">
                                   <h3>Order Name</h3>
                                   <p>2022-09-27 18:00:00</p>
                                   </Col>
                                   <Col md={6} xs={5} className="text-end fstrgtcnt">
                                   <h5>$4.99</h5>
                                   <p></p>
                                   </Col>
                                </Row>
                             </NavLink>
                             </Col>
                             <Col className="fsttb-bxcnt mb-1">
                             <NavLink to="/invoice">
                                <Row className="mx-0 fsttbcnt">
                                   <Col md={1} xs={2} className="align-self-center">
                                   <img src= {'assets/images/ordrtb-imgs.png'} className="img-fluid" alt="Sld Img"/></Col>
                                   <Col md={5} xs={5} className="align-self-center">
                                   <h3>Order Name</h3>
                                   <p>2022-09-27 18:00:00</p>
                                   </Col>
                                   <Col md={6} xs={5} className="text-end fstrgtcnt">
                                   <h5>$13.99</h5>
                                   </Col>
                                </Row>
                             </NavLink>
                             </Col>
                             <Col className="fsttb-bxcnt mb-1">
                             <NavLink to="/invoice">
                                <Row className="mx-0 fsttbcnt">
                                   <Col md={1} xs={2} className="align-self-center">
                                   <img src= {'assets/images/ordrtb-imgs.png'} className="img-fluid" alt="Sld Img"/></Col>
                                   <Col md={5} xs={5} className="align-self-center">
                                   <h3>Order Name</h3>
                                   <p>2022-09-27 18:00:00</p>
                                   </Col>
                                   <Col md={6} xs={5} className="text-end fstrgtcnt">
                                   <h5>$1.99</h5>
                                   <p>to card On 5/13/2013</p>
                                   </Col>
                                </Row>
                             </NavLink>
                             </Col>
                             <Col className="fsttb-bxcnt mb-1">
                             <NavLink to="/invoice">
                                <Row className="mx-0 fsttbcnt">
                                   <Col md={1} xs={2} className="align-self-center">
                                   <img src= {'assets/images/ordrtb-imgs.png'} className="img-fluid" alt="Sld Img"/></Col>
                                   <Col md={5} xs={5} className="align-self-center">
                                   <h3>Order Name</h3>
                                   <p>2022-09-27 18:00:00</p>
                                   </Col>
                                   <Col md={6} xs={5} className="text-end fstrgtcnt">
                                   <h5>$4.99</h5>
                                   </Col>
                                </Row>
                             </NavLink>
                             </Col>
                             <Col className="fsttb-bxcnt mb-1">
                             <NavLink to="/invoice">
                                <Row className="mx-0 fsttbcnt">
                                   <Col md={1} xs={2} className="align-self-center">
                                   <img src= {'assets/images/ordrtb-imgs.png'} className="img-fluid" alt="Sld Img"/></Col>
                                   <Col md={5} xs={5} className="align-self-center">
                                   <h3>Order Name</h3>
                                   <p>2022-09-27 18:00:00</p>
                                   </Col>
                                   <Col md={6} xs={5} className="align-self-center text-end fstrgtcnt">
                                   <h5>$13.99</h5>
                                   <p>to card On 5/13/2013</p>
                                   </Col>
                                </Row>
                             </NavLink>
                             </Col>
                             <Col className="fsttb-bxcnt mb-1">
                             <NavLink to="/invoice">
                                <Row className="mx-0 fsttbcnt">
                                   <Col md={1} xs={2} className="align-self-center">
                                   <img src= {'assets/images/ordrtb-imgs.png'} className="img-fluid" alt="Sld Img"/></Col>
                                   <Col md={5} xs={5} className="align-self-center">
                                   <h3>Order Name</h3>
                                   <p>2022-09-27 18:00:00</p>
                                   </Col>
                                   <Col md={6} xs={5} className="text-end fstrgtcnt">
                                   <h5>$1.99</h5>
                                   <p>to card On 5/13/2013</p>
                                   </Col>
                                </Row>
                             </NavLink>
                             </Col>
                             <Col className="fsttb-bxcnt mb-1">
                             <NavLink to="/invoice">
                                <Row className="mx-0 fsttbcnt">
                                   <Col md={1} xs={2} className="align-self-center">
                                   <img src= {'assets/images/ordrtb-imgs.png'} className="img-fluid" alt="Sld Img"/></Col>
                                   <Col md={5} xs={5} className="align-self-center">
                                   <h3>Order Name</h3>
                                   <p>2022-09-27 18:00:00</p>
                                   </Col>
                                   <Col md={6} xs={5} className="text-end fstrgtcnt">
                                   <h5>$4.99</h5>
                                   </Col>
                                </Row>
                             </NavLink>
                             </Col>
                             <Col className="fsttb-bxcnt mb-1">
                             <NavLink to="/invoice">
                                <Row className="mx-0 fsttbcnt">
                                   <Col md={1} xs={2} className="align-self-center">
                                   <img src= {'assets/images/ordrtb-imgs.png'} className="img-fluid" alt="Sld Img"/></Col>
                                   <Col md={5} xs={5} className="align-self-center">
                                   <h3>Order Name</h3>
                                   <p>2022-09-27 18:00:00</p>
                                   </Col>
                                   <Col md={6} xs={5} className="align-self-center text-end fstrgtcnt">
                                   <h5>$13.99</h5>
                                   <p> to card On 5/13/2013</p>
                                   </Col>
                                </Row>
                             </NavLink>
                             </Col>
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                             <Col className="ordrtb-bxcnt mb-1">
                             <NavLink to="/receipts">
                                <Row className="mx-0 ordrtbcnt">
                                   <Col md={2} xs={2} className="align-self-center">
                                   <img src= {'assets/images/usodpmt-img1.png'} className="img-fluid" alt="Sld Img"/></Col>
                                   <Col md={9} xs={9} className="align-self-center">
                                   <h3>Order Name</h3>
                                   <p>Ordered on 2022-09-27</p>
                                   </Col>
                                   <Col md={1} xs={1} className="align-self-center text-end">
                                   <img src= {'assets/images/svg/arw-rgt.svg'} className="img-fluid" alt="Sld Img"/></Col>
                                </Row>
                             </NavLink>
                             </Col>
                             <Col className="ordrtb-bxcnt mb-1">
                             <NavLink to="/receipts">
                                <Row className="mx-0 ordrtbcnt">
                                   <Col md={2} xs={2} className="align-self-center">
                                   <img src= {'assets/images/usodpmt-img2.png'} className="img-fluid" alt="Sld Img"/></Col>
                                   <Col md={9} xs={9} className="align-self-center">
                                   <h3>Order Name</h3>
                                   <p>Ordered on 2022-09-27</p>
                                   </Col>
                                   <Col md={1} xs={1} className="align-self-center text-end">
                                   <img src= {'assets/images/svg/arw-rgt.svg'} className="img-fluid" alt="Sld Img"/></Col>
                                </Row>
                             </NavLink>
                             </Col>
                             <Col className="ordrtb-bxcnt mb-1">
                             <NavLink to="/receipts">
                                <Row className="mx-0 ordrtbcnt">
                                   <Col md={2} xs={2} className="align-self-center">
                                   <img src= {'assets/images/usodpmt-img3.png'} className="img-fluid" alt="Sld Img"/></Col>
                                   <Col md={9} xs={9} className="align-self-center">
                                   <h3>Order Name</h3>
                                   <p>Ordered on 2022-09-27</p>
                                   </Col>
                                   <Col md={1} xs={1} className="align-self-center text-end">
                                   <img src= {'assets/images/svg/arw-rgt.svg'} className="img-fluid" alt="Sld Img"/></Col>
                                </Row>
                             </NavLink>
                             </Col>
                             <Col className="ordrtb-bxcnt mb-1">
                             <NavLink to="/receipts">
                                <Row className="mx-0 ordrtbcnt">
                                   <Col md={2} xs={2} className="align-self-center">
                                   <img src= {'assets/images/usodpmt-img4.png'} className="img-fluid" alt="Sld Img"/></Col>
                                   <Col md={9} xs={9} className="align-self-center">
                                   <h3>Order Name</h3>
                                   <p>Ordered on 2022-09-27</p>
                                   </Col>
                                   <Col md={1} xs={1} className="align-self-center text-end">
                                   <img src= {'assets/images/svg/arw-rgt.svg'} className="img-fluid" alt="Sld Img"/></Col>
                                </Row>
                             </NavLink>
                             </Col>
                             <Col className="ordrtb-bxcnt mb-1">
                             <NavLink to="/receipts">
                                <Row className="mx-0 ordrtbcnt">
                                   <Col md={2} xs={2} className="align-self-center">
                                   <img src= {'assets/images/usodpmt-img1.png'} className="img-fluid" alt="Sld Img"/></Col>
                                   <Col md={9} xs={9} className="align-self-center">
                                   <h3>Order Name</h3>
                                   <p>Ordered on 2022-09-27</p>
                                   </Col>
                                   <Col md={1} xs={1} className="align-self-center text-end">
                                   <img src= {'assets/images/svg/arw-rgt.svg'} className="img-fluid" alt="Sld Img"/></Col>
                                </Row>
                             </NavLink>
                             </Col>
                             <Col className="ordrtb-bxcnt mb-1">
                             <NavLink to="/receipts">
                                <Row className="mx-0 ordrtbcnt">
                                   <Col md={2} xs={2} className="align-self-center">
                                   <img src= {'assets/images/usodpmt-img2.png'} className="img-fluid" alt="Sld Img"/></Col>
                                   <Col md={9} xs={9} className="align-self-center">
                                   <h3>Order Name</h3>
                                   <p>Ordered on 2022-09-27</p>
                                   </Col>
                                   <Col md={1} xs={1} className="align-self-center text-end">
                                   <img src= {'assets/images/svg/arw-rgt.svg'} className="img-fluid" alt="Sld Img"/></Col>
                                </Row>
                             </NavLink>
                             </Col>
                             <Col className="ordrtb-bxcnt mb-1">
                             <NavLink to="/receipts">
                                <Row className="mx-0 ordrtbcnt">
                                   <Col md={2} xs={2} className="align-self-center">
                                   <img src= {'assets/images/usodpmt-img1.png'} className="img-fluid" alt="Sld Img"/></Col>
                                   <Col md={9} xs={9} className="align-self-center">
                                   <h3>Order Name</h3>
                                   <p>Ordered on 2022-09-27</p>
                                   </Col>
                                   <Col md={1} xs={1} className="align-self-center text-end">
                                   <img src= {'assets/images/svg/arw-rgt.svg'} className="img-fluid" alt="Sld Img"/></Col>
                                </Row>
                             </NavLink>
                             </Col>
                             <Col className="ordrtb-bxcnt mb-1">
                             <NavLink to="/receipts">
                                <Row className="mx-0 ordrtbcnt">
                                   <Col md={2} xs={2} className="align-self-center">
                                   <img src= {'assets/images/usodpmt-img2.png'} className="img-fluid" alt="Sld Img"/></Col>
                                   <Col md={9} xs={9} className="align-self-center">
                                   <h3>Order Name</h3>
                                   <p>Ordered on 2022-09-27</p>
                                   </Col>
                                   <Col md={1} xs={1} className="align-self-center text-end">
                                   <img src= {'assets/images/svg/arw-rgt.svg'} className="img-fluid" alt="Sld Img"/></Col>
                                </Row>
                             </NavLink>
                             </Col>
                             <Col className="ordrtb-bxcnt mb-1">
                             <NavLink to="/receipts">
                                <Row className="mx-0 ordrtbcnt">
                                   <Col md={2} xs={2} className="align-self-center">
                                   <img src= {'assets/images/usodpmt-img3.png'} className="img-fluid" alt="Sld Img"/></Col>
                                   <Col md={9} xs={9} className="align-self-center">
                                   <h3>Order Name</h3>
                                   <p>Ordered on 2022-09-27</p>
                                   </Col>
                                   <Col md={1} xs={1} className="align-self-center text-end">
                                   <img src= {'assets/images/svg/arw-rgt.svg'} className="img-fluid" alt="Sld Img"/></Col>
                                </Row>
                             </NavLink>
                             </Col>
                          </Tab.Pane>
                       </Tab.Content>
                       </Col>
                    </Row>
                 </Tab.Container>
                 {/* ========== Content Box End ========== */}
                 </Col>
                 </Col>
              </Row>
           </Container>
        </section>
        {/* ========== User Order Payment Page End ========== */}
        {/*<Footer />*/}
        </>
    )
}
export default UserOrdpymt;