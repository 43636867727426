import React, { useEffect } from 'react';
import { Row, Col, Container, Form } from 'react-bootstrap';
import './EmpCale.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from '@fullcalendar/timegrid'

const EmpCale = () => {
const events = [{ title: "Appointment Name here", date: new Date(),className: 'event-green'  },{ title: "Appointment Name here", date: '2023-07-13T10:00:00',className: 'event-yellow'  },{ title: "Appointment Name here", date: '2023-07-13T12:00:00',className: 'event-yellow1'  },{ title: "Appointment Name here", date: '2023-07-12T14:00:00',className: 'event-yellow2'  },{ title: "", date: '2023-07-10T14:00:00',className: 'event-light-gray'  },{ title: "", date: '2023-07-10T08:00:00',className: 'event-light-gray'  },{ title: "", date: '2023-07-12T08:00:00',className: 'event-light-gray'  },{ title: "", date: '2023-07-12T17:00:00',className: 'event-light-gray'  }];

useEffect(() => {
      document.title = `Employee Calendar | Blue Faucet`;
      document.getElementById('bodywrap').className='eclndrpgmain grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });     
},[])  
const renderDayHeader = ({ date }) => {
    const formattedDate = date.toLocaleString('default', {
      weekday: 'short',
      day: 'numeric',
    });
    let array = formattedDate.split(" ");
    
    return <span>{array[1]} <p>{array[0]}</p></span>;
  };
    return (
        <>
        {/* ========== Employee Calendar Page Start ========== */}
            <section className="gen-wrap">
        {/* ========== Navbar Start ========== */}
             <Navbar headerText={'Calender'}/>
        {/* ========== Navbar End ========== */}
             <Container>
                <Row className="mx-0 mianhmdwncnt">
                   <Col md={12} xs={12} className="mnhmcnt my-3">
                      <Col md={12} xs={12} className="clndrmaindv">
                       <Row className="justify-content-end">
                         <Col md={3} xs={3} className="drpdwncd">
                             <Form.Select aria-label="Default select example">
                              <option>Day</option>
                              <option defaultValue="1">Week</option>
                            </Form.Select>
                         </Col>
                       </Row>
        {/* ========== Payment Setup Calendar Content Start ========== */}
                        <FullCalendar
                            editable
                            selectable
                            events={events}
                            contentHeight='auto'
                            slotLabelFormat= {
                          {  hour: 'numeric'
                           }}
                            headerToolbar={{
                              start: "",
                              center: 'title',
                              right: ""
                            }}
                            plugins={[timeGridPlugin]}
                            initialView="timeGridWeek"
                            allDaySlot={false}
                            views={{
                                
                         week: {
                             titleFormat: { month: 'long',year:'numeric' },
                          },
                          
                        }}
                    dayHeaderContent={renderDayHeader}
                          />
        {/* ========== Payment Setup Calendar Content End ========== */}
                      </Col>
                   </Col>
                </Row>
             </Container>
          </section>
          <Footer/>
        {/* ========== Employee Calendar Page End ========== */}
        </>
    )
}
export default EmpCale;