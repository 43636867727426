import * as React from "react";
import { Form } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

//{/* ========== Contact Setup Start ========== */}
export const Contactsetupnew = (
  <div className="cntstp-smrypg">
    <h3>Contact Setup</h3>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>User Name</Form.Label>
        <Form.Control type="email" placeholder="ABCDEF" disabled/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Phone Number<span>*</span></Form.Label>
        <Form.Control type="tel" placeholder="123456789" disabled/>
      </Form.Group>
      <Form.Group className="mb-3 ckbox" controlId="">
          <div key="default-1" className="mb-3">
            <Form.Check type="checkbox" id="default-1" label="I agree to share with Merchants when placing orders." defaultChecked={true}/>
          </div>
      </Form.Group>
      <div>
        <p> <span className="fw-bold">Note:</span> The User Name and Phone number will be shared with Merchants when you place orders or make appointments.  If you want to change your User Name, please go to <NavLink to="/profile">Profile Setup</NavLink> to change it.</p>
      </div>
  </div>
);
//{/* ========== Contact Setup End ========== */}