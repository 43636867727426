import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Form } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './UserLogin.css';
import 'bootstrap/dist/css/bootstrap.min.css';
//import $ from 'jquery';

const UserLogin = () => {
const [values, setValues] = useState({
        password: "",
        showPassword: false,
    });

useEffect(() => {
      document.title = `User Login | Blue Faucet`;
      document.getElementById('bodywrap').className='sguppgmain';

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 

},[])  
 const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    }


    return (
        <>
          {/* ========== Login Page Start ========== */}
          <section className="gen-wrap gen-main">
            <Row className="maincnttpnw mx-0">
                <Col xs={2} md={2} className="align-self-center px-0">
                </Col>
                <Col xs={8} md={8} className="align-self-center text-center">
                  <img src="assets/images/logo.svg" className="img-fluid w-75 mb-3" alt="Logo"/>
                  <p>Your local Market place for <span className="fw-bold">Buy/Sell and Services</span></p>
                </Col>
                <Col xs={2} md={2} className="align-self-center"><p></p></Col>
              </Row>
               <Container>
                  <Row className="genscrn">
                  <Col md={12} lg={12} xl={12} xxl={12} className="toptxtcnt px-0">
                  <div className="maincntsrcn">
                     <Form>
                        <Form.Group className="mb-3">
                          <Form.Label>Email or Phone<span>*</span></Form.Label>
                          <Form.Control type="text" placeholder="Enter email or phone number" />
                        </Form.Group>
                        <Form.Group className="mb-3 pswinpt">
                         <Form.Label>Password<span>*</span></Form.Label>
                         <Form.Label className="frgtpswd"><NavLink to="/forgot-password"><p>Forgot Password?</p></NavLink></Form.Label>
                        <Form.Control type={values.showPassword ? "text" : "password"} id="password" placeholder="At least 8 characters"/>
                          {values.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword}></i>}
                        </Form.Group>
                        <Form.Group className="mb-3">
                        <Form.Check aria-label="option 1" label="Remember me" defaultChecked={true}/>
                        </Form.Group>
                        <div className="mb-3 btn-dv">
                           <NavLink to="/home"><Button variant="">Login</Button>{' '}</NavLink>
                        </div>
                        <div className="mb-3 text-center lgncnt">
                           <p>or</p>
                        <Row className="athrlgn mx-0">
                          <Col>
                          <NavLink to="#">
                           <div className="p-3 clkdv">
                            <img src="assets/images/svg/google.svg" className="img-fluid" alt="Logo"/>
                            <h5>Google</h5>
                           </div>
                          </NavLink>
                          </Col>
                          <Col>
                          <NavLink to="#">
                           <div className="p-3 clkdv">
                            <img src="assets/images/svg/facebook.svg" className="img-fluid" alt="Logo"/>
                            <h5>Facebook</h5>
                           </div>
                          </NavLink>
                          </Col>
                        </Row>
                        <div className="lgnlsttxt"><p>Don’t have an account? <NavLink to="/signup">Sign Up</NavLink></p></div>
                        </div>
                      </Form>
                      </div>
                  </Col>
                  </Row>
               </Container>
            </section>
            {/* ========== Login Page End ========== */}
        </>
    )
}

export default UserLogin;