import React, { useEffect } from 'react';
import { Row, Col, Container, Button, Form  } from 'react-bootstrap';
import './SalInvite.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from "react-router-dom";
import Navbar from "../navbar";
import Footer from "../footer";
import $ from 'jquery';

const SalInvite = () => {
useEffect(() => {
      document.title = `Sales Invite | Blue Faucet`;
      document.getElementById('bodywrap').className='sinvtmrcntpg ylwthem';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function(e) {
            $('#imagePreview').attr("src",e.target.result);
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
        }
        reader.readAsDataURL(input.files[0]);
    }
}
$("#imageUpload").change(function() {
    readURL(this);
});
},[])  
    return (
        <>
        {/* ========== Sales Invite Page Start ========== */}
          <section className="gen-main">
        {/* ========== Navbar Start ========== */}
             <Navbar headerText={'Invite Merchant'}/>
        {/* ========== Navbar End ========== */}
               <Container>
                <Row md={12} className="genscrnylwtm">
                  <Col>
        {/* ========== Sales Invite Content Start ========== */}
                     <Row md={12} className="maincntsrnylwtm">
                      <Col className="px-0">
                      <Col className="prflupld">
                        <div className="avatar-upload">
                          <div className="avatar-edit">
                              <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg"/>
                              <label htmlFor="imageUpload"></label>
                          </div>
                          <div className="avatar-preview">
                            <div ><img src="assets/images/usr-img.png" id="imagePreview" className="img-fluid" alt="Logo"/></div>
                          </div>
                          </div>
                      </Col>
                         <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Merchant Name<span></span></Form.Label>
                          <Form.Control type="text" placeholder="Enter merchant name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Owner Name<span></span></Form.Label>
                          <Form.Control type="text" placeholder="Enter owner name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Email<span></span></Form.Label>
                          <Form.Control type="text" placeholder="Enter the merchant’s email address" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Phone Number<span></span></Form.Label>
                          <Form.Control type="text fillup" placeholder="+1 984 236 564 0" />
                        </Form.Group>
                        <Col md={12} xs={12} className="invtn-ltr">
                          <Form.Label>Invitation Letter</Form.Label><Form.Label className="copylink">Copy Link</Form.Label>
                          {/*<Form.Control as="textarea" rows={3} placeholder=""/>*/}
                          <div className="invtltr-bdy">
                            <p className="mb-3">Hello,</p>  
                            <p className="mb-3">I hope this message finds you well. My name is [Your Sales Username], and I'm not only a loyal customer of your store but also someone who believes in helping businesses thrive. I've frequented your store on numerous occasions, yet I understand that it's challenging to keep track of individual customers' preferences and purchase history.</p>
                            <p className="mb-3">It's a missed opportunity when potential customers like me walk by your store with tempting promotions but end up purchasing elsewhere due to a lack of personalized engagement. To bridge this gap and ensure that valuable customers don't slip through the cracks, I'd like to introduce you to a powerful solution: BlueFaucet.</p>
                            <p className="mb-3">BlueFaucet is a customer engagement platform designed to help merchants like you build meaningful relationships with customers. By using BlueFaucet, you can effortlessly create and manage memberships, stay in touch with customers, and keep them informed about promotions, special offers, and more.</p>
                            <p className="mb-3">Here's the best part: you can get started with a 30-day free trial. Plus, if your membership count remains under 50, your account remains free of charge. It's a risk-free opportunity to unlock the potential of personalized customer engagement and drive more business.</p>
                            <p className="mb-3">To take the first step towards transforming your customer interactions, simply click the link below to sign up for BlueFaucet:</p>
                            <p className="mb-3">[BlueFaucet Sign-Up Link]</p>
                            <p className="mb-3">Once your BlueFaucet account is up and running, I'd be thrilled to become a part of your store's membership. Imagine the possibilities of creating a more tailored shopping experience that not only benefits me but also enhances your business growth.</p>
                            <p className="mb-3">Should you have any questions or need assistance during the sign-up process, feel free to reach out to the BlueFaucet support team.</p>
                            <p className="mb-3">Thank you for considering this innovative approach to customer engagement. I look forward to seeing your business flourish even more.</p>
                            <p className="mb-3">Best regards,</p>
                            <p className="mb-0">[Your Sales Username]</p>
                          </div>
                        </Col>
                        <Row>
                          <Col className="btn-clr d-none">
                           <NavLink to="#"><Button variant="">Clear</Button>{' '}</NavLink>
                          </Col>
                          <Col className="btn-dv mt-3">
                           <NavLink to="/sales-dashboard"><Button variant="">Invite Merchant</Button>{' '}</NavLink>
                          </Col>
                        </Row>
                      </Form>
                      </Col>
                     </Row>
        {/* ========== Sales Invite Content End ========== */}
                  </Col>
                </Row>
               </Container>
          </section>
          <Footer/>
        {/* ========== Sales Invite Page End ========== */}
        </>
    )
}
export default SalInvite;