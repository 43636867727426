import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Form  } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './UserPrfl.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';

const UserPrfl = () => {
const navigate = useNavigate();
const [values, setValues] = useState({
        password: "",
        showPassword: false,
    });
const [values1, setValues1] = useState({
        password: "",
        showPassword: false,
    });
useEffect(() => {
      document.title = `User Profile | Blue Faucet`;
      document.getElementById('bodywrap').className='userpgmain user-icn';

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 

function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function(e) {
            $('#imagePreview').attr("src",e.target.result);
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
        }
        reader.readAsDataURL(input.files[0]);
    }
}
$("#imageUpload").change(function() {
    readURL(this);
});

},[])  
 const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    }
     const handleClickShowPassword1 = () => {
        setValues1({ ...values1, showPassword: !values1.showPassword });
    }


    return (
        <>
        {/* ========== User Profile Page Start ========== */}
          <section className="gen-main">
              <Row className="maincnttpnw mx-0">
                <Col xs={2} md={2} className="align-self-center px-0">
                <button onClick={() => navigate(-1)} className="back-btn">
                  <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
                   </svg>
                </button>
                </Col>
                <Col xs={8} md={8} className="align-self-center text-center"><h3>My Profile</h3></Col>
                <Col xs={2} md={2} className="align-self-center"><p></p></Col>
              </Row>
               <Container>
                <Row md={12} className="genscrn">
                  <Col>
                     <Row md={12} className="maincntsrcn">
                      <Col className="px-0">
                      <Col className="prflupld">
                        <div className="avatar-upload">
                          <div className="avatar-edit">
                              <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg"/>
                              <label htmlFor="imageUpload"></label>
                          </div>
                          <div className="avatar-preview">
                            <div ><img src="assets/images/usr-img.png" id="imagePreview" className="img-fluid" alt="Logo"/></div>
                          </div>
                          </div>
                      </Col>
                         <Form>
                        <Form.Group className="mb-3">
                          <Form.Label>Your Name<span></span></Form.Label>
                          <Form.Control type="text" placeholder="Enter your name" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                        <Form.Label>Gender<span></span></Form.Label>
                        <Form.Select aria-label="Default select example">
                          <option>Select Gender</option>
                          <option defaultValue="1">Male</option>
                          <option defaultValue="2">Female</option>
                          <option defaultValue="3">Other</option>
                        </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Phone Number<span></span></Form.Label>
                          <Form.Control type="tel" placeholder="+1 0000000000" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Date of Birth<span></span></Form.Label>
                          <Form.Control type="date" placeholder="dd/mm/yy" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Address<span></span></Form.Label>
                          <Row className="">
                            <Col md={3} xs={3} className="align-self-center"><Form.Label className="fw-normal">Address Title<span></span></Form.Label></Col>
                            <Col md={9} xs={9} className="align-self-center"><Form.Control className="mb-2" type="text" placeholder="ex Home " /></Col>
                          </Row>
                          <Form.Control className="mb-2" type="location" placeholder="Name and street name" />
                          <Form.Select className="mb-2" aria-label="Default select example">
                          <option>Select State</option>
                          <option defaultValue="1">option 1</option>
                          <option defaultValue="2">option 2</option>
                          <option defaultValue="3">option 3</option>
                        </Form.Select>
                          <Form.Select className="mb-2" aria-label="Default select example">
                          <option>Select City</option>
                          <option defaultValue="1">option 1</option>
                          <option defaultValue="2">option 2</option>
                          <option defaultValue="3">option 3</option>
                        </Form.Select>
                        <Form.Group>
                          <Form.Control placeholder="Zip code"/>
                        </Form.Group>
                        </Form.Group>
                        <Row className="mb-3 mx-0 justify-content-end adrs-add-btn">
                          <Col xs="6" md="4">
                            <NavLink to="#"><Button variant="primary" type="button">Add Address</Button> </NavLink>
                          </Col>
                        </Row>
                        <Form.Group className="mb-3">
                          <Form.Label>User Name</Form.Label>
                          <Form.Control type="text" placeholder="Enter user name" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Email</Form.Label>
                          <Form.Control type="text" placeholder="Enter your email address" />
                        </Form.Group>
                        <Form.Group className="mb-3 pswinpt">
                         <Form.Label>Password<span></span></Form.Label>
                        <Form.Control type={values.showPassword ? "text" : "password"} id="enpswd" placeholder="At least 8 characters"/>
                          {values.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword}></i>}
                        </Form.Group>
                        <Form.Group className="mb-3 pswinpt">
                         <Form.Label>Re-enter Password<span></span></Form.Label>
                        <Form.Control type={values1.showPassword ? "text" : "password"} id="renpswd" placeholder="At least 8 characters"/>
                          {values1.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword1}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword1}></i>}
                        </Form.Group>
                        <Row>
                          <Col className="btn-dv">
                           <NavLink to="/home"><Button variant="">Save</Button>{' '}</NavLink>
                          </Col>
                          <div className="mt-3 mb-3 btn-dv"><NavLink to="/payment"><button type="button" className="btn">Payment Setup</button></NavLink></div>                          
                        </Row>
                      </Form>
                      </Col>
                     </Row>
                  </Col>
            
                </Row>
               </Container>
          </section>
          {/* ========== User Profile Page En ========== */}
        </>
    )
}
export default UserPrfl;