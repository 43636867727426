import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Modal } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './SalNoti.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
// import $ from 'jquery';

const SalNoti = () => {
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

useEffect(() => {
      document.title = `Sales Notifications | Blue Faucet`;
      document.getElementById('bodywrap').className='snotipgmain ylwthem';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });    
},[])  
    return (
        <>
         {/* ========== User Notification Page Start ========== */}
         <section className="gen-wrap">
         {/* ========== Navbar Start ========== */}
               <Navbar headerText={'Notifications'}/>
         {/* ========== Navbar End ========== */}
           <Container>
              <Row className="mx-0 mianhmdwncnt">
                 <Col className="mnhmcnt">
         {/* ========== Notification Content Box Start ========== */}
                 <Col md={12} xs={12} className="py-3">
                 <Row className="mx-0">
                    <Col md={12} xs={12} className="ntfctn-bxcnt px-1">
                    <Row className="mx-0 fsttbcnt">
                       <Col md={1} xs={2} className="align-self-center imgbx">
                       <img src= {'assets/images/ntfctn-icn.png'} className="img-fluid" alt="Sld Img"/></Col>
                       <Col md={10} xs={9} className="align-self-center">
                       <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</h3>
                       <p>5 Min Ago</p>
                       </Col>
                       <Col md={1} xs={1} className="align-self-end text-end">
                       <NavLink to="#" onClick={handleShow}>
                          <img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
                       </NavLink>
                       </Col>
                    </Row>
                    </Col>
                    <Col md={12} xs={12} className="ntfctn-bxcnt px-1">
                    <Row className="mx-0 fsttbcnt">
                       <Col md={1} xs={2} className="align-self-center imgbx">
                       <img src= {'assets/images/ntfctn-icn.png'} className="img-fluid" alt="Sld Img"/></Col>
                       <Col md={10} xs={9} className="align-self-center">
                       <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</h3>
                       <p>5 Min Ago</p>
                       </Col>
                       <Col md={1} xs={1} className="align-self-end text-end">
                       <NavLink to="#" onClick={handleShow}>
                          <img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
                       </NavLink>
                       </Col>
                    </Row>
                    </Col>
                    <Col md={12} xs={12} className="ntfctn-bxcnt px-1">
                    <Row className="mx-0 fsttbcnt">
                       <Col md={1} xs={2} className="align-self-center imgbx">
                       <img src= {'assets/images/ntfctn-icn.png'} className="img-fluid" alt="Sld Img"/></Col>
                       <Col md={10} xs={9} className="align-self-center">
                       <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</h3>
                       <p>5 Min Ago</p>
                       </Col>
                       <Col md={1} xs={1} className="align-self-end text-end">
                       <NavLink to="#" onClick={handleShow}>
                          <img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
                       </NavLink>
                       </Col>
                    </Row>
                    </Col>
                    <Col md={12} xs={12} className="ntfctn-bxcnt px-1">
                    <Row className="mx-0 fsttbcnt">
                       <Col md={1} xs={2} className="align-self-center imgbx">
                       <img src= {'assets/images/ntfctn-icn.png'} className="img-fluid" alt="Sld Img"/></Col>
                       <Col md={10} xs={9} className="align-self-center">
                       <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</h3>
                       <p>5 Min Ago</p>
                       </Col>
                       <Col md={1} xs={1} className="align-self-end text-end">
                       <NavLink to="#" onClick={handleShow}>
                          <img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
                       </NavLink>
                       </Col>
                    </Row>
                    </Col>
                    <Col md={12} xs={12} className="ntfctn-bxcnt px-1">
                    <Row className="mx-0 fsttbcnt">
                       <Col md={1} xs={2} className="align-self-center imgbx">
                       <img src= {'assets/images/ntfctn-icn.png'} className="img-fluid" alt="Sld Img"/></Col>
                       <Col md={10} xs={9} className="align-self-center">
                       <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</h3>
                       <p>5 Min Ago</p>
                       </Col>
                       <Col md={1} xs={1} className="align-self-end text-end">
                       <NavLink to="#" onClick={handleShow}>
                          <img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
                       </NavLink>
                       </Col>
                    </Row>
                    </Col>
                    <Col md={12} xs={12} className="ntfctn-bxcnt px-1">
                    <Row className="mx-0 fsttbcnt">
                       <Col md={1} xs={2} className="align-self-center imgbx">
                       <img src= {'assets/images/ntfctn-icn.png'} className="img-fluid" alt="Sld Img"/></Col>
                       <Col md={10} xs={9} className="align-self-center">
                       <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</h3>
                       <p>5 Min Ago</p>
                       </Col>
                       <Col md={1} xs={1} className="align-self-end text-end">
                       <NavLink to="#" onClick={handleShow}>
                          <img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
                       </NavLink>
                       </Col>
                    </Row>
                    </Col>
                    <Col md={12} xs={12} className="ntfctn-bxcnt px-1">
                    <Row className="mx-0 fsttbcnt">
                       <Col md={1} xs={2} className="align-self-center imgbx">
                       <img src= {'assets/images/ntfctn-icn.png'} className="img-fluid" alt="Sld Img"/></Col>
                       <Col md={10} xs={9} className="align-self-center">
                       <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</h3>
                       <p>5 Min Ago</p>
                       </Col>
                       <Col md={1} xs={1} className="align-self-end text-end">
                       <NavLink to="#" onClick={handleShow}>
                          <img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
                       </NavLink>
                       </Col>
                    </Row>
                    </Col>
                    <Col md={12} xs={12} className="ntfctn-bxcnt px-1">
                    <Row className="mx-0 fsttbcnt">
                       <Col md={1} xs={2} className="align-self-center imgbx">
                       <img src= {'assets/images/ntfctn-icn.png'} className="img-fluid" alt="Sld Img"/></Col>
                       <Col md={10} xs={9} className="align-self-center">
                       <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</h3>
                       <p>5 Min Ago</p>
                       </Col>
                       <Col md={1} xs={1} className="align-self-end text-end">
                       <NavLink to="#" onClick={handleShow}>
                          <img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
                       </NavLink>
                       </Col>
                    </Row>
                    </Col>
                    <Col md={12} xs={12} className="ntfctn-bxcnt px-1">
                    <Row className="mx-0 fsttbcnt">
                       <Col md={1} xs={2} className="align-self-center imgbx">
                       <img src= {'assets/images/ntfctn-icn.png'} className="img-fluid" alt="Sld Img"/></Col>
                       <Col md={10} xs={9} className="align-self-center">
                       <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</h3>
                       <p>5 Min Ago</p>
                       </Col>
                       <Col md={1} xs={1} className="align-self-end text-end">
                       <NavLink to="#" onClick={handleShow}>
                          <img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
                       </NavLink>
                       </Col>
                    </Row>
                    </Col>
                    <Col md={12} xs={12} className="ntfctn-bxcnt px-1">
                    <Row className="mx-0 fsttbcnt">
                       <Col md={1} xs={2} className="align-self-center imgbx">
                       <img src= {'assets/images/ntfctn-icn.png'} className="img-fluid" alt="Sld Img"/></Col>
                       <Col md={10} xs={9} className="align-self-center">
                       <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</h3>
                       <p>5 Min Ago</p>
                       </Col>
                       <Col md={1} xs={1} className="align-self-end text-end">
                       <NavLink to="#" onClick={handleShow}>
                          <img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
                       </NavLink>
                       </Col>
                    </Row>
                    </Col>
                 </Row>
                 </Col>
         {/* ========== Notification Content Box End ========== */}
                 <Col className="md={12} xs={12}">
                 <div className="inmodal">
                 {/* ========== Delete Popup Start ========== */}
                    <Modal show={show} onHide={handleClose} className="popupwrngcnt">
                       <Modal.Header closeButton>
                          <h3 className="mdlttltxt">Are you sure you want to delete?</h3>
                       </Modal.Header>
                       <Modal.Body>
                          <Row>
                             <Col className="btn-clr">
                             <NavLink to="#" onClick={handleClose}><Button variant="">Yes</Button></NavLink>
                             </Col>
                             <Col className="btn-dv">
                             <NavLink to="#" onClick={handleClose}><Button variant="">No</Button></NavLink>
                             </Col>
                          </Row>
                       </Modal.Body>
                       <Modal.Footer className="d-none"></Modal.Footer>
                    </Modal>
         {/* ========== Delete Popup End ========== */}
                 </div>
                 </Col>
                 </Col>
              </Row>
           </Container>
        </section>
        {/* ========== User Notification Page End ========== */}
        </>
    )
}
export default SalNoti;