import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Form, Modal } from 'react-bootstrap';
import './SalPerson.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, NavLink } from 'react-router-dom';
import $ from 'jquery';

const SalPerson = () => {
const navigate = useNavigate();

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

useEffect(() => {
      document.title = `Sales Personal Information Setup | Blue Faucet`;
      document.getElementById('bodywrap').className=' ylwthem';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function(e) {
            $('#imagePreview').attr("src",e.target.result);
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
        }
        reader.readAsDataURL(input.files[0]);
    }
}
$("#imageUpload").change(function() {
    readURL(this);
});

},[])  
    return (
        <>
         {/* ========== Sales Personal Information Setup Page Start ========== */}
          <section className="gen-main">
         {/* ========== Navbar Start ========== */}
             <Row className="maincntylwtm mx-0">
                <Col xs={1} md={1} className="align-self-center px-0">
                <button onClick={() =>
                   navigate(-1)} className="back-btn d-none">
                   <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
                   </svg>
                </button>
                </Col>
                <Col xs={10} md={10} className="align-self-center text-center">
                <h3>Sales Information Setup</h3>
                </Col>
                <Col xs={1} md={1} className="align-self-center">
                <p></p>
                </Col>
             </Row>
         {/* ========== Navbar End ========== */}
             <Container>
                <Row md={12} className="genscrnylwtm">
                   <Col>
         {/* ========== Sales Personal Information Setup Content Start ========== */}
                   <Row md={12} className="maincntsrnylwtm">
                      <Col className="px-0">
                      <Col className="prflupld">
                      <div className="avatar-upload">
                         <div className="avatar-edit">
                            <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg"/>
                            <label htmlFor="imageUpload"></label>
                         </div>
                         <div className="avatar-preview">
                            <div ><img src="assets/images/usr-img.png" id="imagePreview" className="img-fluid" alt="Logo"/></div>
                         </div>
                      </div>
                      </Col>
                      <Form>
                         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Your Name<span></span></Form.Label>
                            <Form.Control type="text" placeholder="john" disabled/>
                         </Form.Group>
                         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Gender<span></span></Form.Label>
                            <Form.Select aria-label="Default select example">
                               <option>Select Gender</option>
                               <option defaultValue="1">Male</option>
                               <option defaultValue="2">Female</option>
                               <option defaultValue="3">Other</option>
                            </Form.Select>
                         </Form.Group>
                         <Row className="sprsn-btnfil my-3">
                            <Col className="btn-lft">
                            <NavLink to="#" onClick={handleShow}><Button variant=""><img src= {'assets/images/sales/svg/plusfil-icn.svg'} className="img-fluid pe-2" alt="Btn Img"/>Fill-up W-9 Form</Button>{' '}</NavLink>
                            </Col>
                            <Col className="btn-rgt">
                            <NavLink to="#"><Button variant="">Download W-9 Form</Button>{' '}</NavLink>
                            </Col>
                         </Row>
                         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Phone Number<span></span></Form.Label>
                            <Form.Control type="tel" placeholder="+1 9876543210" disabled/>
                         </Form.Group>
                         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Date of Birth<span></span></Form.Label>
                            <Form.Control type="date" placeholder="dd/mm/yy" disabled/>
                         </Form.Group>
                         <Form.Group className="mb-3">
                          <Form.Label>Address<span></span></Form.Label>
                          <Row className="">
                            <Col md={3} xs={3} className="align-self-center"><Form.Label className="fw-normal">Address Title<span></span></Form.Label></Col>
                            <Col md={9} xs={9} className="align-self-center"><Form.Control className="mb-2" type="text" placeholder="ex Home " disabled /></Col>
                          </Row>
                          <Form.Control className="mb-2" type="location" placeholder="Name and street name" disabled/>
                          <Form.Select className="mb-2" aria-label="Default select example" disabled>
                             <option>Select State</option>
                             <option defaultValue="1">option 1</option>
                             <option defaultValue="2">option 2</option>
                             <option defaultValue="3">option 3</option>
                           </Form.Select>
                           <Form.Select className="mb-2" aria-label="Default select example" disabled>
                             <option>Select City</option>
                             <option defaultValue="1">option 1</option>
                             <option defaultValue="2">option 2</option>
                             <option defaultValue="3">option 3</option>
                           </Form.Select>
                          <Form.Group>
                          <Form.Control placeholder="Zip code" disabled/>
                        </Form.Group>
                        </Form.Group>
                         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>User Name</Form.Label>
                            <Form.Control type="text" placeholder="john" disabled/>
                         </Form.Group>
                         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" placeholder="admin@gmail.com" disabled/>
                         </Form.Group>
                         <Form.Group className="mb-3 ckbox" controlId="exampleForm.ControlInput1">
                            <Form.Check aria-label="option 1" defaultChecked={true}/>
                            <Form.Label>I agree With <NavLink to="/sales-signup">Bluefaucet Sales Agreement</NavLink></Form.Label>
                         </Form.Group>
                         <Row>
                            <Col className="btn-clr">
                            <NavLink to="#"><Button variant="">Clear</Button>{' '}</NavLink>
                            </Col>
                            <Col className="btn-dv">
                            <NavLink to="/sales-profile"><Button variant="">Next</Button>{' '}</NavLink>
                            </Col>
                         </Row>
                      </Form>
                      </Col>
                   </Row>
         {/* ========== Sales Personal Information Setup Content End ========== */}
                   </Col>
                </Row>
             </Container>
             <Col>
             <div className="sprsnlsetuppop">.
         {/* ========== Sales Personal Information Setup W-9 Form Popup Start ========== */}
                <Modal show={show} onHide={handleClose} className="popupwrngcnt">
                   <Modal.Header closeButton>
                      <h3 className="sprslstp-tptxt">W-9 Form</h3>
                   </Modal.Header>
                   <Modal.Body>
                      <Col md={12} xs={12} className="sprsnlstppopcnt">
                      <p className="fw-bold">(Rev. October 2018)</p>
                      <p>Department of the Treasury Internal Revenue Service</p>
                      <h3>Request for Taxpayer Identification Number and Certification</h3>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-normal">1.Name (as shown on your income tax return). Name is required on this line; do not leave this line blank.<span></span></Form.Label>
                         <Form.Control type="text" placeholder="Enter your name" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-normal">2.Business name/disregarded entity name, if different from above<span></span></Form.Label>
                         <Form.Control type="text" placeholder="Business Name" />
                      </Form.Group>
                      <Form.Group className="mb-3 prsnckbx" controlId="exampleForm.ControlInput1">
                         <Form.Label>3.Check appro priate box for federal tax classification of the person whose name is entered on line 1. Check only one of the following seven boxes.<span></span></Form.Label>
                         <Form.Check type="radio" label="Individual/sole proprietor or single-member LLC" name="formHorizontalRadiosmsctp1" id="formHorizontalRadiosmsctp1"/>
                         <Form.Check type="radio" label="All" name="formHorizontalRadiosmsctp1" id="formHorizontalRadiosmsctp1"/>
                         <Form.Check type="radio" label="C Corporation"  name="formHorizontalRadiosmsctp1" id="formHorizontalRadiosmsctp1" defaultChecked={true}/>
                         <Form.Check type="radio" label="S Corporation" name="formHorizontalRadiosmsctp1" id="formHorizontalRadiosmsctp1"/>
                         <Form.Check type="radio" label="Partnership" name="formHorizontalRadiosmsctp1" id="formHorizontalRadiosmsctp1"/>
                         <Form.Check type="radio" label="Trust/estate" name="formHorizontalRadiosmsctp1" id="formHorizontalRadiosmsctp1"/>
                         <Form.Check type="radio" label="Limited liability company. Enter the tax classification (C=C corporation, S=S corporation, P=Partnership)" name="formHorizontalRadiosmsctp1" id="formHorizontalRadiosmsctp1"/>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label><span></span></Form.Label>
                         <Form.Control type="text" placeholder="" />
                      </Form.Group>
                      <div className="msgnote">
                         <p>Note: Check the appropriate box in the line above for the tax classification of the single-member owner. Do not check LLC if the LLC is classified as a single-member LLC that is disregarded from the owner unless the owner of the LLC is another LLC that is not disregarded from the owner for U.S. federal tax purposes. Otherwise, a single-member LLC that is disregarded from the owner should check the appropriate box for the tax classification of its owner.</p>
                      </div>
                      <Form.Group className="mb-3 prsnckbx" controlId="exampleForm.ControlInput1">
                         <Form.Check type="radio" label="Other (see instructions)" name="formHorizontalRadiosmsctp1" id="formHorizontalRadiosmsctp1"/>
                      </Form.Group>
                      <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                         <Form.Control type="text" placeholder="Business Name" />
                         <Form.Label className="fw-normal mt-2">4.Exemptions (codes apply only to certain entities, not individuals; see instructions on page 3):<span></span></Form.Label>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-normal">Exempt payee code (if any)<span></span></Form.Label>
                         <Form.Control type="text" placeholder="" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-normal">5.Address (number, street, and apt. or suite no.) See instructions.<span></span></Form.Label>
                         <Form.Control type="text" placeholder="" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-normal">6.City, state, and ZIP code<span></span></Form.Label>
                         <Form.Control type="text" placeholder="" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-normal">7.List account number(s) here (optional)<span></span></Form.Label>
                         <Form.Control type="text" placeholder="" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-normal">Requester’s name and address (optional)<span></span></Form.Label>
                         <Form.Control as="textarea" rows={3} />
                      </Form.Group>
                      <div className="prt-1">
                         <h4>Part I</h4>
                         <h6>Taxpayer Identification Number (TIN)</h6>
                         <p>Enter your TIN in the appropriate box. The TIN provided must match the name given on line 1 to avoid backup withholding. For individuals, this is generally your social security number (SSN). However, for a resident alien, sole proprietor, or disregarded entity, see the instructions for Part I, later. For other entities, it is your employer identification number (EIN). If you do not have a number, see How to get a TIN, later.</p>
                         <div className="msgnote my-3">
                            <p>Note: If the account is in more than one name, see the instructions for line 1. Also see What Name and Number To Give the Requester for guidelines on whose number to enter.</p>
                         </div>
                         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className="fw-normal">Social security number<span></span></Form.Label>
                            <Form.Control type="text" placeholder="" />
                         </Form.Group>
                         <div className="">add</div>
                         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className="fw-normal">Employer identification number<span></span></Form.Label>
                            <Form.Control type="text" placeholder="" />
                         </Form.Group>
                      </div>
                      <div className="prt-2">
                         <h4>Part II</h4>
                         <h6>Certification</h6>
                         <p>Under penalties of perjury, I certify that:</p>
                         <ul>
                            <li>The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me); and</li>
                            <li>I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and</li>
                            <li>I am a U.S. citizen or other U.S. person (defined below); and</li>
                            <li>The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct.</li>
                         </ul>
                         <h5>Certification instructions</h5>
                         <p>You must cross out item 2 above if you have been notified by the IRS that you are currently subject to backup withholding because you have failed to report all interest and dividends on your tax return. For real estate transactions, item 2 does not apply. For mortgage interest paid, acquisition or abandonment of secured property, cancellation of debt, contributions to an individual retirement arrangement (IRA), and generally, payments other than interest and dividends, you are not required to sign the certification, but you must provide your correct TIN. See the instructions for Part II, later.</p>
                         <h5>Sign Here</h5>
                         <Row>
                            <Col>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                               <Form.Label className="fw-normal">Signature of U.S. person<span></span></Form.Label>
                               <Form.Control type="file" placeholder="" />
                            </Form.Group>
                            </Col>
                            <Col>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                               <Form.Label className="fw-normal">Date<span></span></Form.Label>
                               <Form.Control type="date" placeholder="" />
                            </Form.Group>
                            </Col>
                         </Row>
                      </div>
                      </Col>
                      <Row className="my-3 ">
                         <Col className="popprflstplstbtn">
                         <NavLink to="#" onClick={() => handleClose()}><Button variant="lft">Submit</Button>{' '}</NavLink>
                         </Col>
                      </Row>
                   </Modal.Body>
                   <Modal.Footer className="d-none"></Modal.Footer>
                </Modal>
         {/* ========== Sales Personal Information Setup W-9 Form Popup End ========== */}
             </div>
             </Col>
          </section>
         {/* ========== Sales Personal Information Setup Page End ========== */}
        </>
    )
}
export default SalPerson;