import React, { useEffect } from 'react';
import { Row, Col, Container, Button, Table, Stack } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './EmpReceipts.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
// import $ from 'jquery';

const EmpReceipts = () => {
const navigate = useNavigate();   
useEffect(() => {
      document.title = `Employee Receipts | Blue Faucet`;
      document.getElementById('bodywrap').className='usrrcptcpgmain grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
},[])  
    return (
        <>
         {/* ========== Employee Receipts Page Start ========== */}
         <section className="gen-main">
         {/* ========== Navbar Start ========== */}
            <Row className="maincntmrcnt mx-0">
               <Col xs={2} md={2} className="align-self-center px-0">
               <button onClick={() =>
                  navigate(-1)} className="back-btn">
                  <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
                  </svg>
               </button>
               </Col>
               <Col xs={8} md={8} className="align-self-center text-center">
               <h3>Receipts</h3>
               </Col>
               <Col xs={2} md={2} className="align-self-center">
               <p></p>
               </Col>
            </Row>   
         {/* ========== Navbar End ========== */}
         <Container>
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
         {/* ========== Employee Receipts Content Start ========== */}
               <Col md={12} xs={12} className="py-2">
               <Row className="mx-0 maincnt-recepts">
                  <Col md={8} xs={8}>
                  <h3>Zylker Dezigns</h3>
                  <p>4606 Benson Park Drive Rosedale, U.S.A</p>
                  <p>Phone: 555-2938</p>
                  <div className="dwncmt">
                     <h4>Serviced by: <span>xxxxxxxxxxxx</span></h4>
                     <h5>Information:</h5>
                     <h4>Invoice No.<span>#-0023</span></h4>
                     <h4>Invoice Date: <span>08May 2023</span></h4>
                  </div>
                  </Col>
                  <Col md={4} xs={4} className="text-end left-cnt-rcpt">
                  <img src="assets/images/invicepgicn.png" className="img-fluid ps-2" alt="Hm Img"/>
                  <h4>Due Date:<span>20May 2023</span></h4>
                  </Col>
                  <Col md={12} xs={12} className="tblinvcpg">
                  <Table responsive>
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Service/Items</th>
                           <th>Price</th>
                           <th>Q’ty</th>
                           <th>Amount</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>1</td>
                           <td>Nivea Shampoo</td>
                           <td>$49</td>
                           <td>1</td>
                           <td>$49</td>
                        </tr>
                        <tr>
                           <td>2</td>
                           <td>Nivea Shampoo</td>
                           <td>$49</td>
                           <td>1</td>
                           <td>$49</td>
                        </tr>
                     </tbody>
                  </Table>
                  <div className="text-end tbldwndv">
                     <Stack direction="horizontal" gap={4}>
                        <div className="pb-1 ms-auto">
                           <p>SubTotal</p>
                        </div>
                        <div className="pb-1"><span>$99</span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={4}>
                        <div className="pb-1 ms-auto">
                           <p>Tax(10.25%)</p>
                        </div>
                        <div className="pb-1"><span>$10</span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={4}>
                        <div className="pb-1 ms-auto">
                           <p>Coupon/Discount Code</p>
                        </div>
                        <div className="pb-1">-<span>$xx</span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={4}>
                        <div className="pb-1 ms-auto">
                           <p>Total</p>
                        </div>
                        <div className="pb-1"><span>$99</span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={4}>
                        <div className="pb-1 pe-1 ms-auto">
                           <p>Add Tips</p>
                        </div>
                        <div className="pb-1 ps-3"><span> - </span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={4} className="lstprcdv">
                        <div className=" ms-auto">
                           <h6>Total Balance</h6>
                        </div>
                        <div className="">
                           <h6>$99</h6>
                        </div>
                     </Stack>
                  </div>
                  </Col>
                  <Col md={6} xs={7} className="dwn-cntrecpt">
                  <h3>Transaction Records</h3>
                  <p>Account Name: ABC Bank</p>
                  <p>Account Number: xxxxxx123</p>
                  <p>Expires xx/xx Chip: 12345</p>
                  <img src="assets/images/svg/sing.svg" className="img-fluid ps-2" alt="Hm Img"/>
                  </Col>
                  <Row className="mx-0 btnlst-usrrcpt">
                     <Col md={6} xs={6} className="px-0">
                     <NavLink to="#"><Button variant="" type="submit">Approved</Button></NavLink>
                     </Col>
                     <Col md={6} xs={6} className="px-0 text-end">
                     <NavLink to="#">Refund Policy</NavLink>
                     </Col>
                  </Row>
               </Row>
               <Col className="rcptbtn mt-3 d-none"><NavLink to="#"><Button variant="" type="submit"> Apply For Refund</Button></NavLink></Col>
               </Col>
      {/* ========== Employee Receipts Content Start ========== */}
               </Col>
            </Row>
         </Container>
      </section>
      {/* ========== Employee Receipts Page End ========== */}
        </>
    )
}
export default EmpReceipts;