import React, { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './EmpDash.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
import { NavLink } from "react-router-dom";
//import $ from 'jquery';

const EmpDash = () => {
useEffect(() => {
      document.title = `Employee Dashbord | Blue Faucet`;
      document.getElementById('bodywrap').className='Homepgmain mdsbrdpgmain edsbord eds grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });       
},[])  
    return (
        <>
        {/* ========== Employee Dashbord Page Start ========== */}
            <section className="gen-wrap">
        {/* ========== Navbar Start ========== */}
              <Navbar/>
        {/* ========== Navbar End ========== */}
               <Container>
                  <Row className="mx-0 mianhmdwncnt">
                   <Col className="mnhmcnt">
        {/* ========== Employee Dashbord Content Start ========== */}
                    <NavLink to="/emp-profile">
                     <Row className="mx-0 edsbrdtp">
                       <Col md={2} xs={2} className="img-cnt"><img src= {'assets/images/employee/usr-icn.png'} className="img-fluid" alt="Mcust Img"/></Col>
                       <Col md={8} xs={8} className="text-cnt">
                         <h3>Employee Name</h3>
                         <p>lorem.ipsum@gmail.com</p>
                       </Col>
                       <Col md={2} xs={2} className="edt-icn text-end"><img src= {'assets/images/employee/edt-icn.png'} className="img-fluid" alt="Mcust Img"/></Col>
                     </Row>
                    </NavLink>
        {/* ========== Employee Dashbord 4 box Content Start ========== */}
                   <Row xs={{ cols:2, gutter: 2 }} lg={{ cols: 2, gutter: 3}} className="mt-2">
                      <Col>
                       <NavLink to="/emp-order">
                        <div className="p-3 maindvmdsbrd">
                          <Row className="rw mx-0">
                            <Col md={3} xs={3} className="mdtp-lft text-center"><img src="assets/images/merchant/svg/mdsbrd-icn1.svg" className="img-fluid" alt="LOGO IMG"/></Col>
                            <Col md={9} xs={9} className="mdtp-rgt">
                            <h3>12,536</h3>
                            <p>Orders/Appointm...</p>
                            </Col>
                            <div className="mbrdr-spce my-2"></div>
                            <Col md={6} xs={6} className="mdcnt-lft linergt">
                              <h3><span>+</span>500</h3>
                              <p>Last Week</p>
                            </Col>
                            <Col md={6} xs={6} className="mdcnt-lft">
                              <h3><span>+</span>1,000</h3>
                              <p>Last Month</p>
                            </Col>
                            <div className="mbrdr-spce my-2"></div>
                            <Col md={12} xs={12} className="text-center grfdv">
                               <img src="assets/images/merchant/svg/grph-img1.svg" className="img-fluid" alt="Graph Img"/>
                            </Col>
                          </Row>
                        </div>
                       </NavLink>
                      </Col>
                      <Col>
                       <NavLink to="/emp-order">
                        <div className="p-3 maindvmdsbrd">
                          <Row className="rw mx-0">
                            <Col md={3} xs={3} className="mdtp-lft text-center"><img src="assets/images/merchant/svg/mdsbrd-icn2.svg" className="img-fluid" alt="LOGO IMG"/></Col>
                            <Col md={9} xs={9} className="mdtp-rgt blutxt">
                            <h3>12,536</h3>
                            <p>Income Forecast</p>
                            </Col>
                            <div className="mbrdr-spce my-2"></div>
                            <Col md={6} xs={6} className="mdcnt-lft linergt">
                              <h3><span>+</span>500</h3>
                              <p>Last Week</p>
                            </Col>
                            <Col md={6} xs={6} className="mdcnt-lft">
                              <h3><span>+</span>1,000</h3>
                              <p>Last Month</p>
                            </Col>
                            <div className="mbrdr-spce my-2"></div>
                            <Col md={12} xs={12} className="text-center grfdv">
                              <img src="assets/images/merchant/svg/grph-img2.svg" className="img-fluid" alt="Graph Img"/>
                            </Col>
                          </Row>
                         </div>
                         </NavLink>
                      </Col>
                    </Row>
        {/* ========== Employee Dashbord 4 box Content End ========== */}
        {/* ========== Employee Dashbord Customer box Content Start ========== */}
                    <Col md={12} xs={12} className="edsbrdhist">
                        <p>Commission History</p>
                    </Col>
                    <Col md={12} xs={12} className="mcusbx">
                        <Row className="mx-0">
                          <Col md={2} xs={2} className="align-self-center px-1"><img src= {'assets/images/merchant/mcust-img1.png'} className="img-fluid" alt="Mcust Img"/></Col>
                          <Col md={6} xs={7} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                            <h3>Customer Name</h3>
                            <p>loremipsum@gmail.com</p>
                          </Col>
                          <Col md={4} xs={3} className="align-self-center text-end">
                          <div className="demo">
                             <select>
                                <option>Normal</option>
                                <option>Vip</option>
                                <option>Block</option>
                             </select>
                          </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={12} xs={12} className="mcusbx">
                        <Row className="mx-0">
                          <Col md={2} xs={2} className="align-self-center px-1"><img src= {'assets/images/merchant/mcust-img2.png'} className="img-fluid" alt="Mcust Img"/></Col>
                          <Col md={6} xs={7} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                            <h3>Customer Name</h3>
                            <p>loremipsum@gmail.com</p>
                          </Col>
                          <Col md={4} xs={3} className="align-self-center text-end">
                          <div className="demo">
                             <select>
                                <option>Normal</option>
                                <option>Vip</option>
                                <option>Block</option>
                             </select>
                          </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={12} xs={12} className="mcusbx">
                        <Row className="mx-0">
                          <Col md={2} xs={2} className="align-self-center px-1"><img src= {'assets/images/merchant/mcust-img3.png'} className="img-fluid" alt="Mcust Img"/></Col>
                          <Col md={6} xs={7} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                            <h3>Customer Name</h3>
                            <p>loremipsum@gmail.com</p>
                          </Col>
                  <Col md={4} xs={3} className="align-self-center text-end">
                  <div className="demo">
                     <select>
                        <option>Normal</option>
                        <option>Vip</option>
                        <option>Block</option>
                     </select>
                  </div>
                  </Col>
                        </Row>
                      </Col>
                      <Col md={12} xs={12} className="mcusbx">
                        <Row className="mx-0">
                          <Col md={2} xs={2} className="align-self-center px-1"><img src= {'assets/images/merchant/mcust-img4.png'} className="img-fluid" alt="Mcust Img"/></Col>
                          <Col md={6} xs={7} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                            <h3>Customer Name</h3>
                            <p>loremipsum@gmail.com</p>
                          </Col>
                  <Col md={4} xs={3} className="align-self-center text-end">
                  <div className="demo">
                     <select>
                        <option>Normal</option>
                        <option>Vip</option>
                        <option>Block</option>
                     </select>
                  </div>
                  </Col>
                        </Row>
                      </Col>
      {/* ========== Employee Dashbord Customer box Content End ========== */}
      {/* ========== Employee Dashbord Content End ========== */}
                     </Col>
                  </Row>
               </Container>
            </section>
      {/* ========== Navbar Start ========== */}
            <Footer/>
      {/* ========== Navbar End ========== */}
      {/* ========== Employee Dashbord Page End ========== */}
        </>
    )
}
export default EmpDash;