import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Form, Modal } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './MerDash.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import {Chart as ChartJS,CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Filler,Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Filler,Legend);
export const options = {responsive: true,plugins: {legend: {position: 'top',display: false,},title: {display: false,text: '',},},};
const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
export const data = {labels,datasets: [{fill: true,label: '',data: [12, 19, 3, 5,15,20,25],borderColor: 'rgba(63, 149, 52, 1)',backgroundColor: 'rgb(63 149 52 / 55%)',borderDash: [10,5],},],};
//import $ from 'jquery';
const MerDash = () => {
const [value, onChange] = useState(new Date());

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
useEffect(() => {
      document.title = `Merchant Dashbord | Blue Faucet`;
      document.getElementById('bodywrap').className='Homepgmain mdsbrdpgmain grnbg srcblk icnset';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });       
},[])  
    return (
      <>
      
      {/* ========== Merchant Dashbord Page Start ========== */}
         <section className="gen-wrap">
         {/* ========== Navbar Start ========== */}
            <Navbar/>
         {/* ========== Navbar End ========== */}
         <Container>
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
               {/* ========== Dashbord Order Box Start ========== */}
               <Row xs={{ cols:2, gutter: 2 }} lg={{ cols: 2, gutter: 3}} className="mt-2">
               <Col>
               <NavLink to="/mer-order-appointment">
                  <div className="p-3 maindvmdsbrd">
                     <Row className="rw mx-0">
                        <Col md={3} xs={3} className="mdtp-lft text-center">
                        <img src="assets/images/merchant/svg/mdsbrd-icn1.svg" className="img-fluid" alt="LOGO IMG"/></Col>
                        <Col md={9} xs={9} className="mdtp-rgt">
                        <h3>12,536</h3>
                        <p>Orders/Appointm...</p>
                        </Col>
                        <div className="mbrdr-spce my-2"></div>
                        <Col md={6} xs={6} className="mdcnt-lft linergt">
                        <h3><span>+</span>500</h3>
                        <p>Last Week</p>
                        </Col>
                        <Col md={6} xs={6} className="mdcnt-lft">
                        <h3><span>+</span>1,000</h3>
                        <p>Last Month</p>
                        </Col>
                        <div className="mbrdr-spce my-2"></div>
                        <Col md={12} xs={12} className="text-center grfdv">
                        <img src="assets/images/merchant/svg/grph-img1.svg" className="img-fluid" alt="Graph Img"/>
                        </Col>
                     </Row>
                  </div>
               </NavLink>
               </Col>
               <Col>
               <div className="p-3 maindvmdsbrd">
                  <Row className="rw mx-0">
                     <Col md={3} xs={3} className="mdtp-lft text-center">
                     <img src="assets/images/merchant/svg/mdsbrd-icn2.svg" className="img-fluid" alt="LOGO IMG"/></Col>
                     <Col md={9} xs={9} className="mdtp-rgt blutxt">
                     <h3>12,536</h3>
                     <p>Income Forecast</p>
                     </Col>
                     <div className="mbrdr-spce my-2"></div>
                     <Col md={6} xs={6} className="mdcnt-lft linergt">
                     <h3><span>+</span>500</h3>
                     <p>Last Week</p>
                     </Col>
                     <Col md={6} xs={6} className="mdcnt-lft">
                     <h3><span>+</span>1,000</h3>
                     <p>Last Month</p>
                     </Col>
                     <div className="mbrdr-spce my-2"></div>
                     <Col md={12} xs={12} className="text-center grfdv">
                     <img src="assets/images/merchant/svg/grph-img2.svg" className="img-fluid" alt="Graph Img"/>
                     </Col>
                  </Row>
               </div>
               </Col>
               <Col>
               <div className="p-3 maindvmdsbrd">
                  <Row className="rw mx-0">
                     <Col md={3} xs={3} className="mdtp-lft text-center">
                     <img src="assets/images/merchant/svg/mdsbrd-icn3.svg" className="img-fluid" alt="LOGO IMG"/></Col>
                     <Col md={9} xs={9} className="mdtp-rgt grntxt">
                     <h3>12,536</h3>
                     <p>Coupon Usage St...</p>
                     </Col>
                     <div className="mbrdr-spce my-2"></div>
                     <Col md={6} xs={6} className="mdcnt-lft linergt">
                     <h3><span>+</span>500</h3>
                     <p>Last Week</p>
                     </Col>
                     <Col md={6} xs={6} className="mdcnt-lft">
                     <h3><span>+</span>1,000</h3>
                     <p>Last Month</p>
                     </Col>
                     <div className="mbrdr-spce my-2"></div>
                     <Col md={12} xs={12} className="text-center grfdv">
                     <img src="assets/images/merchant/svg/grph-img3.svg" className="img-fluid" alt="Graph Img"/>
                     </Col>
                  </Row>
               </div>
               </Col>
               <Col>
               <div className="p-3 maindvmdsbrd">
                  <Row className="rw mx-0">
                     <Col md={3} xs={3} className="mdtp-lft text-center">
                     <img src="assets/images/merchant/svg/mdsbrd-icn4.svg" className="img-fluid" alt="LOGO IMG"/></Col>
                     <Col md={9} xs={9} className="mdtp-rgt ylwtxt">
                     <h3>12,536</h3>
                     <p>Customer Statistics</p>
                     </Col>
                     <div className="mbrdr-spce my-2"></div>
                     <Col md={6} xs={6} className="mdcnt-lft linergt">
                     <h3><span>+</span>500</h3>
                     <p>Last Week</p>
                     </Col>
                     <Col md={6} xs={6} className="mdcnt-lft">
                     <h3><span>+</span>1,000</h3>
                     <p>Last Month</p>
                     </Col>
                     <div className="mbrdr-spce my-2"></div>
                     <Col md={12} xs={12} className="text-center grfdv">
                     <img src="assets/images/merchant/svg/grph-img4.svg" className="img-fluid" alt="Graph Img"/>
                     </Col>
                  </Row>
               </div>
               </Col>
            </Row>
            {/* ========== Dashbord Order Box End ========== */}
            <Col md={12} xs={12} className="mbtndv">
            <NavLink to="#"  onClick={handleShow}><Button variant=""><i className="fas fa-plus me-1"></i> Invite Customers</Button>{' '}</NavLink>
            </Col>
            {/* ========== Dashbord Customers Box Start ========== */}
            <Col md={12} xs={12} className="mdsbrdchrtdv mb-3">
            <Row className="mx-0">
               <Col md={12} xs={12} className="cusslctbtn mb-3">
               <div className="cusradio">
                  <input className="radio-label" type="radio" name="yesno" id="one" defaultChecked={true}/>
                  <label className="button-label" htmlFor="one">Customers</label>
                  <input className="radio-label" type="radio" name="yesno" id="two"/>
                  <label className="button-label" htmlFor="two">Reservations</label>
                  <input className="radio-label" type="radio" name="yesno" id="three"/>
                  <label className="button-label" htmlFor="three">Orders</label>
               </div>
               </Col>
               <Col md={8} xs={8} className="mb-3">
               <Row className="dwngrftptxt">
                  <Col md={3} xs={3} className="text-center">
                  <img src="assets/images/merchant/user-img.png" className="img-fluid" alt="Graph Img"/></Col>
                  <Col md={9} xs={9}>
                  <p>Total Customers</p>
                  <h3>12536</h3>
                  </Col>
               </Row>
               </Col>
               <Col md={4} xs={4}>
               <Form.Select aria-label="Default select example">
                  <option>Day</option>
                  <option defaultValue="1">Week</option>
                  <option defaultValue="2">Month</option>
                  <option defaultValue="3">Year</option>
               </Form.Select>
               </Col>
            </Row>
            {/* ========== Dashbord Chat Start ========== */}
            <Line options={options} data={data} />
            {/* ========== Dashbord Chat End ========== */}
            </Col>
            {/* ========== Dashbord Customers Box End ========== */}
            {/* ========== Dashbord Calendar Box Start ========== */}
            <Col className="mcallenderdv">
            <Row className="mx-0 tpcntmc">
               <Col>
               <h3>Add Calendar</h3>
               </Col>
               <Col className="text-end">
               <img src="assets/images/merchant/svg/calander.svg" className="img-fluid" alt="Graph Img"/></Col>
            </Row>
            <div>
            {/* ========== Calendar Start ========== */}
               <Calendar onChange={onChange} defaultValue={value} />
            {/* ========== Calendar Start ========== */}
            </div>
            </Col>
            {/* ========== Dashbord Calendar Box Start ========== */}
            <Col className="modalpop">
            <div className="mrcustmrmodal">
            {/* ========== Dashbord Invite Customers Popup Box Start ========== */}
               <Modal size="lg" show={show} onHide={handleClose} aria-labelledby="example-modal-sizes-title-lg" className="trdmodal" id="trdmdl3">
                  <Modal.Body>
                     <Row className="mx-0 topppdtlmcstmr">
                        <Col md={6} xs={6}>
                        <h3>Invite Customers</h3>
                        </Col>
                        <Col md={6} xs={6} className="grntxt">
                        <h3>Copy Link</h3>
                        </Col>
                     </Row>
                     <Row className="mx-0 shrelist">
                        <Col md={12} xs={12} className="my-2">
                        <h3>Import your customers from the following, and send an invitation to them.</h3>
                        </Col>
                        <Col md={3} xs={3} className="shrelist-mincnt text-center">
                        <NavLink to="#">
                           <img src= {'assets/images/svg/shr-icn1.svg'} className="img-fluid" alt="Sld Img"/>
                           <div className="nm-shr">
                              <p>To Contact</p>
                           </div>
                        </NavLink>
                        </Col>
                        <Col md={3} xs={3} className="shrelist-mincnt text-center">
                        <NavLink to="#">
                           <img src= {'assets/images/svg/shr-icn2.svg'} className="img-fluid" alt="Sld Img"/>
                           <div className="nm-shr">
                              <p>Gmail</p>
                           </div>
                        </NavLink>
                        </Col>
                        <Col md={3} xs={3} className="shrelist-mincnt text-center">
                        <NavLink to="#">
                           <img src= {'assets/images/svg/shr-icn3.svg'} className="img-fluid" alt="Sld Img"/>
                           <div className="nm-shr">
                              <p>Outlook</p>
                           </div>
                        </NavLink>
                        </Col>
                        <Col md={3} xs={3} className="shrelist-mincnt text-center">
                        <NavLink to="#">
                           <img src= {'assets/images/svg/shr-icn4.svg'} className="img-fluid" alt="Sld Img"/>
                           <div className="nm-shr">
                              <p>Whatsapp</p>
                           </div>
                        </NavLink>
                        </Col>
                        <Col md={3} xs={3} className="shrelist-mincnt text-center">
                        <NavLink to="#">
                           <img src= {'assets/images/svg/shr-icn5.svg'} className="img-fluid" alt="Sld Img"/>
                           <div className="nm-shr">
                              <p>Phone Log</p>
                           </div>
                        </NavLink>
                        </Col>
                        <Col md={3} xs={3} className="shrelist-mincnt text-center">
                        <NavLink to="#">
                           <img src= {'assets/images/svg/shr-icn5.svg'} className="img-fluid" alt="Sld Img"/>
                           <div className="nm-shr">
                              <p>Phone Number</p>
                           </div>
                        </NavLink>
                        </Col>
                        <Col md={3} xs={3} className="shrelist-mincnt text-center">
                        <NavLink to="#">
                           <img src= {'assets/images/svg/shr-icn6.svg'} className="img-fluid" alt="Sld Img"/>
                           <div className="nm-shr">
                              <p>Email</p>
                           </div>
                        </NavLink>
                        </Col>
                        <Col md={3} xs={3} className="shrelist-mincnt text-center d-none">
                        <NavLink to="#">
                           <img src= {'assets/images/svg/shr-icn7.svg'} className="img-fluid" alt="Sld Img"/>
                           <div className="nm-shr">
                              <p>QR Code</p>
                           </div>
                        </NavLink>
                        </Col> 
                        <Col md={12} xs={12} className="oppbxcntmcustmr mb-3">
                        <h4>Invitation Message</h4>
                        <div>                           
                           <p className="mb-3">Hello,</p>
                           <p className="mb-3">I hope this message finds you well. My name is [Your Sales Username], and I'm not only a loyal customer of your store but also someone who believes in helping businesses thrive. I've frequented your store on numerous occasions, yet I understand that it's challenging to keep track of individual customers' preferences and purchase history.</p>
                           <p className="mb-3">It's a missed opportunity when potential customers like me walk by your store with tempting promotions but end up purchasing elsewhere due to a lack of personalized engagement. To bridge this gap and ensure that valuable customers don't slip through the cracks, I'd like to introduce you to a powerful solution: BlueFaucet.</p>
                           <p className="mb-3">BlueFaucet is a customer engagement platform designed to help merchants like you build meaningful relationships with customers. By using BlueFaucet, you can effortlessly create and manage memberships, stay in touch with customers, and keep them informed about promotions, special offers, and more.</p>
                           <p className="mb-3">Here's the best part: you can get started with a 30-day free trial. Plus, if your membership count remains under 50, your account remains free of charge. It's a risk-free opportunity to unlock the potential of personalized customer engagement and drive more business.</p>
                           <p className="mb-3">To take the first step towards transforming your customer interactions, simply click the link below to sign up for BlueFaucet:</p>
                           <p className="mb-3">[BlueFaucet Sign-Up Link]</p>
                           <p className="mb-3">Once your BlueFaucet account is up and running, I'd be thrilled to become a part of your store's membership. Imagine the possibilities of creating a more tailored shopping experience that not only benefits me but also enhances your business growth.</p>
                           <p className="mb-3">Should you have any questions or need assistance during the sign-up process, feel free to reach out to the BlueFaucet support team.</p>
                           <p className="mb-3">Thank you for considering this innovative approach to customer engagement. I look forward to seeing your business flourish even more.</p>
                           <p className="mb-3">Best regards,</p>
                           <p className="mb-0">[Your Sales Username]</p>                                     
                        </div>
                        </Col>
                        <Col md={12} xs={12} className="btnpopmcstmr my-2">
                        <NavLink to="#"> <Button variant="black" type="submit">Confirm</Button></NavLink>
                        </Col>
                     </Row>
                  </Modal.Body>
               </Modal>
               {/* ========== Dashbord Invite Customers Popup Start ========== */}
            </div>
            </Col>
            </Col>
            </Row>
         </Container>
      </section>
      {/* ========== Footer Start ========== */}
         <Footer/>
      {/* ========== Footer End ========== */}
      {/* ========== Merchant Dashbord Page End ========== */}
        </>
    )
}
export default MerDash;