import React, { useEffect } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import './UserStrlst.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import $ from 'jquery';

const UserStrlst = () => {
useEffect(() => {
      document.title = `User store list | Blue Faucet`;
      document.getElementById('bodywrap').className='ustrlpgmain srcblk';
    window.scrollTo({top: 0,left: 0,behavior: 'smooth', });  
},[])  
const like = (id) => {$('.lik'+id).hide();$('.dis'+id).show();}
const dislike = (id) => {$('.dis'+id).hide();$('.lik'+id).show();}
    return (
        <>
        {/* ========== User Store List Page Start ========== */}
         <section className="gen-wrap">
         {/* ========== Navbar Start ========== */}
            <Navbar headerText={''}/>
         {/* ========== Navbar End ========== */}
         <Container>
            <Row className="hmtpfixdv">
               <Col md={12} lg={12} xl={12} xxl={12} className="hmtp-scrl uclsrldv">
               <ul className="hmtp-scldv">
                  <Row className="mx-0 ucl-sfbtn">
                     <Col>
                     <Dropdown>
                        <Dropdown.Toggle variant="" id="dropdown-basic"><span><img src="assets/images/svg/ucl-srtimg.svg" className="img-fluid" alt="Usl Img"/></span>Sort</Dropdown.Toggle>
                        <Dropdown.Menu>
                           <Dropdown.Item href={void(0)}>Popular</Dropdown.Item>
                           <Dropdown.Item href={void(0)}>Rating</Dropdown.Item>
                           <Dropdown.Item href={void(0)}>Price Low to high</Dropdown.Item>
                           <Dropdown.Item href={void(0)}>Price High to Low</Dropdown.Item>
                        </Dropdown.Menu>
                     </Dropdown>
                     </Col>
                     <Col>
                     <NavLink to="#"><Button variant="" type="Button"><span><img src="assets/images/svg/ucl-fltrimg.svg" className="img-fluid" alt="Usl Img"/></span>Filter</Button></NavLink>
                     </Col>
                  </Row>
               </ul>
               </Col>
            </Row>
            {/* ========== Store List Card Detail Start ========== */}
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
               <Col md={12} xs={12} className="py-2">
                  <Col className="ustrlpg">
                  <Row className="mx-0 ustrl-box">
                     <Col md={2} xs={2} className="px-0">
                     <img src="assets/images/ustrl-img1.png" className="img-fluid" alt="Hm Img"/></Col>
                     <Col md={7} xs={7} className="px-2 px-md-0">
                     <h3>Saloon Shop 1</h3>
                     <div id="str-trng">
                        <div className="rating-group strrtg-rating">
                           <label aria-label="1 star" className="rating__label" htmlFor="rating-1"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-1" defaultValue="1" type="radio"/>
                           <label aria-label="2 stars" className="rating__label" htmlFor="rating-2"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-2" defaultValue="2" type="radio"/>
                           <label aria-label="3 stars" className="rating__label" htmlFor="rating-3"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-3" defaultValue="3" type="radio"/>
                           <label aria-label="4 stars" className="rating__label" htmlFor="rating-4"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-4" defaultValue="4" type="radio"/>
                           <label aria-label="5 stars" className="rating__label" htmlFor="rating-5"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-5" defaultValue="5" type="radio" defaultChecked/>
                        </div>
                        <span>(2121 ratings)</span>
                     </div>
                     <p>4.2 Km - Address Here</p>
                     <p>Buffet Offers</p>
                     </Col>
                     <Col md={3} xs={3} className="text-end px-0">
                     <NavLink to="/store-details"><Button variant="" type="button">View</Button></NavLink></Col>
                  </Row>
                  <Col md={12} xs={12} className="ustrl-crdlst">
                  <ul>
                     <li>$49.99</li>
                     <li>$69.99</li>
                     <li>44% Off</li>
                  </ul>
                  <div className="ginlike">
                     <div className="">
                        <svg className="lik1 lik" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" onClick={() =>
                           like(1)}>
                           <circle cx="12" cy="12" r="12" fill="white"/>
                           <path d="M8.79002 6.9229C8.31907 7.0049 7.74971 7.25093 7.38185 7.52975C6.7586 8.0007 6.29468 8.73173 6.10255 9.5354C5.99945 9.97589 5.96899 10.6437 6.0346 11.0701C6.20564 12.1854 6.87107 13.2445 8.16677 14.4628C8.64475 14.915 8.97981 15.2103 10.3458 16.3841C11.187 17.1105 11.6064 17.4549 11.6954 17.4994C11.8782 17.5861 12.1218 17.5861 12.3046 17.4994C12.485 17.4127 14.9944 15.2524 15.8332 14.4628C17.1289 13.2445 17.7944 12.1854 17.9654 11.0701C18.031 10.6437 18.0005 9.97589 17.8975 9.5354C17.7053 8.73173 17.2414 8.0007 16.6181 7.52975C16.2409 7.2439 15.6762 7.00256 15.1865 6.9229C14.9218 6.87838 14.3805 6.87838 14.1181 6.9229C13.3918 7.04942 12.7099 7.45243 12.1968 8.06396C12.0961 8.18346 12.0094 8.28187 12 8.28187C11.9906 8.28187 11.9039 8.18346 11.8032 8.06396C11.2901 7.45243 10.6082 7.04942 9.88188 6.9229C9.62883 6.88072 9.04307 6.88072 8.79002 6.9229Z" fill="#FF0000"/>
                        </svg>
                        <svg className="dis1 dis" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() =>
                           dislike(1)}>
                           <circle id="Ellipse_40" data-name="Ellipse 40" cx="12" cy="12" r="12" fill="#fff"/>
                           <g id="Black">
                              <path id="Vector" d="M8.79,6.923a3.579,3.579,0,0,0-1.408.607A3.579,3.579,0,0,0,6.1,9.535a4.716,4.716,0,0,0-.068,1.535,6.039,6.039,0,0,0,2.132,3.393c.478.452.813.748,2.179,1.921.841.726,1.261,1.071,1.35,1.115a.746.746,0,0,0,.609,0c.18-.087,2.69-2.247,3.529-3.037a6.039,6.039,0,0,0,2.132-3.393A4.719,4.719,0,0,0,17.9,9.535,3.579,3.579,0,0,0,16.618,7.53a3.491,3.491,0,0,0-1.432-.607,4.29,4.29,0,0,0-1.068,0A3.262,3.262,0,0,0,12.2,8.064a1.991,1.991,0,0,1-.2.218,1.991,1.991,0,0,1-.2-.218A3.262,3.262,0,0,0,9.882,6.923,4.726,4.726,0,0,0,8.79,6.923Z" fill="none" stroke="black" strokeWidth="1"/>
                           </g>
                        </svg>
                     </div>
                  </div>
                  </Col>
                  </Col>
               </Col>
               <Col md={12} xs={12} className="py-2">
                  <Col className="ustrlpg">
                  <Row className="mx-0 ustrl-box">
                     <Col md={2} xs={2} className="px-0">
                     <img src="assets/images/ustrl-img2.png" className="img-fluid" alt="Hm Img"/></Col>
                     <Col md={7} xs={7} className="px-2 px-md-0">
                     <h3>Saloon Shop 2</h3>
                     <div id="str-trng">
                        <div className="rating-group strrtg-rating">
                           <label aria-label="1 star" className="rating__label" htmlFor="rating-1"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-1" defaultValue="1" type="radio"/>
                           <label aria-label="2 stars" className="rating__label" htmlFor="rating-2"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-2" defaultValue="2" type="radio"/>
                           <label aria-label="3 stars" className="rating__label" htmlFor="rating-3"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-3" defaultValue="3" type="radio"/>
                           <label aria-label="4 stars" className="rating__label" htmlFor="rating-4"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-4" defaultValue="4" type="radio"/>
                           <label aria-label="5 stars" className="rating__label" htmlFor="rating-5"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-5" defaultValue="5" type="radio" defaultChecked/>
                        </div>
                        <span>(2121 ratings)</span>
                     </div>
                     <p>4.2 Km - Address Here</p>
                     <p>Buffet Offers</p>
                     </Col>
                     <Col md={3} xs={3} className="text-end px-0">
                     <NavLink to="/store-details"><Button variant="" type="button">View</Button></NavLink></Col>
                  </Row>
                  <Col md={12} xs={12} className="ustrl-crdlst">
                  <ul>
                     <li>$49.99</li>
                     <li>$69.99</li>
                     <li>44% Off</li>
                  </ul>
                  <div className="ginlike">
                     <div className="">
                        <svg className="lik2 lik" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" onClick={() =>
                           like(2)}>
                           <circle cx="12" cy="12" r="12" fill="white"/>
                           <path d="M8.79002 6.9229C8.31907 7.0049 7.74971 7.25093 7.38185 7.52975C6.7586 8.0007 6.29468 8.73173 6.10255 9.5354C5.99945 9.97589 5.96899 10.6437 6.0346 11.0701C6.20564 12.1854 6.87107 13.2445 8.16677 14.4628C8.64475 14.915 8.97981 15.2103 10.3458 16.3841C11.187 17.1105 11.6064 17.4549 11.6954 17.4994C11.8782 17.5861 12.1218 17.5861 12.3046 17.4994C12.485 17.4127 14.9944 15.2524 15.8332 14.4628C17.1289 13.2445 17.7944 12.1854 17.9654 11.0701C18.031 10.6437 18.0005 9.97589 17.8975 9.5354C17.7053 8.73173 17.2414 8.0007 16.6181 7.52975C16.2409 7.2439 15.6762 7.00256 15.1865 6.9229C14.9218 6.87838 14.3805 6.87838 14.1181 6.9229C13.3918 7.04942 12.7099 7.45243 12.1968 8.06396C12.0961 8.18346 12.0094 8.28187 12 8.28187C11.9906 8.28187 11.9039 8.18346 11.8032 8.06396C11.2901 7.45243 10.6082 7.04942 9.88188 6.9229C9.62883 6.88072 9.04307 6.88072 8.79002 6.9229Z" fill="#FF0000"/>
                        </svg>
                        <svg className="dis2 dis" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() =>
                           dislike(2)}>
                           <circle id="Ellipse_40" data-name="Ellipse 40" cx="12" cy="12" r="12" fill="#fff"/>
                           <g id="Black">
                              <path id="Vector" d="M8.79,6.923a3.579,3.579,0,0,0-1.408.607A3.579,3.579,0,0,0,6.1,9.535a4.716,4.716,0,0,0-.068,1.535,6.039,6.039,0,0,0,2.132,3.393c.478.452.813.748,2.179,1.921.841.726,1.261,1.071,1.35,1.115a.746.746,0,0,0,.609,0c.18-.087,2.69-2.247,3.529-3.037a6.039,6.039,0,0,0,2.132-3.393A4.719,4.719,0,0,0,17.9,9.535,3.579,3.579,0,0,0,16.618,7.53a3.491,3.491,0,0,0-1.432-.607,4.29,4.29,0,0,0-1.068,0A3.262,3.262,0,0,0,12.2,8.064a1.991,1.991,0,0,1-.2.218,1.991,1.991,0,0,1-.2-.218A3.262,3.262,0,0,0,9.882,6.923,4.726,4.726,0,0,0,8.79,6.923Z" fill="none" stroke="black" strokeWidth="1"/>
                           </g>
                        </svg>
                     </div>
                  </div>
                  </Col>
                  </Col>
               </Col>
               <Col md={12} xs={12} className="py-2">
                  <Col className="ustrlpg">
                  <Row className="mx-0 ustrl-box">
                     <Col md={2} xs={2} className="px-0">
                     <img src="assets/images/ustrl-img3.png" className="img-fluid" alt="Hm Img"/></Col>
                     <Col md={7} xs={7} className="px-2 px-md-0">
                     <h3>Saloon Shop 3</h3>
                     <div id="str-trng">
                        <div className="rating-group strrtg-rating">
                           <label aria-label="1 star" className="rating__label" htmlFor="rating-1"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-1" defaultValue="1" type="radio"/>
                           <label aria-label="2 stars" className="rating__label" htmlFor="rating-2"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-2" defaultValue="2" type="radio"/>
                           <label aria-label="3 stars" className="rating__label" htmlFor="rating-3"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-3" defaultValue="3" type="radio"/>
                           <label aria-label="4 stars" className="rating__label" htmlFor="rating-4"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-4" defaultValue="4" type="radio"/>
                           <label aria-label="5 stars" className="rating__label" htmlFor="rating-5"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-5" defaultValue="5" type="radio" defaultChecked/>
                        </div>
                        <span>(2121 ratings)</span>
                     </div>
                     <p>4.2 Km - Address Here</p>
                     <p>Buffet Offers</p>
                     </Col>
                     <Col md={3} xs={3} className="text-end px-0">
                     <NavLink to="/store-details"><Button variant="" type="button">View</Button></NavLink></Col>
                  </Row>
                  <Col md={12} xs={12} className="ustrl-crdlst">
                  <ul>
                     <li>$49.99</li>
                     <li>$69.99</li>
                     <li>44% Off</li>
                  </ul>
                  <div className="ginlike">
                     <div className="">
                        <svg className="lik3 lik" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" onClick={() =>
                           like(3)}>
                           <circle cx="12" cy="12" r="12" fill="white"/>
                           <path d="M8.79002 6.9229C8.31907 7.0049 7.74971 7.25093 7.38185 7.52975C6.7586 8.0007 6.29468 8.73173 6.10255 9.5354C5.99945 9.97589 5.96899 10.6437 6.0346 11.0701C6.20564 12.1854 6.87107 13.2445 8.16677 14.4628C8.64475 14.915 8.97981 15.2103 10.3458 16.3841C11.187 17.1105 11.6064 17.4549 11.6954 17.4994C11.8782 17.5861 12.1218 17.5861 12.3046 17.4994C12.485 17.4127 14.9944 15.2524 15.8332 14.4628C17.1289 13.2445 17.7944 12.1854 17.9654 11.0701C18.031 10.6437 18.0005 9.97589 17.8975 9.5354C17.7053 8.73173 17.2414 8.0007 16.6181 7.52975C16.2409 7.2439 15.6762 7.00256 15.1865 6.9229C14.9218 6.87838 14.3805 6.87838 14.1181 6.9229C13.3918 7.04942 12.7099 7.45243 12.1968 8.06396C12.0961 8.18346 12.0094 8.28187 12 8.28187C11.9906 8.28187 11.9039 8.18346 11.8032 8.06396C11.2901 7.45243 10.6082 7.04942 9.88188 6.9229C9.62883 6.88072 9.04307 6.88072 8.79002 6.9229Z" fill="#FF0000"/>
                        </svg>
                        <svg className="dis3 dis" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() =>
                           dislike(3)}>
                           <circle id="Ellipse_40" data-name="Ellipse 40" cx="12" cy="12" r="12" fill="#fff"/>
                           <g id="Black">
                              <path id="Vector" d="M8.79,6.923a3.579,3.579,0,0,0-1.408.607A3.579,3.579,0,0,0,6.1,9.535a4.716,4.716,0,0,0-.068,1.535,6.039,6.039,0,0,0,2.132,3.393c.478.452.813.748,2.179,1.921.841.726,1.261,1.071,1.35,1.115a.746.746,0,0,0,.609,0c.18-.087,2.69-2.247,3.529-3.037a6.039,6.039,0,0,0,2.132-3.393A4.719,4.719,0,0,0,17.9,9.535,3.579,3.579,0,0,0,16.618,7.53a3.491,3.491,0,0,0-1.432-.607,4.29,4.29,0,0,0-1.068,0A3.262,3.262,0,0,0,12.2,8.064a1.991,1.991,0,0,1-.2.218,1.991,1.991,0,0,1-.2-.218A3.262,3.262,0,0,0,9.882,6.923,4.726,4.726,0,0,0,8.79,6.923Z" fill="none" stroke="black" strokeWidth="1"/>
                           </g>
                        </svg>
                     </div>
                  </div>
                  </Col>
                  </Col>
               </Col>
               <Col md={12} xs={12} className="py-2">
                  <Col className="ustrlpg">
                  <Row className="mx-0 ustrl-box">
                     <Col md={2} xs={2} className="px-0">
                     <img src="assets/images/ustrl-img4.png" className="img-fluid" alt="Hm Img"/></Col>
                     <Col md={7} xs={7} className="px-2 px-md-0">
                     <h3>Saloon Shop 4</h3>
                     <div id="str-trng">
                        <div className="rating-group strrtg-rating">
                           <label aria-label="1 star" className="rating__label" htmlFor="rating-1"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-1" defaultValue="1" type="radio"/>
                           <label aria-label="2 stars" className="rating__label" htmlFor="rating-2"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-2" defaultValue="2" type="radio"/>
                           <label aria-label="3 stars" className="rating__label" htmlFor="rating-3"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-3" defaultValue="3" type="radio"/>
                           <label aria-label="4 stars" className="rating__label" htmlFor="rating-4"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-4" defaultValue="4" type="radio"/>
                           <label aria-label="5 stars" className="rating__label" htmlFor="rating-5"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-5" defaultValue="5" type="radio" defaultChecked/>
                        </div>
                        <span>(2121 ratings)</span>
                     </div>
                     <p>4.2 Km - Address Here</p>
                     <p>Buffet Offers</p>
                     </Col>
                     <Col md={3} xs={3} className="text-end px-0">
                     <NavLink to="/store-details"><Button variant="" type="button">View</Button></NavLink></Col>
                  </Row>
                  <Col md={12} xs={12} className="ustrl-crdlst">
                  <ul>
                     <li>$49.99</li>
                     <li>$69.99</li>
                     <li>44% Off</li>
                  </ul>
                  <div className="ginlike">
                     <div className="">
                        <svg className="lik4 lik" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" onClick={() =>
                           like(4)}>
                           <circle cx="12" cy="12" r="12" fill="white"/>
                           <path d="M8.79002 6.9229C8.31907 7.0049 7.74971 7.25093 7.38185 7.52975C6.7586 8.0007 6.29468 8.73173 6.10255 9.5354C5.99945 9.97589 5.96899 10.6437 6.0346 11.0701C6.20564 12.1854 6.87107 13.2445 8.16677 14.4628C8.64475 14.915 8.97981 15.2103 10.3458 16.3841C11.187 17.1105 11.6064 17.4549 11.6954 17.4994C11.8782 17.5861 12.1218 17.5861 12.3046 17.4994C12.485 17.4127 14.9944 15.2524 15.8332 14.4628C17.1289 13.2445 17.7944 12.1854 17.9654 11.0701C18.031 10.6437 18.0005 9.97589 17.8975 9.5354C17.7053 8.73173 17.2414 8.0007 16.6181 7.52975C16.2409 7.2439 15.6762 7.00256 15.1865 6.9229C14.9218 6.87838 14.3805 6.87838 14.1181 6.9229C13.3918 7.04942 12.7099 7.45243 12.1968 8.06396C12.0961 8.18346 12.0094 8.28187 12 8.28187C11.9906 8.28187 11.9039 8.18346 11.8032 8.06396C11.2901 7.45243 10.6082 7.04942 9.88188 6.9229C9.62883 6.88072 9.04307 6.88072 8.79002 6.9229Z" fill="#FF0000"/>
                        </svg>
                        <svg className="dis4 dis" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() =>
                           dislike(4)}>
                           <circle id="Ellipse_40" data-name="Ellipse 40" cx="12" cy="12" r="12" fill="#fff"/>
                           <g id="Black">
                              <path id="Vector" d="M8.79,6.923a3.579,3.579,0,0,0-1.408.607A3.579,3.579,0,0,0,6.1,9.535a4.716,4.716,0,0,0-.068,1.535,6.039,6.039,0,0,0,2.132,3.393c.478.452.813.748,2.179,1.921.841.726,1.261,1.071,1.35,1.115a.746.746,0,0,0,.609,0c.18-.087,2.69-2.247,3.529-3.037a6.039,6.039,0,0,0,2.132-3.393A4.719,4.719,0,0,0,17.9,9.535,3.579,3.579,0,0,0,16.618,7.53a3.491,3.491,0,0,0-1.432-.607,4.29,4.29,0,0,0-1.068,0A3.262,3.262,0,0,0,12.2,8.064a1.991,1.991,0,0,1-.2.218,1.991,1.991,0,0,1-.2-.218A3.262,3.262,0,0,0,9.882,6.923,4.726,4.726,0,0,0,8.79,6.923Z" fill="none" stroke="black" strokeWidth="1"/>
                           </g>
                        </svg>
                     </div>
                  </div>
                  </Col>
                  </Col>
               </Col>
               <Col md={12} xs={12} className="py-2">
                  <Col className="ustrlpg">
                  <Row className="mx-0 ustrl-box">
                     <Col md={2} xs={2} className="px-0">
                     <img src="assets/images/ustrl-img5.png" className="img-fluid" alt="Hm Img"/></Col>
                     <Col md={7} xs={7} className="px-2 px-md-0">
                     <h3>Saloon Shop 5</h3>
                     <div id="str-trng">
                        <div className="rating-group strrtg-rating">
                           <label aria-label="1 star" className="rating__label" htmlFor="rating-1"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-1" defaultValue="1" type="radio"/>
                           <label aria-label="2 stars" className="rating__label" htmlFor="rating-2"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-2" defaultValue="2" type="radio"/>
                           <label aria-label="3 stars" className="rating__label" htmlFor="rating-3"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-3" defaultValue="3" type="radio"/>
                           <label aria-label="4 stars" className="rating__label" htmlFor="rating-4"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-4" defaultValue="4" type="radio"/>
                           <label aria-label="5 stars" className="rating__label" htmlFor="rating-5"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                           <input className="rating__input" name="rating" id="rating-5" defaultValue="5" type="radio" defaultChecked/>
                        </div>
                        <span>(2121 ratings)</span>
                     </div>
                     <p>4.2 Km - Address Here</p>
                     <p>Buffet Offers</p>
                     </Col>
                     <Col md={3} xs={3} className="text-end px-0">
                     <NavLink to="/store-details"><Button variant="" type="button">View</Button></NavLink></Col>
                  </Row>
                  <Col md={12} xs={12} className="ustrl-crdlst">
                  <ul>
                     <li>$49.99</li>
                     <li>$69.99</li>
                     <li>44% Off</li>
                  </ul>
                  <div className="ginlike">
                     <div className="">
                        <svg className="lik5 lik" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" onClick={() =>
                           like(5)}>
                           <circle cx="12" cy="12" r="12" fill="white"/>
                           <path d="M8.79002 6.9229C8.31907 7.0049 7.74971 7.25093 7.38185 7.52975C6.7586 8.0007 6.29468 8.73173 6.10255 9.5354C5.99945 9.97589 5.96899 10.6437 6.0346 11.0701C6.20564 12.1854 6.87107 13.2445 8.16677 14.4628C8.64475 14.915 8.97981 15.2103 10.3458 16.3841C11.187 17.1105 11.6064 17.4549 11.6954 17.4994C11.8782 17.5861 12.1218 17.5861 12.3046 17.4994C12.485 17.4127 14.9944 15.2524 15.8332 14.4628C17.1289 13.2445 17.7944 12.1854 17.9654 11.0701C18.031 10.6437 18.0005 9.97589 17.8975 9.5354C17.7053 8.73173 17.2414 8.0007 16.6181 7.52975C16.2409 7.2439 15.6762 7.00256 15.1865 6.9229C14.9218 6.87838 14.3805 6.87838 14.1181 6.9229C13.3918 7.04942 12.7099 7.45243 12.1968 8.06396C12.0961 8.18346 12.0094 8.28187 12 8.28187C11.9906 8.28187 11.9039 8.18346 11.8032 8.06396C11.2901 7.45243 10.6082 7.04942 9.88188 6.9229C9.62883 6.88072 9.04307 6.88072 8.79002 6.9229Z" fill="#FF0000"/>
                        </svg>
                        <svg className="dis5 dis" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() =>
                           dislike(5)}>
                           <circle id="Ellipse_40" data-name="Ellipse 40" cx="12" cy="12" r="12" fill="#fff"/>
                           <g id="Black">
                              <path id="Vector" d="M8.79,6.923a3.579,3.579,0,0,0-1.408.607A3.579,3.579,0,0,0,6.1,9.535a4.716,4.716,0,0,0-.068,1.535,6.039,6.039,0,0,0,2.132,3.393c.478.452.813.748,2.179,1.921.841.726,1.261,1.071,1.35,1.115a.746.746,0,0,0,.609,0c.18-.087,2.69-2.247,3.529-3.037a6.039,6.039,0,0,0,2.132-3.393A4.719,4.719,0,0,0,17.9,9.535,3.579,3.579,0,0,0,16.618,7.53a3.491,3.491,0,0,0-1.432-.607,4.29,4.29,0,0,0-1.068,0A3.262,3.262,0,0,0,12.2,8.064a1.991,1.991,0,0,1-.2.218,1.991,1.991,0,0,1-.2-.218A3.262,3.262,0,0,0,9.882,6.923,4.726,4.726,0,0,0,8.79,6.923Z" fill="none" stroke="black" strokeWidth="1"/>
                           </g>
                        </svg>
                     </div>
                  </div>
                  </Col>
                  </Col>
               </Col>
               </Col>
            </Row>
            {/* ========== Store List Card Detail End ========== */}
         </Container>
      </section>
      {/* ========== User Store List Page End ========== */}
        </>
    )
}

export default UserStrlst;