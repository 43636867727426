import React, { } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './UserOrdrsmry.css';

const UserOdrcfm = () => {
  return (
        <>
    {/* ========== Order Confirmation Content Start ========== */}
      <section className="cnfrmtnpg">
        <Container>
          <Row className="cnfmtnpg justify-content-center mx-0">
            <Col md={12} xs={12} className="px-0 cntdv">
             <div className="text-center">
               <img src= {'assets/images//svg/confrmation-check.svg'} className="img-fluid mb-3" alt="Cnfrmation Img"/> 
               <h3>Your Order is booked</h3>
             </div>
            </Col>
            <Col md={8} xs={8} className="btn-ordrcnfrmation text-center">
             <NavLink to="/home"><Button variant="home">continue</Button>{' '}</NavLink>
            </Col>
          </Row>
        </Container>
      </section>
    {/* ========== Order Confirmation Content End ========== */}
 </>
    )
}
export default UserOdrcfm;