import React, { useEffect } from 'react';
import { Row, Col, Container, Button} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './UserHome.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import $ from 'jquery';

const UserHome = () => {

useEffect(() => {
      document.title = `User Home | Blue Faucet`;
      document.getElementById('bodywrap').className='Homepgmain srcblk icnset';
   window.scrollTo({top: 0,left: 0,behavior: 'smooth',}); 
},[])  
const like = (id) => {$('.lik'+id).hide();$('.dis'+id).show();}
const dislike = (id) => {$('.dis'+id).hide();$('.lik'+id).show();}

    return (
        <>
         {/* ========== Home Page Start ========== */}
            <section className="gen-wrap">
            {/* ========== Navbar Start ========== */}
               <Navbar headerText={''}/>
            {/* ========== Navbar End ========== */}
               <Container>
               {/* ========== Category Box Start ========== */}
                  <Row className="hmtpfixdv">
                     <Col md={12} lg={12} xl={12} xxl={12} className="hmtp-scrl">
                     <ul className="hmtp-scldv">
                        <NavLink to="/store-list" className="active intFilter" data-val="all">
                           <li>
                              <img src="assets/images/hm-srvc.png" className="img-fluid" alt="Hm Img"/>
                              <p>Home Services</p>
                           </li>
                        </NavLink>
                        <NavLink to="/store-list" className="active intFilter" data-val="all">
                           <li>
                              <img src="assets/images/hm-img2.png" className="img-fluid" alt="Hm Img"/>
                              <p>Restaurants</p>
                           </li>
                        </NavLink>
                        <NavLink to="/store-list" className="active intFilter" data-val="all">
                           <li>
                              <img src="assets/images/prfsnlsrvc-icn.png" className="img-fluid" alt="Hm Img"/>
                              <p>Professional Services</p>
                           </li>
                        </NavLink>
                        <NavLink to="/category-list" className="active intFilter" data-val="all">
                           <li>
                              <img src="assets/images/hm-imgplus.png" className="img-fluid" alt="Hm Img"/>
                              <p>View All</p>
                           </li>
                        </NavLink>
                     </ul>
                     </Col>
                  </Row>
                  {/* ========== Services Box End ========== */}
                  <Row className="mx-0 mianhmdwncnt">
                     <Col className="mnhmcnt">
                     <Col md={12} xs={12} className="py-3 tpbnr">
                     <NavLink to="/coupon-list">
                      <Row className="mx-0 cuscoupn cuscoupnhmtp">
                       <Col className="align-self-center">
                        <p>79 Coupons Available</p>
                        <Button variant="btn">View All</Button>{' '}
                       </Col>
                       <Col className="align-self-end text-end"><img src="assets/images/svg/hm-tpcpnimg.svg" className="img-fluid w-100" alt="Hmtp Postr"/></Col>
                      </Row>
                     </NavLink>
                     </Col>
                     <Col md={12} xs={12} className="btn-hmdwnbnr mb-3">
                     <NavLink to="/sales-person"><Button variant="">Sales Funnel</Button>{' '}</NavLink></Col>
                     <Col md={12} xs={12} className="hmcrdsldr  my-md-2">
                     <div className="tptxtcnt mb-3">
                        <p>Today’s Special From Favorite Stores</p>
                        <NavLink to="/favorite"> View All</NavLink>
                     </div>
                     <Col className="sldrhm">
                     {/* ========== OwlCarousel Box Card Start ========== */}
                     <OwlCarousel items={2.2}  className='owl-theme' loop margin={10} >
                        <Col className="hmone-slrcrd">                        
                           <div className="ginlike">
                              <div className="">
                                 <svg className="dis1 dis" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() =>
                                    dislike(1)}>
                                    <circle id="Ellipse_40" data-name="Ellipse 40" cx="12" cy="12" r="12" fill="#fff"/>
                                    <g id="Black">
                                       <path id="Vector" d="M8.79,6.923a3.579,3.579,0,0,0-1.408.607A3.579,3.579,0,0,0,6.1,9.535a4.716,4.716,0,0,0-.068,1.535,6.039,6.039,0,0,0,2.132,3.393c.478.452.813.748,2.179,1.921.841.726,1.261,1.071,1.35,1.115a.746.746,0,0,0,.609,0c.18-.087,2.69-2.247,3.529-3.037a6.039,6.039,0,0,0,2.132-3.393A4.719,4.719,0,0,0,17.9,9.535,3.579,3.579,0,0,0,16.618,7.53a3.491,3.491,0,0,0-1.432-.607,4.29,4.29,0,0,0-1.068,0A3.262,3.262,0,0,0,12.2,8.064a1.991,1.991,0,0,1-.2.218,1.991,1.991,0,0,1-.2-.218A3.262,3.262,0,0,0,9.882,6.923,4.726,4.726,0,0,0,8.79,6.923Z" fill="none" stroke="black" strokeWidth="1"/>
                                    </g>
                                 </svg>
                                 <svg className="lik1 lik" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" onClick={() =>
                                    like(1)}>
                                    <circle cx="12" cy="12" r="12" fill="white"/>
                                    <path d="M8.79002 6.9229C8.31907 7.0049 7.74971 7.25093 7.38185 7.52975C6.7586 8.0007 6.29468 8.73173 6.10255 9.5354C5.99945 9.97589 5.96899 10.6437 6.0346 11.0701C6.20564 12.1854 6.87107 13.2445 8.16677 14.4628C8.64475 14.915 8.97981 15.2103 10.3458 16.3841C11.187 17.1105 11.6064 17.4549 11.6954 17.4994C11.8782 17.5861 12.1218 17.5861 12.3046 17.4994C12.485 17.4127 14.9944 15.2524 15.8332 14.4628C17.1289 13.2445 17.7944 12.1854 17.9654 11.0701C18.031 10.6437 18.0005 9.97589 17.8975 9.5354C17.7053 8.73173 17.2414 8.0007 16.6181 7.52975C16.2409 7.2439 15.6762 7.00256 15.1865 6.9229C14.9218 6.87838 14.3805 6.87838 14.1181 6.9229C13.3918 7.04942 12.7099 7.45243 12.1968 8.06396C12.0961 8.18346 12.0094 8.28187 12 8.28187C11.9906 8.28187 11.9039 8.18346 11.8032 8.06396C11.2901 7.45243 10.6082 7.04942 9.88188 6.9229C9.62883 6.88072 9.04307 6.88072 8.79002 6.9229Z" fill="#FF0000"/>
                                 </svg>                                 
                              </div>
                           </div>
                           <NavLink to="/favorite">                           
                              <div className="sldtp-imgdv">
                                 <img src= {'assets/images/slrd-img1.png'} className="img-fluid" alt="Sld Img"/>
                              </div>
                              <div className="sldcntdv">
                                 <h3>Barbeque Near By...</h3>
                                 <p>$100.00 <span>$120.00</span></p>
                              </div>
                           </NavLink>
                        </Col>
                        <Col className="hmone-slrcrd">                        
                           <div className="ginlike">
                              <div className="">
                                 <svg className="dis2 dis" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() =>
                                    dislike(2)}>
                                    <circle id="Ellipse_40" data-name="Ellipse 40" cx="12" cy="12" r="12" fill="#fff"/>
                                    <g id="Black">
                                       <path id="Vector" d="M8.79,6.923a3.579,3.579,0,0,0-1.408.607A3.579,3.579,0,0,0,6.1,9.535a4.716,4.716,0,0,0-.068,1.535,6.039,6.039,0,0,0,2.132,3.393c.478.452.813.748,2.179,1.921.841.726,1.261,1.071,1.35,1.115a.746.746,0,0,0,.609,0c.18-.087,2.69-2.247,3.529-3.037a6.039,6.039,0,0,0,2.132-3.393A4.719,4.719,0,0,0,17.9,9.535,3.579,3.579,0,0,0,16.618,7.53a3.491,3.491,0,0,0-1.432-.607,4.29,4.29,0,0,0-1.068,0A3.262,3.262,0,0,0,12.2,8.064a1.991,1.991,0,0,1-.2.218,1.991,1.991,0,0,1-.2-.218A3.262,3.262,0,0,0,9.882,6.923,4.726,4.726,0,0,0,8.79,6.923Z" fill="none" stroke="black" strokeWidth="1"/>
                                    </g>
                                 </svg>
                                 <svg className="lik2 lik" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" onClick={() =>
                                    like(2)}>
                                    <circle cx="12" cy="12" r="12" fill="white"/>
                                    <path d="M8.79002 6.9229C8.31907 7.0049 7.74971 7.25093 7.38185 7.52975C6.7586 8.0007 6.29468 8.73173 6.10255 9.5354C5.99945 9.97589 5.96899 10.6437 6.0346 11.0701C6.20564 12.1854 6.87107 13.2445 8.16677 14.4628C8.64475 14.915 8.97981 15.2103 10.3458 16.3841C11.187 17.1105 11.6064 17.4549 11.6954 17.4994C11.8782 17.5861 12.1218 17.5861 12.3046 17.4994C12.485 17.4127 14.9944 15.2524 15.8332 14.4628C17.1289 13.2445 17.7944 12.1854 17.9654 11.0701C18.031 10.6437 18.0005 9.97589 17.8975 9.5354C17.7053 8.73173 17.2414 8.0007 16.6181 7.52975C16.2409 7.2439 15.6762 7.00256 15.1865 6.9229C14.9218 6.87838 14.3805 6.87838 14.1181 6.9229C13.3918 7.04942 12.7099 7.45243 12.1968 8.06396C12.0961 8.18346 12.0094 8.28187 12 8.28187C11.9906 8.28187 11.9039 8.18346 11.8032 8.06396C11.2901 7.45243 10.6082 7.04942 9.88188 6.9229C9.62883 6.88072 9.04307 6.88072 8.79002 6.9229Z" fill="#FF0000"/>
                                 </svg>
                              </div>
                           </div>
                           <NavLink to="/favorite">
                              <div className="sldtp-imgdv">
                                 <img src= {'assets/images/slrd-img2.png'} className="img-fluid" alt="Sld Img"/>
                              </div>
                              <div className="sldcntdv">
                                 <h3>Best Buffets</h3>
                                 <p>$100.00 <span>$120.00</span></p>
                              </div>
                           </NavLink>
                        </Col>
                        <Col className="hmone-slrcrd">
                           <div className="ginlike">
                              <div className="">
                                 <svg className="lik3 lik" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" onClick={() =>
                                    like(3)}>
                                    <circle cx="12" cy="12" r="12" fill="white"/>
                                    <path d="M8.79002 6.9229C8.31907 7.0049 7.74971 7.25093 7.38185 7.52975C6.7586 8.0007 6.29468 8.73173 6.10255 9.5354C5.99945 9.97589 5.96899 10.6437 6.0346 11.0701C6.20564 12.1854 6.87107 13.2445 8.16677 14.4628C8.64475 14.915 8.97981 15.2103 10.3458 16.3841C11.187 17.1105 11.6064 17.4549 11.6954 17.4994C11.8782 17.5861 12.1218 17.5861 12.3046 17.4994C12.485 17.4127 14.9944 15.2524 15.8332 14.4628C17.1289 13.2445 17.7944 12.1854 17.9654 11.0701C18.031 10.6437 18.0005 9.97589 17.8975 9.5354C17.7053 8.73173 17.2414 8.0007 16.6181 7.52975C16.2409 7.2439 15.6762 7.00256 15.1865 6.9229C14.9218 6.87838 14.3805 6.87838 14.1181 6.9229C13.3918 7.04942 12.7099 7.45243 12.1968 8.06396C12.0961 8.18346 12.0094 8.28187 12 8.28187C11.9906 8.28187 11.9039 8.18346 11.8032 8.06396C11.2901 7.45243 10.6082 7.04942 9.88188 6.9229C9.62883 6.88072 9.04307 6.88072 8.79002 6.9229Z" fill="#FF0000"/>
                                 </svg>
                                 <svg className="dis3 dis" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() =>
                                    dislike(3)}>
                                    <circle id="Ellipse_40" data-name="Ellipse 40" cx="12" cy="12" r="12" fill="#fff"/>
                                    <g id="Black">
                                       <path id="Vector" d="M8.79,6.923a3.579,3.579,0,0,0-1.408.607A3.579,3.579,0,0,0,6.1,9.535a4.716,4.716,0,0,0-.068,1.535,6.039,6.039,0,0,0,2.132,3.393c.478.452.813.748,2.179,1.921.841.726,1.261,1.071,1.35,1.115a.746.746,0,0,0,.609,0c.18-.087,2.69-2.247,3.529-3.037a6.039,6.039,0,0,0,2.132-3.393A4.719,4.719,0,0,0,17.9,9.535,3.579,3.579,0,0,0,16.618,7.53a3.491,3.491,0,0,0-1.432-.607,4.29,4.29,0,0,0-1.068,0A3.262,3.262,0,0,0,12.2,8.064a1.991,1.991,0,0,1-.2.218,1.991,1.991,0,0,1-.2-.218A3.262,3.262,0,0,0,9.882,6.923,4.726,4.726,0,0,0,8.79,6.923Z" fill="none" stroke="black" strokeWidth="1"/>
                                    </g>
                                 </svg>
                              </div>
                           </div>
                           <NavLink to="/favorite">                           
                              <div className="sldtp-imgdv">
                                 <img src= {'assets/images/slrd-img1.png'} className="img-fluid" alt="Sld Img"/>
                              </div>
                              <div className="sldcntdv">
                                 <h3>Barbeque Near By...</h3>
                                 <p>$100.00 <span>$120.00</span></p>
                              </div>
                           </NavLink>
                        </Col>
                        <Col className="hmone-slrcrd">                        
                           <div className="ginlike">
                              <div className="">
                                 <svg className="lik4 lik" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" onClick={() =>
                                    like(4)}>
                                    <circle cx="12" cy="12" r="12" fill="white"/>
                                    <path d="M8.79002 6.9229C8.31907 7.0049 7.74971 7.25093 7.38185 7.52975C6.7586 8.0007 6.29468 8.73173 6.10255 9.5354C5.99945 9.97589 5.96899 10.6437 6.0346 11.0701C6.20564 12.1854 6.87107 13.2445 8.16677 14.4628C8.64475 14.915 8.97981 15.2103 10.3458 16.3841C11.187 17.1105 11.6064 17.4549 11.6954 17.4994C11.8782 17.5861 12.1218 17.5861 12.3046 17.4994C12.485 17.4127 14.9944 15.2524 15.8332 14.4628C17.1289 13.2445 17.7944 12.1854 17.9654 11.0701C18.031 10.6437 18.0005 9.97589 17.8975 9.5354C17.7053 8.73173 17.2414 8.0007 16.6181 7.52975C16.2409 7.2439 15.6762 7.00256 15.1865 6.9229C14.9218 6.87838 14.3805 6.87838 14.1181 6.9229C13.3918 7.04942 12.7099 7.45243 12.1968 8.06396C12.0961 8.18346 12.0094 8.28187 12 8.28187C11.9906 8.28187 11.9039 8.18346 11.8032 8.06396C11.2901 7.45243 10.6082 7.04942 9.88188 6.9229C9.62883 6.88072 9.04307 6.88072 8.79002 6.9229Z" fill="#FF0000"/>
                                 </svg>
                                 <svg className="dis4 dis" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() =>
                                    dislike(4)}>
                                    <circle id="Ellipse_40" data-name="Ellipse 40" cx="12" cy="12" r="12" fill="#fff"/>
                                    <g id="Black">
                                       <path id="Vector" d="M8.79,6.923a3.579,3.579,0,0,0-1.408.607A3.579,3.579,0,0,0,6.1,9.535a4.716,4.716,0,0,0-.068,1.535,6.039,6.039,0,0,0,2.132,3.393c.478.452.813.748,2.179,1.921.841.726,1.261,1.071,1.35,1.115a.746.746,0,0,0,.609,0c.18-.087,2.69-2.247,3.529-3.037a6.039,6.039,0,0,0,2.132-3.393A4.719,4.719,0,0,0,17.9,9.535,3.579,3.579,0,0,0,16.618,7.53a3.491,3.491,0,0,0-1.432-.607,4.29,4.29,0,0,0-1.068,0A3.262,3.262,0,0,0,12.2,8.064a1.991,1.991,0,0,1-.2.218,1.991,1.991,0,0,1-.2-.218A3.262,3.262,0,0,0,9.882,6.923,4.726,4.726,0,0,0,8.79,6.923Z" fill="none" stroke="black" strokeWidth="1"/>
                                    </g>
                                 </svg>
                              </div>
                           </div>
                           <NavLink to="/favorite">
                              <div className="sldtp-imgdv">
                                 <img src= {'assets/images/slrd-img2.png'} className="img-fluid" alt="Sld Img"/>
                              </div>
                              <div className="sldcntdv">
                                 <h3>Best Buffets</h3>
                                 <p>$100.00 <span>$120.00</span></p>
                              </div>
                           </NavLink>
                        </Col>
                        <Col className="hmone-slrcrd">                        
                           <div className="ginlike">
                              <div className="">
                                 <svg className="lik5 lik" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" onClick={() =>
                                    like(5)}>
                                    <circle cx="12" cy="12" r="12" fill="white"/>
                                    <path d="M8.79002 6.9229C8.31907 7.0049 7.74971 7.25093 7.38185 7.52975C6.7586 8.0007 6.29468 8.73173 6.10255 9.5354C5.99945 9.97589 5.96899 10.6437 6.0346 11.0701C6.20564 12.1854 6.87107 13.2445 8.16677 14.4628C8.64475 14.915 8.97981 15.2103 10.3458 16.3841C11.187 17.1105 11.6064 17.4549 11.6954 17.4994C11.8782 17.5861 12.1218 17.5861 12.3046 17.4994C12.485 17.4127 14.9944 15.2524 15.8332 14.4628C17.1289 13.2445 17.7944 12.1854 17.9654 11.0701C18.031 10.6437 18.0005 9.97589 17.8975 9.5354C17.7053 8.73173 17.2414 8.0007 16.6181 7.52975C16.2409 7.2439 15.6762 7.00256 15.1865 6.9229C14.9218 6.87838 14.3805 6.87838 14.1181 6.9229C13.3918 7.04942 12.7099 7.45243 12.1968 8.06396C12.0961 8.18346 12.0094 8.28187 12 8.28187C11.9906 8.28187 11.9039 8.18346 11.8032 8.06396C11.2901 7.45243 10.6082 7.04942 9.88188 6.9229C9.62883 6.88072 9.04307 6.88072 8.79002 6.9229Z" fill="#FF0000"/>
                                 </svg>
                                 <svg className="dis5 dis" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() =>
                                    dislike(5)}>
                                    <circle id="Ellipse_40" data-name="Ellipse 40" cx="12" cy="12" r="12" fill="#fff"/>
                                    <g id="Black">
                                       <path id="Vector" d="M8.79,6.923a3.579,3.579,0,0,0-1.408.607A3.579,3.579,0,0,0,6.1,9.535a4.716,4.716,0,0,0-.068,1.535,6.039,6.039,0,0,0,2.132,3.393c.478.452.813.748,2.179,1.921.841.726,1.261,1.071,1.35,1.115a.746.746,0,0,0,.609,0c.18-.087,2.69-2.247,3.529-3.037a6.039,6.039,0,0,0,2.132-3.393A4.719,4.719,0,0,0,17.9,9.535,3.579,3.579,0,0,0,16.618,7.53a3.491,3.491,0,0,0-1.432-.607,4.29,4.29,0,0,0-1.068,0A3.262,3.262,0,0,0,12.2,8.064a1.991,1.991,0,0,1-.2.218,1.991,1.991,0,0,1-.2-.218A3.262,3.262,0,0,0,9.882,6.923,4.726,4.726,0,0,0,8.79,6.923Z" fill="none" stroke="black" strokeWidth="1"/>
                                    </g>
                                 </svg>
                              </div>
                           </div>
                           <NavLink to="/favorite">                           
                              <div className="sldtp-imgdv">
                                 <img src= {'assets/images/slrd-img1.png'} className="img-fluid" alt="Sld Img"/>
                              </div>
                              <div className="sldcntdv">
                                 <h3>Barbeque Near By...</h3>
                                 <p>$100.00 <span>$120.00</span></p>
                              </div>
                           </NavLink>
                        </Col>
                        <Col className="hmone-slrcrd">
                           <div className="ginlike">
                              <div className="">
                                 <svg className="lik6 lik" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" onClick={() =>
                                    like(6)}>
                                    <circle cx="12" cy="12" r="12" fill="white"/>
                                    <path d="M8.79002 6.9229C8.31907 7.0049 7.74971 7.25093 7.38185 7.52975C6.7586 8.0007 6.29468 8.73173 6.10255 9.5354C5.99945 9.97589 5.96899 10.6437 6.0346 11.0701C6.20564 12.1854 6.87107 13.2445 8.16677 14.4628C8.64475 14.915 8.97981 15.2103 10.3458 16.3841C11.187 17.1105 11.6064 17.4549 11.6954 17.4994C11.8782 17.5861 12.1218 17.5861 12.3046 17.4994C12.485 17.4127 14.9944 15.2524 15.8332 14.4628C17.1289 13.2445 17.7944 12.1854 17.9654 11.0701C18.031 10.6437 18.0005 9.97589 17.8975 9.5354C17.7053 8.73173 17.2414 8.0007 16.6181 7.52975C16.2409 7.2439 15.6762 7.00256 15.1865 6.9229C14.9218 6.87838 14.3805 6.87838 14.1181 6.9229C13.3918 7.04942 12.7099 7.45243 12.1968 8.06396C12.0961 8.18346 12.0094 8.28187 12 8.28187C11.9906 8.28187 11.9039 8.18346 11.8032 8.06396C11.2901 7.45243 10.6082 7.04942 9.88188 6.9229C9.62883 6.88072 9.04307 6.88072 8.79002 6.9229Z" fill="#FF0000"/>
                                 </svg>
                                 <svg className="dis6 dis" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() =>
                                    dislike(6)}>
                                    <circle id="Ellipse_40" data-name="Ellipse 40" cx="12" cy="12" r="12" fill="#fff"/>
                                    <g id="Black">
                                       <path id="Vector" d="M8.79,6.923a3.579,3.579,0,0,0-1.408.607A3.579,3.579,0,0,0,6.1,9.535a4.716,4.716,0,0,0-.068,1.535,6.039,6.039,0,0,0,2.132,3.393c.478.452.813.748,2.179,1.921.841.726,1.261,1.071,1.35,1.115a.746.746,0,0,0,.609,0c.18-.087,2.69-2.247,3.529-3.037a6.039,6.039,0,0,0,2.132-3.393A4.719,4.719,0,0,0,17.9,9.535,3.579,3.579,0,0,0,16.618,7.53a3.491,3.491,0,0,0-1.432-.607,4.29,4.29,0,0,0-1.068,0A3.262,3.262,0,0,0,12.2,8.064a1.991,1.991,0,0,1-.2.218,1.991,1.991,0,0,1-.2-.218A3.262,3.262,0,0,0,9.882,6.923,4.726,4.726,0,0,0,8.79,6.923Z" fill="none" stroke="black" strokeWidth="1"/>
                                    </g>
                                 </svg>
                              </div>
                           </div>
                           <NavLink to="/favorite">                           
                              <div className="sldtp-imgdv">
                                 <img src= {'assets/images/slrd-img2.png'} className="img-fluid" alt="Sld Img"/>
                              </div>
                              <div className="sldcntdv">
                                 <h3>Best Buffets</h3>
                                 <p>$100.00 <span>$120.00</span></p>
                              </div>
                           </NavLink>
                        </Col>
                        <Col className="hmone-slrcrd">                        
                           <div className="ginlike">
                              <div className="">
                                 <svg className="lik7 lik" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" onClick={() =>
                                    like(7)}>
                                    <circle cx="12" cy="12" r="12" fill="white"/>
                                    <path d="M8.79002 6.9229C8.31907 7.0049 7.74971 7.25093 7.38185 7.52975C6.7586 8.0007 6.29468 8.73173 6.10255 9.5354C5.99945 9.97589 5.96899 10.6437 6.0346 11.0701C6.20564 12.1854 6.87107 13.2445 8.16677 14.4628C8.64475 14.915 8.97981 15.2103 10.3458 16.3841C11.187 17.1105 11.6064 17.4549 11.6954 17.4994C11.8782 17.5861 12.1218 17.5861 12.3046 17.4994C12.485 17.4127 14.9944 15.2524 15.8332 14.4628C17.1289 13.2445 17.7944 12.1854 17.9654 11.0701C18.031 10.6437 18.0005 9.97589 17.8975 9.5354C17.7053 8.73173 17.2414 8.0007 16.6181 7.52975C16.2409 7.2439 15.6762 7.00256 15.1865 6.9229C14.9218 6.87838 14.3805 6.87838 14.1181 6.9229C13.3918 7.04942 12.7099 7.45243 12.1968 8.06396C12.0961 8.18346 12.0094 8.28187 12 8.28187C11.9906 8.28187 11.9039 8.18346 11.8032 8.06396C11.2901 7.45243 10.6082 7.04942 9.88188 6.9229C9.62883 6.88072 9.04307 6.88072 8.79002 6.9229Z" fill="#FF0000"/>
                                 </svg>
                                 <svg className="dis7 dis" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() =>
                                    dislike(7)}>
                                    <circle id="Ellipse_40" data-name="Ellipse 40" cx="12" cy="12" r="12" fill="#fff"/>
                                    <g id="Black">
                                       <path id="Vector" d="M8.79,6.923a3.579,3.579,0,0,0-1.408.607A3.579,3.579,0,0,0,6.1,9.535a4.716,4.716,0,0,0-.068,1.535,6.039,6.039,0,0,0,2.132,3.393c.478.452.813.748,2.179,1.921.841.726,1.261,1.071,1.35,1.115a.746.746,0,0,0,.609,0c.18-.087,2.69-2.247,3.529-3.037a6.039,6.039,0,0,0,2.132-3.393A4.719,4.719,0,0,0,17.9,9.535,3.579,3.579,0,0,0,16.618,7.53a3.491,3.491,0,0,0-1.432-.607,4.29,4.29,0,0,0-1.068,0A3.262,3.262,0,0,0,12.2,8.064a1.991,1.991,0,0,1-.2.218,1.991,1.991,0,0,1-.2-.218A3.262,3.262,0,0,0,9.882,6.923,4.726,4.726,0,0,0,8.79,6.923Z" fill="none" stroke="black" strokeWidth="1"/>
                                    </g>
                                 </svg>
                              </div>
                           </div>
                           <NavLink to="/favorite">   
                              <div className="sldtp-imgdv">
                                 <img src= {'assets/images/slrd-img1.png'} className="img-fluid" alt="Sld Img"/>
                              </div>
                              <div className="sldcntdv">
                                 <h3>Barbeque Near By...</h3>
                                 <p>$100.00 <span>$120.00</span></p>
                              </div>
                           </NavLink>
                        </Col>
                        <Col className="hmone-slrcrd">                        
                           <div className="ginlike">
                              <div className="">
                                 <svg className="lik8 lik" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" onClick={() =>
                                    like(8)}>
                                    <circle cx="12" cy="12" r="12" fill="white"/>
                                    <path d="M8.79002 6.9229C8.31907 7.0049 7.74971 7.25093 7.38185 7.52975C6.7586 8.0007 6.29468 8.73173 6.10255 9.5354C5.99945 9.97589 5.96899 10.6437 6.0346 11.0701C6.20564 12.1854 6.87107 13.2445 8.16677 14.4628C8.64475 14.915 8.97981 15.2103 10.3458 16.3841C11.187 17.1105 11.6064 17.4549 11.6954 17.4994C11.8782 17.5861 12.1218 17.5861 12.3046 17.4994C12.485 17.4127 14.9944 15.2524 15.8332 14.4628C17.1289 13.2445 17.7944 12.1854 17.9654 11.0701C18.031 10.6437 18.0005 9.97589 17.8975 9.5354C17.7053 8.73173 17.2414 8.0007 16.6181 7.52975C16.2409 7.2439 15.6762 7.00256 15.1865 6.9229C14.9218 6.87838 14.3805 6.87838 14.1181 6.9229C13.3918 7.04942 12.7099 7.45243 12.1968 8.06396C12.0961 8.18346 12.0094 8.28187 12 8.28187C11.9906 8.28187 11.9039 8.18346 11.8032 8.06396C11.2901 7.45243 10.6082 7.04942 9.88188 6.9229C9.62883 6.88072 9.04307 6.88072 8.79002 6.9229Z" fill="#FF0000"/>
                                 </svg>
                                 <svg className="dis8 dis" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() =>
                                    dislike(8)}>
                                    <circle id="Ellipse_40" data-name="Ellipse 40" cx="12" cy="12" r="12" fill="#fff"/>
                                    <g id="Black">
                                       <path id="Vector" d="M8.79,6.923a3.579,3.579,0,0,0-1.408.607A3.579,3.579,0,0,0,6.1,9.535a4.716,4.716,0,0,0-.068,1.535,6.039,6.039,0,0,0,2.132,3.393c.478.452.813.748,2.179,1.921.841.726,1.261,1.071,1.35,1.115a.746.746,0,0,0,.609,0c.18-.087,2.69-2.247,3.529-3.037a6.039,6.039,0,0,0,2.132-3.393A4.719,4.719,0,0,0,17.9,9.535,3.579,3.579,0,0,0,16.618,7.53a3.491,3.491,0,0,0-1.432-.607,4.29,4.29,0,0,0-1.068,0A3.262,3.262,0,0,0,12.2,8.064a1.991,1.991,0,0,1-.2.218,1.991,1.991,0,0,1-.2-.218A3.262,3.262,0,0,0,9.882,6.923,4.726,4.726,0,0,0,8.79,6.923Z" fill="none" stroke="black" strokeWidth="1"/>
                                    </g>
                                 </svg>
                              </div>
                           </div>
                           <NavLink to="/favorite">
                              <div className="sldtp-imgdv">
                                 <img src= {'assets/images/slrd-img2.png'} className="img-fluid" alt="Sld Img"/>
                              </div>
                              <div className="sldcntdv">
                                 <h3>Best Buffets</h3>
                                 <p>$100.00 <span>$120.00</span></p>
                              </div>
                           </NavLink>
                        </Col>   
                     </OwlCarousel>
                     {/* ========== OwlCarousel Box Card End ========== */}
                     </Col>
                     </Col>
                     <Col md={12} xs={12} className="hmcrdsldr  my-md-2">
                     <div className="tptxtcnt mb-3">
                        <p>Search Stores</p>
                        <NavLink to="/store-list"> View All </NavLink>
                     </div>
                     <Col className="sldrhm">
                     {/* ========== OwlCarousel Box Card Start ========== */}
                     <OwlCarousel items={2.2}  className='owl-theme twosld' loop margin={10} >
                        <Col className="hmone-slrcrd">
                        <NavLink to="/store-list">
                           <div className="sldtp-imgdv">
                              <img src= {'assets/images/slrd-imgt1.png'} className="img-fluid" alt="Sld Img"/>
                           </div>
                           <div className="sldcntdv">
                              <h3>Saloon for Men & W..</h3>
                              <p>Upto 60% off</p>
                           </div>
                        </NavLink>
                        </Col>
                        <Col className="hmone-slrcrd">
                        <NavLink to="/store-list">
                           <div className="sldtp-imgdv">
                              <img src= {'assets/images/slrd-imgt2.png'} className="img-fluid" alt="Sld Img"/>
                           </div>
                           <div className="sldcntdv">
                              <h3>Barbeque Restaurant</h3>
                              <p>Upto 60% off</p>
                           </div>
                        </NavLink>
                        </Col>
                        <Col className="hmone-slrcrd">
                        <NavLink to="/store-list">
                           <div className="sldtp-imgdv">
                              <img src= {'assets/images/slrd-imgt1.png'} className="img-fluid" alt="Sld Img"/>
                           </div>
                           <div className="sldcntdv">
                              <h3>Saloon for Men & W..</h3>
                              <p>Upto 60% off</p>
                           </div>
                        </NavLink>
                        </Col>
                        <Col className="hmone-slrcrd">
                        <NavLink to="/store-list">
                           <div className="sldtp-imgdv">
                              <img src= {'assets/images/slrd-imgt2.png'} className="img-fluid" alt="Sld Img"/>
                           </div>
                           <div className="sldcntdv">
                              <h3>Barbeque Restaurant</h3>
                              <p>Upto 60% off</p>
                           </div>
                        </NavLink>
                        </Col>
                        <Col className="hmone-slrcrd">
                        <NavLink to="/store-list">
                           <div className="sldtp-imgdv">
                              <img src= {'assets/images/slrd-imgt1.png'} className="img-fluid" alt="Sld Img"/>
                           </div>
                           <div className="sldcntdv">
                              <h3>Saloon for Men & W..</h3>
                              <p>Upto 60% off</p>
                           </div>
                        </NavLink>
                        </Col>
                        <Col className="hmone-slrcrd">
                        <NavLink to="/store-list">
                           <div className="sldtp-imgdv">
                              <img src= {'assets/images/slrd-imgt2.png'} className="img-fluid" alt="Sld Img"/>
                           </div>
                           <div className="sldcntdv">
                              <h3>Barbeque Restaurant</h3>
                              <p>Upto 60% off</p>
                           </div>
                        </NavLink>
                        </Col>
                        <Col className="hmone-slrcrd">
                        <NavLink to="/store-list">
                           <div className="sldtp-imgdv">
                              <img src= {'assets/images/slrd-imgt1.png'} className="img-fluid" alt="Sld Img"/>
                           </div>
                           <div className="sldcntdv">
                              <h3>Saloon for Men & W..</h3>
                              <p>Upto 60% off</p>
                           </div>
                        </NavLink>
                        </Col>
                        <Col className="hmone-slrcrd">
                        <NavLink to="/store-list">
                           <div className="sldtp-imgdv">
                              <img src= {'assets/images/slrd-imgt2.png'} className="img-fluid" alt="Sld Img"/>
                           </div>
                           <div className="sldcntdv">
                              <h3>Barbeque Restaurant</h3>
                              <p>Upto 60% off</p>
                           </div>
                        </NavLink>
                        </Col>   
                     </OwlCarousel>
                     {/* ========== OwlCarousel Box Card End ========== */}
                     </Col>
                     </Col>
                     <Col className="hmlst-bnr mb-4">
                     <NavLink to="/sales-invite-merchant">
                      <Row className="mx-0 cuscoupn cupndwnhm">
                       <Col className="align-self-start">
                        <h3>Make$60 Commission! Click heere to find out more detail</h3>
                       </Col>
                      </Row>
                     </NavLink>
                      </Col>
                     </Col>
                  </Row>
               </Container>
            </section>
            <Footer/>
         {/* ========== Home Page Start ========== */}
        </>
    )
}
export default UserHome;