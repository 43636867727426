import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Form, Modal } from 'react-bootstrap';
import './EmpProfile.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { NavLink } from "react-router-dom";

const EmpProfile = () => {
const [isSunChecked, setSunIsChecked] = useState(true);
const [isMonChecked, setMonIsChecked] = useState(false);
const [isTueChecked, setTueIsChecked] = useState(false);
const [isWedChecked, setWedIsChecked] = useState(false);
const [isThuChecked, setThuIsChecked] = useState(false);
const [isFriChecked, setFriIsChecked] = useState(false);
const [isSatChecked, setSatIsChecked] = useState(false);

const handleSunCheckboxChange = () => {setSunIsChecked(!isSunChecked);};
const handleMonCheckboxChange = () => {setMonIsChecked(!isMonChecked);};
const handleTueCheckboxChange = () => {setTueIsChecked(!isTueChecked);};
const handleWedCheckboxChange = () => {setWedIsChecked(!isWedChecked);};
const handleThuCheckboxChange = () => {setThuIsChecked(!isThuChecked);};
const handleFriCheckboxChange = () => {setFriIsChecked(!isFriChecked);};
const handleSatCheckboxChange = () => {setSatIsChecked(!isSatChecked);};
const navigate = useNavigate();
const [values, setValues] = useState({password: "",showPassword: false,});
const [values1, setValues1] = useState({password: "",showPassword: false,});
const [values2, setValues2] = useState({password: "",showPassword: false,});
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
useEffect(() => {
      document.title = `Employee Profile | Blue Faucet`;
      document.getElementById('bodywrap').className='rprflpgmain grenicn';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function(e) {
            $('#imagePreview').attr("src",e.target.result);
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
        }
        reader.readAsDataURL(input.files[0]);
    }
}
$("#imageUpload").change(function() {
    readURL(this);
});
},[]) 
const handleClickShowPassword = () => {setValues({ ...values, showPassword: !values.showPassword });}
const handleClickShowPassword1 = () => {setValues1({ ...values1, showPassword: !values1.showPassword });}
const handleClickShowPassword2 = () => {setValues2({ ...values2, showPassword: !values2.showPassword });}

    return (
        <>
         {/* ========== Employee Profile Page Start ========== */}
          <section className="gen-main">
         {/* ========== Navbar Start ========== */}
            <Row className="maincntmrcnt mx-0">
               <Col xs={2} md={2} className="align-self-center px-0">
               <button onClick={() =>
                  navigate(-1)} className="back-btn">
                  <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
                  </svg>
               </button>
               </Col>
               <Col xs={8} md={8} className="align-self-center text-center">
               <h3>Your Profile</h3>
               </Col>
               <Col xs={2} md={2} className="align-self-center">
               <p></p>
               </Col>
            </Row>
         {/* ========== Navbar End ========== */}
            <Container>
               <Row md={12} className="genscrnmrcnt">
                  <Col>
         {/* ========== Employee Profile Content Start ========== */}
                  <Row md={12} className="maincntsrcnmrcnt">
                     <Col className="px-0">
                     <Col className="prflupld">
                     <div className="avatar-upload">
                        <div className="avatar-edit">
                           <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg"/>
                           <label htmlFor="imageUpload"></label>
                        </div>
                        <div className="avatar-preview">
                           <div ><img src="assets/images/employee/usr-icn.png" id="imagePreview" className="img-fluid" alt="Logo"/></div>
                        </div>
                     </div>
                     </Col>
                     <Form>
                        <Form.Group className="mb-3" >
                           <Form.Label>Employee Name<span></span></Form.Label>
                           <Form.Control type="text" placeholder="john" disabled/>
                        </Form.Group>
                        <Form.Group className="mb-3" >
                           <Form.Label>Phone Number<span></span></Form.Label>
                           <Form.Control type="tel" placeholder="+1 9876543210" disabled/>
                        </Form.Group>
                        <div className="custm-add mb-3">
                           <NavLink to="#">
                              <Button variant="primary" onClick={handleShow}>
                                 Working Hours Setup
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M7.62132 0.0863132C7.43385 0.183171 7.27138 0.373764 7.21826 0.558107C7.19952 0.633095 7.18702 1.7829 7.18702 3.92941V7.18822H3.93134C1.78172 7.18822 0.631923 7.20072 0.556936 7.21947C0.0945177 7.34757 -0.139816 7.92872 0.0882688 8.37864C0.182002 8.56611 0.372594 8.72858 0.556936 8.7817C0.631923 8.80045 1.78172 8.81295 3.93134 8.81295H7.18702V12.0686C7.18702 14.2183 7.19952 15.3681 7.21826 15.4431C7.34637 15.9055 7.92751 16.1398 8.37743 15.9117C8.5649 15.818 8.72737 15.6274 8.78049 15.4431C8.79923 15.3681 8.81173 14.2183 8.81173 12.0686V8.81295H12.0705C14.217 8.81295 15.3668 8.80045 15.4418 8.7817C15.6262 8.72858 15.8167 8.56611 15.9136 8.37864C16.1386 7.92872 15.9042 7.34757 15.4418 7.21947C15.3668 7.20072 14.217 7.18822 12.0705 7.18822H8.81173V3.92941C8.81173 1.7829 8.79923 0.633095 8.78049 0.558107C8.68988 0.233163 8.36181 0.00195217 7.99938 0.00195217C7.83378 0.00195217 7.75567 0.0206995 7.62132 0.0863132Z" fill="#3F9534"/>
                                 </svg>
                              </Button>
                           </NavLink>
                        </div>
                        <Form.Group className="mb-3" >
                           <Form.Label>Service Description<span></span></Form.Label>
                           <Form.Control as="textarea" rows={3} placeholder="Add Service Description" />
                        </Form.Group>
                        <Form.Group className="mb-3 pswinpt" >
                           <Form.Label>Old Password<span></span></Form.Label>
                           <Form.Control type={values.showPassword ? "text" : "password"} id="olpassword" placeholder="••••••••••••"/>
                           {values.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword}></i>}
                        </Form.Group>
                        <Form.Group className="mb-3 pswinpt" >
                           <Form.Label>New Password<span></span></Form.Label>
                           <Form.Control type={values1.showPassword ? "text" : "password"} id="nepassword" placeholder="••••••••••••" />
                           {values1.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword1}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword1}></i>}
                        </Form.Group>
                        <Form.Group className="mb-3 pswinpt" >
                           <Form.Label>Re-enter New Password<span></span></Form.Label>
                           <Form.Control type={values2.showPassword ? "text" : "password"} id="repassword" placeholder="••••••••••••" />
                           {values2.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword2}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword2}></i>}
                        </Form.Group>
                        <Row>
                           <Col className="btn-clr">
                           <NavLink to="#"><Button variant="">Clear</Button>{' '}</NavLink>
                           </Col>
                           <Col className="btn-dv">
                           <NavLink to="/emp-dashboard"><Button variant="">Save</Button>{' '}</NavLink>
                           </Col>
                        </Row>
                     </Form>
                     </Col>
                  </Row>
         {/* ========== Employee Profile Content End ========== */}
                  </Col>
                  <div className="popupcntmrcntflw">
                     <div className="inmodal">
         {/* ========== Employee Profile Working Hours Setup Modal Content Start ========== */}
                        <Modal show={show} onHide={handleClose} className="popupwrngcnt">
                           <Modal.Header closeButton>
                              <h3 className="mdlttltxt">Working Hours Setup</h3>
                           </Modal.Header>
                           <Modal.Body>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className="align-self-center">
                              <Form.Label>Sunday</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label=""  checked={isSunChecked} onChange={handleSunCheckboxChange} />
                              </Col>
                              {isSunChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                              </Row>
                              </Col>
                              }
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className="align-self-center">
                              <Form.Label>Monday</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={isMonChecked} onChange={handleMonCheckboxChange} />
                              </Col>
                              {isMonChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                              </Row>
                              </Col>   
                              }                              
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className="align-self-center">
                              <Form.Label>Tuesday</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={isTueChecked} onChange={handleTueCheckboxChange} />
                              </Col>
                              {isTueChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                              </Row>
                              </Col>   
                              }                                  
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className=" mb-2 align-self-center">
                              <Form.Label>Wednesday</Form.Label>
                              </Col>
                              <Col xs={2} className=" mb-2 align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={isWedChecked} onChange={handleWedCheckboxChange}/>
                              </Col>
                              {isWedChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                              </Row>
                              </Col>   
                              }    
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className=" mb-2 align-self-center">
                              <Form.Label>Thursday</Form.Label>
                              </Col>
                              <Col xs={2} className=" mb-2 align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={isThuChecked} onChange={handleThuCheckboxChange}/>
                              </Col>
                              {isThuChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                              </Row>
                              </Col>   
                              }         
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className=" mb-2 align-self-center">
                              <Form.Label>Friday</Form.Label>
                              </Col>
                              <Col xs={2} className=" mb-2 align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={isFriChecked} onChange={handleFriCheckboxChange}/>
                              </Col>
                              {isFriChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                              </Row>
                              </Col>   
                              }    
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className=" mb-2 align-self-center">
                              <Form.Label>Saturday</Form.Label>
                              </Col>
                              <Col xs={2} className=" mb-2 align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={isSatChecked} onChange={handleSatCheckboxChange}/>
                              </Col>
                              {isSatChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                                 <Col xs={6} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
                                 <Form.Control type="time" placeholder="00:00 AM" />
                                 </Col>
                              </Row>
                              </Col>   
                              }                                  
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={12} className="align-self-center">
                              <h3 className="mdlttltxt float-start">Orders/Appointments settings</h3>
                              <span style={{ }}>
                                 <OverlayTrigger delay={{ hide: 450, show: 300 }} overlay={(props) => (
                                 <Tooltip {...props}>Get more information in FAQs!</Tooltip>
                                 )}placement="bottom">
                                 <NavLink to="/mer-help"> <span className="mdlttltxt text-danger">&nbsp;? </span></NavLink>
                                 </OverlayTrigger>
                              </span>
                              </Col>
                              <div className="linepop my-1"></div>
                              <Row className="chkbtn mx-0 mb-3 mt-3 px-0">
                                 <Col xs={9} className="align-self-center">
                                 <Form.Label>How Many customers can be accepted per time slot (per hour):</Form.Label>
                                 </Col>
                                 <Col xs={3} className="align-self-center">
                                  <Form.Control type="Number" placeholder="4" />
                                 </Col>
                              </Row>
                              <Col xs={10} className="align-self-center mb-2">
                              <Form.Label>Auto accept during working hours</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" defaultChecked={true}/>
                              </Col>
                              <Col xs={10} className="align-self-center mb-2">
                              <Form.Label>Need to be Confirmed</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label=""/>
                              </Col>
                              <Col xs={10} className="align-self-center mb-2">
                              <Form.Label>Auto Refuse (customers in the blocklist)</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" defaultChecked={true}/>
                              </Col>
                           </Row>
                              <Row className="pp-btn1 mx-0">
                                 <Col>
                                 <NavLink to="#" onClick={() => handleClose()}><Button className="blc" variant="">Confirm</Button></NavLink></Col>
                              </Row>
                           </Modal.Body>
                        </Modal>
         {/* ========== Employee Profile Working Hours Setup Modal Content End ========== */}
                     </div>
                  </div>
               </Row>
            </Container>
         </section>
         {/* ========== Employee Profile Page End ========== */}
        </>
    )
}
export default EmpProfile;