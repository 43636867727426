import React, { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './UserCalender.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
// import $ from 'jquery';
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from '@fullcalendar/timegrid'

const UserCalender = () => {

const events = [{ title: "Appointment Name here", date: '2023-08-09T08:00:00',className: 'event-green'  },{ title: "Appointment Name here", date: new Date(),className: 'event-green'  },{ title: "Appointment Name here", date: '2023-07-13T10:00:00',className: 'event-yellow'  },{ title: "Appointment Name here", date: '2023-07-13T12:00:00',className: 'event-yellow1'  },{ title: "Appointment Name here", date: '2023-07-12T14:00:00',className: 'event-yellow2'  },{ title: "", date: '2023-07-10T14:00:00',className: 'event-light-gray'  },{ title: "", date: '2023-07-10T08:00:00',className: 'event-light-gray'  },{ title: "", date: '2023-07-12T08:00:00',className: 'event-light-gray'  },{ title: "", date: '2023-07-12T17:00:00',className: 'event-light-gray'  }];

useEffect(() => {
      document.title = `User Calendar | Blue Faucet`;
      document.getElementById('bodywrap').className='clndrpgmain';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
     
    
},[])  
const renderDayHeader = ({ date }) => {
    const formattedDate = date.toLocaleString('default', {
      weekday: 'short',
      day: 'numeric',
    });
    let array = formattedDate.split(" ");
    
    return <span>{array[1]} <p>{array[0]}</p></span>;
  };
  const handleEventClick = ({ event, el }) => {
   
      window.location.href = '/service-order-summary';



  };

    return (
        <>
            {/* ========== User Calender Page Start ========== */}
            <section className="gen-wrap">
               <Navbar headerText={'Calender'}/>
               <Container>
                  <Row className="mx-0 mianhmdwncnt">
                     <Col md={12} xs={12} className="mnhmcnt my-3">
                     <Col md={12} xs={12} className="clndrmaindv">
                     {/* ========== Calendar Code Start ========== */}
                     <FullCalendar
                     editable
                     selectable
                     events={events}
                     contentHeight='auto'
                     slotLabelFormat= {
                     {  hour: 'numeric'
                     }}
                     headerToolbar={{
                     start: "",
                     center: 'title',
                     right: "dayGridMonth,timeGridWeek,timeGridDay"
                     }}
                     plugins={[timeGridPlugin]}
                     initialView="timeGridWeek"
                     allDaySlot={false}
                     views={{
                     week: {
                     titleFormat: { month: 'long',year:'numeric' },
                     },
                     }}
                     dayHeaderContent={renderDayHeader}
                     eventClick={handleEventClick}
                     />
                     {/* ========== Calendar Code Start ========== */}
                     </Col>
                     </Col>
                  </Row>
               </Container>
            </section>
            <Footer/>
            {/* ========== User Calender Page End ========== */}
        </>
    )
}

export default UserCalender;