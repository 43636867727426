import React, { useEffect } from 'react';
import { Row, Col, Container,} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './UserIntmrct.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
// import $ from 'jquery';

const UserIntmrct = () => {

useEffect(() => {
      document.title = `User Invite Merchants | Blue Faucet`;
      document.getElementById('bodywrap').className='usrivmrcntpgmain';

     let copyText = document.querySelector(".copy-text");
copyText.querySelector("button").addEventListener("click", function () {
  let input = copyText.querySelector("input.text");
  input.select();
  document.execCommand("copy");
  copyText.classList.add("active");
  window.getSelection().removeAllRanges();
  setTimeout(function () {
    copyText.classList.remove("active");
  }, 2500);
});


    window.scrollTo({top: 0,left: 0,behavior: 'smooth', }); 
    
},[])  

    return (
        <>
        {/* ========== User Invite Merchant Page Start ========== */}
         <section className="gen-wrap">
         {/* ========== Navbar Start ========== */}
            <Navbar headerText={'Invite Merchants'}/>
         {/* ========== Navbar End ========== */}
           <Container>
              <Row className="mx-0 mianhmdwncnt">
                 <Col className="mnhmcnt">
                 <Col md={12} xs={12} className="py-3">
                 <Col className="mb-3 px-0 usrmrcnt-tpcnt">
                  <Row className="mx-0 cuscoupn cupndwnhm">
                    <Col className="align-self-start">
                     <h3>Make$60 Commission! Click heere to find out more detail</h3>
                    </Col>
                  </Row>
                 </Col>
                 {/* ========== Share Box Start ========== */}
                 <Row className="mx-0 shrelist">
                    <Col md={12} xs={12} className="my-2">
                    <h3>Share Via</h3>
                    </Col>
                    <Col md={3} xs={3} className="shrelist-mincnt text-center">
                    <NavLink to="#">
                       <img src= {'assets/images/svg/shr-icn1.svg'} className="img-fluid" alt="Sld Img"/>
                       <div className="nm-shr">
                          <p>To Contact</p>
                       </div>
                    </NavLink>
                    </Col>
                    <Col md={3} xs={3} className="shrelist-mincnt text-center">
                    <NavLink to="#">
                       <img src= {'assets/images/svg/shr-icn2.svg'} className="img-fluid" alt="Sld Img"/>
                       <div className="nm-shr">
                          <p>Gmail</p>
                       </div>
                    </NavLink>
                    </Col>
                    <Col md={3} xs={3} className="shrelist-mincnt text-center">
                    <NavLink to="#">
                       <img src= {'assets/images/svg/shr-icn3.svg'} className="img-fluid" alt="Sld Img"/>
                       <div className="nm-shr">
                          <p>Outlook</p>
                       </div>
                    </NavLink>
                    </Col>
                    <Col md={3} xs={3} className="shrelist-mincnt text-center">
                    <NavLink to="#">
                       <img src= {'assets/images/svg/shr-icn4.svg'} className="img-fluid" alt="Sld Img"/>
                       <div className="nm-shr">
                          <p>Whatsapp</p>
                       </div>
                    </NavLink>
                    </Col>
                    <Col md={3} xs={3} className="shrelist-mincnt text-center">
                    <NavLink to="#">
                       <img src= {'assets/images/svg/shr-icn5.svg'} className="img-fluid" alt="Sld Img"/>
                       <div className="nm-shr">
                          <p>Phone Log</p>
                       </div>
                    </NavLink>
                    </Col>
                    <Col md={3} xs={3} className="shrelist-mincnt text-center">
                    <NavLink to="#">
                       <img src= {'assets/images/svg/shr-icn5.svg'} className="img-fluid" alt="Sld Img"/>
                       <div className="nm-shr">
                          <p>Phone Number</p>
                       </div>
                    </NavLink>
                    </Col>
                    <Col md={3} xs={3} className="shrelist-mincnt text-center">
                    <NavLink to="#">
                       <img src= {'assets/images/svg/shr-icn6.svg'} className="img-fluid" alt="Sld Img"/>
                       <div className="nm-shr">
                          <p>Email</p>
                       </div>
                    </NavLink>
                    </Col>
                    <Col md={3} xs={3} className="shrelist-mincnt text-center d-none">
                    <NavLink to="#">
                       <img src= {'assets/images/svg/shr-icn7.svg'} className="img-fluid" alt="Sld Img"/>
                       <div className="nm-shr">
                          <p>QR Code</p>
                       </div>
                    </NavLink>
                    </Col> 
                    <Col md={12} xs={12} className="cpytxtdv">
                    <h4>Or copy link</h4>
                    <div className="copy-text">
                       <input type="text" className="text" defaultValue="sdv5bdb151xcv" disabled/>
                       <button>Copy</button>
                    </div>
                    </Col>
                 </Row>
                 {/* ========== Share Box End ========== */}
                 </Col>
                 </Col>
              </Row>
           </Container>
        </section>
        {/* ========== User Invite Merchant Page End ========== */}
        </>
    )
}

export default UserIntmrct;