import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import './UserOrdrsmry.css';
import './slder.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import { NavLink } from "react-router-dom";
//import { useNavigate } from 'react-router-dom';
// import $ from 'jquery';
import { Stepper } from "@progress/kendo-react-layout";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Contactsetupnew } from "./Contactsetupnew";
import { Ordersummary } from "./Ordersummary";
import { Paybill } from "./Paybill";
const stepPages = [Contactsetupnew, Ordersummary, Paybill];

const UserOrdrsmrynewstp2 = () => {
  //const navigate = useNavigate();

  const [title, setTitle] = useState('Contact Setup');
   const [step, setStep] = React.useState(1);
    
   const [formState, setFormState] = React.useState({});
   const [steps, setSteps] = React.useState([
    {
      label: "Contact Setup",
      isValid: undefined,
    },
    {
      label: "Order Summary",
      isValid: undefined,
    },
    {
      label: "Pay The Bill",
      isValid: undefined,
    },
  ]);
     const lastStepIndex = steps.length - 1;
  const isLastStep = lastStepIndex === step;
  const isPreviousStepsValid =
    steps
      .slice(0, step)
      .findIndex((currentStep) => currentStep.isValid === false) === -1;
  const onStepSubmit = React.useCallback(
    (event) => {
      const { isValid, values } = event;
      const currentSteps = steps.map((currentStep, index) => ({

        ...currentStep,
        isValid: index === step ? isValid : currentStep.isValid,
      }));
      console.log(currentSteps);
      setSteps(currentSteps);
      setStep(() => Math.min(step + 1, lastStepIndex));
      setFormState(values);
      //setTitle(currentSteps[step+1]['label']);
      setTitle('Contact Setup');
      if (isLastStep && isPreviousStepsValid && isValid) {
        
        //alert(JSON.stringify(values));
        ///alert('ffddf');
        window.location.href = '/product-order-confirmation';
      }
    },
    [steps, isLastStep, isPreviousStepsValid, step, lastStepIndex]
  );
  const onPrevClick = React.useCallback(
    (event) => {
      event.preventDefault();
      setStep(() => Math.max(step - 1, 0));
    },
    [step, setStep]
  );

useEffect(() => {
      document.title = `User Order Summary | Blue Faucet`;
      document.getElementById('bodywrap').className='uospgmain';
    
},[]) 
    return (
        <>
        {/* ========== User Order Summary Page Start ========== */}
            <section className="gen-wrap">
            {/* ========== Navbar Start ========== */}
               <Navbar headerText={title}/>
                <Col className="thstbox">
                                {step === 0 ? (
                                  <h3>Contact Setup</h3>
                                    ) : undefined}
                                {step === 1 ? (
                                  <h3>Apply Coupon</h3>
                                    ) : undefined} 
                                {step === 2 ? (
                                  <h3>Pay the Bill</h3>
                                    ) : undefined}                  
                </Col>                
            {/* ========== Navbar End ========== */}
             <Container>
                <Row className="">
                  <Col md={12} lg={12} xl={12} xxl={12} className="usrsmrypg px-0">
                  {/* ========== Order Summary content Start ========== */}
                   <ul className="hmtp-scldv">
                      <Row className="mx-0 ucl-sfbtn">
                         <div className="px-0" style={{display: "flex",flexDirection: "column",justifyContent: "center",}}>
                     <Stepper value={step} items={steps} />
                     <Form initialValues={formState} onSubmitClick={onStepSubmit} render={(formRenderProps) => (
                         <div md={11} xs={11} style={{margin: "0px 15px",}}>
                           <FormElement>
                             {stepPages[step]}
                             <span style={{marginTop: "40px",}} className={"k-form-separator"}/>
                             <div style={{justifyContent: "space-between",alignContent: "center",}}
                               className={"k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"}>
                               <span className="d-none" style={{alignSelf: "center",}}>
                                 Step {step + 1} of 3
                               </span>
                               <div className="btnnxt-smrypg">
                                 {step !== 0 ? (
                                   <Button className="d-none" style={{marginRight: "16px",}} onClick={onPrevClick}> Previous </Button>
                                 ) : undefined}
                                 <NavLink to="/order-confirmation"><Button themecolor={"primary"} disabled={isLastStep ? !isPreviousStepsValid : false} onClick={formRenderProps.onSubmit}> {isLastStep ? "Pay The Bill" : "Next"}</Button></NavLink>

                               </div>
                             </div>
                           </FormElement>
                         </div>
                       )}
                     />
                   </div>
                      </Row>
                   </ul>
                  {/* ========== Order Summary content End ========== */}
                   </Col>
                </Row>
             </Container>
          </section>
        {/* ========== User Order Summary Page End ========== */}
        </>
    )
}
export default UserOrdrsmrynewstp2;