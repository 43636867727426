import React, { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './MerCal.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
// import $ from 'jquery';
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from '@fullcalendar/timegrid'

const MerCal = () => {

const events = [{ title: "Appointment", date: new Date(),className: 'event-green'  },{ title: "Appointment 1", date: '2023-08-13T01:00:00',className: 'event-yellow'  },{ title: "Appointment 2", date: '2023-08-14T02:00:00',className: 'event-yellow1'  },{ title: "Appointment 3", date: '2023-08-15T01:00:00',className: 'event-yellow2'  },{ title: "Appointment 4", date: '2023-08-16T02:00:00',className: 'event-light-gray'},{ title: "Appointment 5", date: '2023-08-17T05:00:00',className: 'event-green'},{ title: "Appointment 6", date: '2023-08-18T01:00:00',className: 'event-yellow'  },{ title: "Appointment 7", date: '2023-08-19T02:00:00',className: 'event-yellow1'  },{ title: "Appointment 8", date: '2023-08-20T01:00:00',className: 'event-yellow2'  },{ title: "Appointment 9", date: '2023-08-21T02:00:00',className: 'event-light-gray'},{ title: "Appointment 10", date: '2023-08-17T02:00:00',className: 'event-green'}];

useEffect(() => {
      document.title = `Merchant Calender | Blue Faucet`;
      document.getElementById('bodywrap').className='clndrpgmain grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });     
},[])  
const renderDayHeader = ({ date }) => {
    const formattedDate = date.toLocaleString('default', {
      weekday: 'short',
      day: 'numeric',
    });
    let array = formattedDate.split(" ");
    
    return <span>{array[1]} <p>{array[0]}</p></span>;
  };
  const handleEventClick = ({ event, el }) => {
   
      // window.location.href = '/order-summary';

  };
    return (
        <>
        
        {/* ========== Merchant Calender Page Start ========== */}
            <section className="gen-wrap">
            {/* ========== Navbar Start ========== */}
               <Navbar headerText={'Calender'}/>
            {/* ========== Navbar End ========== */}
               <Container>
                  <Row className="mx-0 mianhmdwncnt">
                     <Col md={12} xs={12} className="mnhmcnt my-3">
                     <Col md={12} xs={12} className="clndrmaindv">
                {/* ========== Calender Content Start ========== */}
                     <FullCalendar
                     editable
                     selectable
                     events={events}
                     contentHeight='auto'
                     slotLabelFormat= {
                     {  hour: 'numeric'
                     }}
                     headerToolbar={{
                     start: "",
                     center: 'title',
                     right: "dayGridMonth,timeGridWeek,timeGridDay"
                     }}
                     plugins={[timeGridPlugin]}
                     initialView="timeGridWeek"
                     allDaySlot={false}
                     views={{
                     week: {
                     titleFormat: { month: 'long',year:'numeric' },
                     },
                     }}
                     dayHeaderContent={renderDayHeader}
                     eventClick={handleEventClick}
                     />
                {/* ========== Calender Content End ========== */}
                     </Col>
                     </Col>
                  </Row>
               </Container>
            </section>
            {/* ========== Footer End ========== */}
              <Footer/>
            {/* ========== Footer End ========== */}
        {/* ========== Merchant Calender Page End ========== */}
        </>
    )
}

export default MerCal;