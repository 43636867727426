import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Form, Nav, Modal } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import './MerSelling.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "../footer";
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';


const MerSelling = () => {
const navigate = useNavigate();

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const [show1, setShow1] = useState(false);
const handleClose1 = () => setShow1(false);
const handleShow1 = () => setShow1(true);

const [product, setProduct] = useState(true);
const [service, setService] = useState(true);

const [show2, setShow2] = useState(false);
const handleClose2 = () => setShow2(false);
const handleShow2 = () => setShow2(true);

useEffect(() => {
      document.title = ` Merchant Selling | Blue Faucet`;
      document.getElementById('bodywrap').className='slngpgmain grnbg grenicn';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });       
},[])  
const handleOnChangedsplysp = (event) => {
      event.persist();
var val=event.target.value;
console.log(val);
    if(val===0){setProduct(true);setService(true);}
    if(val===1){setProduct(false);setService(true);}
    if(val===2){setProduct(true);setService(false);}
}
$("document").ready(function() {
          $('.dropdown-menu').on('click', function(e) {
    if($(this).hasClass('dropdown-menu-form')) {
    e.stopPropagation();}});
});
    return (
        <>
      {/* ========== Merchant Selling Page Start ========== */}
         <section className="gen-wrap">
      {/* ========== Navbar Start ========== */}
         <Row className="maincntmrcnt">
            <Col xs={2} md={2} className="align-self-center px-0">
            <button onClick={() =>
               navigate(-1)} className="back-btn d-none">
               <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
               </svg>
            </button>
            </Col>
            <Col xs={8} md={8} className="align-self-center text-center">
            <h3>Product/Service Setup</h3>
            </Col>
            <Col xs={2} md={2} className="align-self-center text-end mcustmrsw slctbtnsw">
            <Button id="btn2" className="" href={void(0)} variant="" onClick={handleShow}><img src="assets/images/merchant/svg/plus-icn.svg" className="img-fluid" alt="Logo"/></Button>{' '}
            </Col>
         </Row>
      {/* ========== Navbar End ========== */}
         <Container>
            <Row className="tpfixgrn">
               <Col md={5} xs={5} className="">
               <Form.Select aria-label="Default select example" onChange={handleOnChangedsplysp}>
                  <option defaultValue='0'>Display All</option>
                  <option defaultValue="1">Display Services</option>
                  <option defaultValue="2">Display Products</option>
               </Form.Select>
               </Col>
               <Col md={7} xs={7} className="text-end btntpfx">
               </Col>
            </Row>
      {/* ========== Selling Tab Start ========== */}
            <Tab.Container id="" defaultActiveKey="first">
               <Nav variant="pills" className="tbgren">
                  <Row className="tbrw mx-0">
                     <Col md={6} xs={6} className="">
                     <Nav.Item>
                        <Nav.Link eventKey="first" onClick={handleShow1}>Add Service</Nav.Link>
                     </Nav.Item>
                     </Col>
                     <Col md={6} xs={6} className="">
                     <Nav.Item>
                        <Nav.Link eventKey="second" onClick={handleShow}>Add Product</Nav.Link>
                     </Nav.Item>
                     </Col>
                  </Row>
               </Nav>
            </Tab.Container>
      {/* ========== Selling Tab Start ========== */}
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
      {/* ========== Selling Main Content Start ========== */}
               <Col md={12} xs={12} className="">
               {service && (
               <>
               <Col md={12} xs={12} className="mcusbx">
               <Row className="mx-0">
                  <Col md={2} xs={2} className="align-self-center px-1">
                  <NavLink to="#" onClick={handleShow1}><img src= {'assets/images/ustrl-img1.png'} className="img-fluid" alt="Mcust Img"/></NavLink>
                  </Col>
                  <Col md={8} xs={9} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                  <h3>
                     <NavLink to="#" onClick={handleShow1}>Service Name</NavLink>
                  </h3>
                  <p>$300.99</p>
                  </Col>
                  <Col md={2} xs={1} className="align-self-center text-end">
                  <NavLink to="#" onClick={handleShow2}><img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/></NavLink>
                  </Col>
               </Row>
               </Col>
               <Col md={12} xs={12} className="mcusbx">
               <Row className="mx-0">
                  <Col md={2} xs={2} className="align-self-center px-1">
                  <NavLink to="#" onClick={handleShow1}><img src= {'assets/images/ustrl-img2.png'} className="img-fluid" alt="Mcust Img"/></NavLink>
                  </Col>
                  <Col md={8} xs={9} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                  <h3>
                     <NavLink to="#" onClick={handleShow1}>Service Name</NavLink>
                  </h3>
                  <p>$300.99</p>
                  </Col>
                  <Col md={2} xs={1} className="align-self-center text-end">
                  <NavLink to="#" onClick={handleShow2}><img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/></NavLink>
                  </Col>
               </Row>
               </Col>
               <Col md={12} xs={12} className="mcusbx">
               <Row className="mx-0">
                  <Col md={2} xs={2} className="align-self-center px-1">
                  <NavLink to="#" onClick={handleShow1}><img src= {'assets/images/ustrl-img3.png'} className="img-fluid" alt="Mcust Img"/></NavLink>
                  </Col>
                  <Col md={8} xs={9} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                  <h3>
                     <NavLink to="#" onClick={handleShow1}>Service Name</NavLink>
                  </h3>
                  <p>$300.99</p>
                  </Col>
                  <Col md={2} xs={1} className="align-self-center text-end">
                  <NavLink to="#" onClick={handleShow2}>
                     <img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
                  </NavLink>
                  </Col>
               </Row>
               </Col>
               <Col md={12} xs={12} className="mcusbx">
               <Row className="mx-0">
                  <Col md={2} xs={2} className="align-self-center px-1">
                  <NavLink to="#" onClick={handleShow1}><img src= {'assets/images/ustrl-img4.png'} className="img-fluid" alt="Mcust Img"/></NavLink>
                  </Col>
                  <Col md={8} xs={9} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                  <h3>
                     <NavLink to="#" onClick={handleShow1}>Service Name</NavLink>
                  </h3>
                  <p>$300.99</p>
                  </Col>
                  <Col md={2} xs={1} className="align-self-center text-end">
                  <NavLink to="#" onClick={handleShow2}>
                     <img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
                  </NavLink>
                  </Col>
               </Row>
               </Col>
               </>
               )}
               </Col>
               <Col>
               {product && (
               <>
               <Col md={12} xs={12} className="mcusbx">
               <Row className="mx-0">
                  <Col md={2} xs={2} className="align-self-center px-1">
                  <NavLink to="#" onClick={handleShow}><img src= {'assets/images/npg-imgone.png'} className="img-fluid" alt="Mcust Img"/></NavLink>
                  </Col>
                  <Col md={8} xs={9} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                  <h3>
                     <NavLink to="#" onClick={handleShow}>Product Name</NavLink>
                  </h3>
                  <p>$300.99</p>
                  </Col>
                  <Col md={2} xs={1} className="align-self-center text-end">
                  <NavLink to="#" onClick={handleShow2}>
                     <img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
                  </NavLink>
                  </Col>
               </Row>
               </Col>
               <Col md={12} xs={12} className="mcusbx">
               <Row className="mx-0">
                  <Col md={2} xs={2} className="align-self-center px-1">
                  <NavLink to="#" onClick={handleShow}><img src= {'assets/images/npg-imgtwo.png'} className="img-fluid" alt="Mcust Img"/></NavLink>
                  </Col>
                  <Col md={8} xs={9} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                  <h3>
                     <NavLink to="#" onClick={handleShow}>Product Name</NavLink>
                  </h3>
                  <p>$300.99</p>
                  </Col>
                  <Col md={2} xs={1} className="align-self-center text-end">
                  <NavLink to="#" onClick={handleShow2}>
                     <img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
                  </NavLink>
                  </Col>
               </Row>
               </Col>
               <Col md={12} xs={12} className="mcusbx">
               <Row className="mx-0">
                  <Col md={2} xs={2} className="align-self-center px-1">
                  <NavLink to="#" onClick={handleShow}><img src= {'assets/images/npg-imgone.png'} className="img-fluid" alt="Mcust Img"/></NavLink>
                  </Col>
                  <Col md={8} xs={9} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                  <h3>
                     <NavLink to="#" onClick={handleShow}>Product Name</NavLink>
                  </h3>
                  <p>$300.99</p>
                  </Col>
                  <Col md={2} xs={1} className="align-self-center text-end">
                  <NavLink to="#" onClick={handleShow2}>
                     <img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
                  </NavLink>
                  </Col>
               </Row>
               </Col>
               <Col md={12} xs={12} className="mcusbx">
               <Row className="mx-0">
                  <Col md={2} xs={2} className="align-self-center px-1">
                  <NavLink to="#" onClick={handleShow}><img src= {'assets/images/npg-imgtwo.png'} className="img-fluid" alt="Mcust Img"/></NavLink>
                  </Col>
                  <Col md={8} xs={9} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                  <h3>
                     <NavLink to="#" onClick={handleShow}>Product Name</NavLink>
                  </h3>
                  <p>$300.99</p>
                  </Col>
                  <Col md={2} xs={1} className="align-self-center text-end">
                  <NavLink to="#" onClick={handleShow2}>
                     <img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
                  </NavLink>
                  </Col>
               </Row>
               </Col>
               </>
               )}
               </Col>
               </Col>
      {/* ========== Selling Main Content End ========== */}
            </Row>
            <Col>
            <div className="madcustmrpop">
      {/* ========== Selling Add Product Popup Start ========== */}
               <Modal show={show} onHide={handleClose} className="popupwrngcnt" centered>
                  <Modal.Header closeButton>
                     <h3 className="mdlttltxt">Add Product</h3>
                  </Modal.Header>
                  <Modal.Body>
                     <Col md={12} xs={12} className="ppinpt">
                     <Form>
                        <Form.Group className="mb-3">
                           <Form.Label>Product Name</Form.Label>
                           <Form.Control type="text" placeholder="Enter product name" />
                        </Form.Group>
                        <Col className="upladcustm">
                        <Form.Label>Select Image</Form.Label>
                        <Row>
                           <Col>
                           <div id="dropzone">
                              <div><img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/></div>
                              <input type="file" accept="image/png, application/pdf" />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div><img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/></div>
                              <input type="file" accept="image/png, application/pdf" />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div><img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/> </div>
                              <input type="file" accept="image/png, application/pdf" />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div><img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/> </div>
                              <input type="file" accept="image/png, application/pdf" />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div><img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/> </div>
                              <input type="file" accept="image/png, application/pdf" />
                           </div>
                           </Col>                                                                                                                                        
                        </Row>
                        </Col>
                        <Form.Group className="mb-3">
                           <Form.Label>Category</Form.Label>
                           <Form.Select aria-label="Default select example">
                              <option>Select Category</option>
                              <option defaultValue="1">Select Category</option>
                              <option defaultValue="2">Select Category</option>
                              <option defaultValue="3">Select Category</option>
                              <option defaultValue="4">Select Category</option>
                           </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Price</Form.Label>
                           <Form.Control type="text" placeholder="$00.00" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Quantity</Form.Label>
                           <Form.Control type="text" placeholder="Enter Quantity" />
                        </Form.Group>
                        <Form.Group className="mb-3 d-none">
                           <Form.Label>Duration in Minutes</Form.Label>
                           <Form.Control type="text" placeholder="5 Minutes" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Description</Form.Label>
                           <Form.Control as="textarea" rows={3} placeholder="Enter your description here" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Special Instructions</Form.Label>
                           <Form.Control as="textarea" rows={3} placeholder="Enter your special instruction here" />
                        </Form.Group>
                     </Form>
                     </Col>
                     <Col md={12} xs={12} className="btnpopmcstmr my-3">
                     <NavLink to="#" onClick={() => handleClose()}><Button variant="black" type="submit">Save</Button></NavLink>
                     </Col>
                  </Modal.Body>
                  <Modal.Footer className="d-none"></Modal.Footer>
               </Modal>
      {/* ========== Selling Add Product Popup End ========== */}
            </div>
            <div className="madcustmrpop2">
      {/* ========== Selling Add Service Popup Start ========== */}
               <Modal show={show1} onHide={handleClose1} className="popupwrngcnt" centered>
                  <Modal.Header closeButton>
                     <h3 className="mdlttltxt">Add Service</h3>
                  </Modal.Header>
                  <Modal.Body>
                     <Col md={12} xs={12} className="ppinpt">
                     <Form>
                        <Form.Group className="mb-3">
                           <Form.Label>Service Name</Form.Label>
                           <Form.Control type="text" placeholder="Enter product or service name" />
                        </Form.Group>
                        <Col className="upladcustm">
                        <Form.Label>Select Image</Form.Label>
                        <Row>
                           <Col>
                           <div id="dropzone">
                              <div><img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/></div>
                              <input type="file" accept="image/png, application/pdf" />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div><img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/></div>
                              <input type="file" accept="image/png, application/pdf" />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div><img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/> </div>
                              <input type="file" accept="image/png, application/pdf" />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div><img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/> </div>
                              <input type="file" accept="image/png, application/pdf" />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div><img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/> </div>
                              <input type="file" accept="image/png, application/pdf" />
                           </div>
                           </Col>                                                                                                                                        
                        </Row>
                        </Col>
                        <Form.Group className="mb-3 d-none">
                           <Form.Label>Category</Form.Label>
                           <Form.Select aria-label="Default select example">
                              <option>Select Category</option>
                              <option defaultValue="1">Select Category</option>
                              <option defaultValue="2">Select Category</option>
                              <option defaultValue="3">Select Category</option>
                              <option defaultValue="4">Select Category</option>
                           </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Price</Form.Label>
                           <Form.Control type="text" placeholder="$00.00" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Duration in Minutes</Form.Label>
                           <Form.Control type="text" placeholder="5 Minutes" />
                        </Form.Group>
                        <Form.Group className="mb-3 d-none">
                           <Form.Label>Location</Form.Label>
                           <Form.Control type="location" placeholder="Enter Location" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Description</Form.Label>
                           <Form.Control as="textarea" rows={3} placeholder="Enter your description here" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Special Instructions</Form.Label>
                           <Form.Control as="textarea" rows={3} placeholder="Enter your special instruction here" />
                        </Form.Group>
                     </Form>
                     </Col>
                     <Col md={12} xs={12} className="btnpopmcstmr my-3">
                     <NavLink to="#" onClick={() => handleClose1()}><Button variant="black" type="submit">Save</Button></NavLink>
                     </Col>
                  </Modal.Body>
                  <Modal.Footer className="d-none"></Modal.Footer>
               </Modal>
      {/* ========== Selling Add Service Popup End ========== */}
            </div>
            <div className="inmodal">
      {/* ========== Selling delete Popup Start ========== */}
               <Modal show={show2} onHide={handleClose2} className="popupwrngcnt">
                  <Modal.Header closeButton>
                     <h3 className="mdlttltxt">Are you sure you want to delete?</h3>
                  </Modal.Header>
                  <Modal.Body>
                     <Row>
                        <Col className="btn-clr">
                        <NavLink to="#" onClick={handleClose2}><Button variant="">Yes</Button></NavLink>
                        </Col>
                        <Col className="btn-dv">
                        <NavLink to="#" onClick={handleClose2}><Button variant="">No</Button></NavLink>
                        </Col>
                     </Row>
                  </Modal.Body>
                  <Modal.Footer className="d-none"></Modal.Footer>
               </Modal>
      {/* ========== Selling delete Popup End ========== */}
            </div>
            </Col>
         </Container>
      </section>
   {/* ========== Footer Start ========== */}
      <Footer/>
   {/* ========== Footer Start ========== */}
   {/* ========== Merchant Selling Page End ========== */}
        </>
    )
}
export default MerSelling;